import React, { useState, useEffect } from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { authenticatedFetch, isAdmin } from '../services/authentication'
import { isValidEmail } from '../services/utils'
import useDebounce from '../services/useDebounce'
import { style } from '../themes/styles'
import Label from './core/Label'
import Input from './core/fields/Input'
import Button from './core/Button'
import EditableHeader from './core/EditableHeader'
import Modal from './core/Modal'
import SavingProgress from './SavingProgress'
import OrganizationMember from './OrganizationMember'
import { H2, Text } from '../constants/StyleComponents'
import { inviteMemberToOrganization, renameOrganization } from '../services/DataStore'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const Actions = style.StyledComponent.div`
  position: absolute;
  right: 40px;
  top: 40px;
  @media ${style.screen.sm} {
    height: 24px;
    right: 24px;
    top: 24px;
    width: 54px;
  }
  button {
    height: 40px;
    width: 70px;
    @media ${style.screen.sm} {
      height: 24px;
      width: 54px;
    }
  }
`

const Container = style.StyledComponent.div`
  overflow: auto;
  padding: 40px;
  position: relative;
  @media ${style.screen.sm} {
    padding: 24px;
  }
  @media ${style.screen.xs} {
    overflow-x: hidden;
  }
`

const CurrentMemberContent = style.StyledComponent.div`
  margin-top: 2em;
`

const CurrentMemberList = style.StyledComponent.ul`
  height: 280px;
  list-style: none;
  margin-top: 40px;
  overflow-y: scroll;
  padding: 0;
  @media ${style.screen.sm} {
    height: 240px;
  }
`

const InlineContainer = style.StyledComponent.div`
  width: 100%;
  display: inline-flex;
`

const InvitedMembersText = style.StyledComponent(Text)`
  font-size: clamp(0.75rem, 0.6429rem + 0.5357vw, 1.125rem);
`

const Header = style.StyledComponent.div`
  font-weight: 100;
  width: 347px;
`

const MergeActions = style.StyledComponent.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 16px;
`

const MergeButton = style.StyledComponent(Button)`
  float: right;
  margin-top: -15px;
`

const MergeUsersModal = style.StyledComponent.div`
  min-height: 20vh;
`

const NewUserContainer = style.StyledComponent.div`
  background-color: #ededed;
  border-radius: 7px;
  display: flex;
  margin: 15px 0;
`

const NewUserInput = style.StyledComponent(Input)`
  margin: 0 auto;
`

const NewUserRoleButton = style.StyledComponent.div`
  ${style.borderRadius};
  ${style.flexRow};
  background: ${style.color.cta};
  color: white;
  cursor: pointer;
  font-weight: 600;
  line-height: 4;
  padding: 0 ${style.grid * 2}px;
  position: relative;
  width: 150px;
  @media ${style.screen.sm} {
    line-height: 2;
  }
  @media ${style.screen.xs} {
    font-size: 12px;
    line-height: 1;
    padding-top: 20px;
    width: 52px;
  }
  white-space: nowrap;
`

const Title = style.StyledComponent(H2)`
  font-size: ${style.titleFont};
  @media ${style.screen.sm} {
  }
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export default function EditOrganizationUsers({ user, close }) {
  const [email, setEmail] = useState('')
  const [members, setMembers] = useState([])
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [saving, setSaving] = useState(false)
  const [successSaving, setSuccessSaving] = useState(false)
  const [mergeUsers, setMergeUsers] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(false)
  const [alertMessage, setAlertMessage] = useState(false)
  const [newOrgName, setNewOrgName] = useState(user.organization.name)

  useEffect(() => {
    setMembers(user.organization.members)
  }, [user])

  const inviteMember = async () => {
    if (isValidEmail(email)) {
      setSaving(true)
      setError(null)
      setSuccess(null)

      inviteMemberToOrganization(user.organization.id, email)
        .then(response => {
          const newMembers = response.members.filter(m => m.user_id !== user.id)
          setMembers(newMembers)

          setSuccess(`${email} has been invited to your organization ✔`)
          setEmail('')
          setSaving(false)
        })
        .catch(response => {
          setSaving(false)
          setError((response && response.message) || 'Something went wrong, try again.')
        })
    } else {
      setError('Invalid email address.')
    }
  }

  const rename = async organizationName => {
    renameOrganization(user.organization.id, organizationName)
    setNewOrgName(organizationName)
  }

  const closeAfterSuccess = () => {
    setSaving(false)
    setSuccessSaving(false)
    close()
  }

  const handleClose = () => {
    setError(null)
    setSuccess(null)
    close(newOrgName)
  }

  useEffect(() => {
    if (mergeUsers && searchTerm !== '') {
      setAlertMessage(false)
      setSelectedUser(false)
      authenticatedFetch(
        `users?search_term=${searchTerm}&statuses[]=approved&platform=${user.platform}&user_not=${user.id}&slim=true`
      ).then(users => {
        setUsers(users)
      })
    }
  }, [debouncedSearchTerm])

  const mergeMember = () => {
    authenticatedFetch(
      `organizations/${user.organization.id}/merge_member?merge_user_id=${selectedUser}`
    )
      .then(res => {
        setAlertMessage('Merged user')
        setMergeUsers(false)
        const newMembers = res.members.filter(m => m.user_id !== user.id)
        setMembers(newMembers)
      })
      .catch(err => {
        setAlertMessage(err.message || err.error)
      })
  }

  const formatResult = item => {
    return (
      <>
        <span>{item.name}</span>
        <br />
        <span>{item.username}</span>
      </>
    )
  }

  return (
    <Container>
      <Title>
        Manage Organization
        <InlineContainer>
          {user.organization.name === 'Modern Venture Partners' ? (
            <Header>{user.organization.name}</Header>
          ) : (
            <EditableHeader onEdit={rename} text={user.organization.name} />
          )}
          <Actions>
            <Button label='Close' action={handleClose} />
          </Actions>
        </InlineContainer>
      </Title>

      {success && <Text color={style.color.success}>{success}</Text>}
      {error && <Text color={style.color.warning}>{error}</Text>}

      <Label label='Add new member' />
      {user.organization.name === 'Modern Venture Partners' && (
        <Label label='(All members of this organization will be admins)' light={true} />
      )}
      <NewUserContainer>
        <NewUserInput label='email' type='text' value={email} onChange={value => setEmail(value)} />
        <NewUserRoleButton onClick={() => inviteMember()}>Invite Member</NewUserRoleButton>
      </NewUserContainer>

      <InvitedMembersText>
        Invited members have full administrative access but cannot add or remove members.
      </InvitedMembersText>

      <CurrentMemberContent>
        <Label label='Current members' />
        {isAdmin() && <MergeButton label='Merge Member' action={() => setMergeUsers(true)} />}
        <CurrentMemberList>
          {members.map((member, index) => (
            <OrganizationMember
              key={index}
              member={member}
              setMembers={setMembers}
              organization_id={user.organization.id}
              setError={setError}
              setSuccess={setSuccess}
              setSaving={setSaving}
            />
          ))}
        </CurrentMemberList>
      </CurrentMemberContent>
      {mergeUsers && (
        <Modal minHeight='20vh' show={mergeUsers} close={() => setMergeUsers(false)}>
          <MergeUsersModal>
            <h2>Merge Member into {user.organization.name}</h2>
            {alertMessage && <Text style={style.error}>{alertMessage}</Text>}
            <ReactSearchAutocomplete
              items={users}
              onSearch={value => setSearchTerm(value)}
              autoFocus
              formatResult={formatResult}
              onSelect={item => setSelectedUser(item.id)}
            />
            <MergeActions>
              <Button
                className='cancel'
                marginRight='10px'
                label='Cancel'
                action={() => setMergeUsers(false)}
              />
              <Button label='Move member' action={mergeMember} disabled={!selectedUser} />
            </MergeActions>
          </MergeUsersModal>
        </Modal>
      )}
      <SavingProgress saving={saving} success={successSaving} close={closeAfterSuccess} />
    </Container>
  )
}
