export default function dealsTableGlobalFilter(row, columnId, value, addMeta) {
  if (value.search) {
    let found = false
    const lowerCaseSearch = value.search.toLowerCase()
    for (const cell of row.getAllCells()) {
      const val = cell.getValue()
      if (val && val.toString().toLowerCase().includes(lowerCaseSearch)) {
        found = true
        break
      }
    }
    if (!found) return false
  }

  return (
    [row.original, ...row.original.follow_ons].filter(
      f =>
        (value.fund === 'all' || value.fund === f.fund_year) &&
        !value.stages.includes(f.stage_rollup)
    ).length > 0
  )
}
