import React, { useState } from 'react'
import { useParams } from 'react-router'
import OpportunityCommitments from '../../components/opportunity/OpportunityCommitments'
import ScrollContainer from '../../components/core/ScrollContainer'
import Header from '../../components/core/Header'
import { Content } from '../../constants/StyleComponents'

/**
 * Component for showing commitments for a deal
 * @returns {JSX.Element}
 * @constructor
 */
export default function DealCommitmentsRoute({ history }) {
  const { id } = useParams()

  const [isScrolled, setIsScrolled] = useState(false)

  const headerAction = {
    label: 'back',
    icon: 'arrow-left',
    action: () => history.push(`/admin/deals/${id}`)
  }

  return (
    <ScrollContainer hasHeader isScrolled={isScrolled} setIsScrolled={setIsScrolled}>
      <Header onAdmin isScrolled={isScrolled} leftAction={headerAction} />

      <Content isAdmin>
        <OpportunityCommitments id={id} />
      </Content>
    </ScrollContainer>
  )
}
