import React, { useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import styles from 'assets/scss/table.module.scss'
import FeatherIcon from 'feather-icons-react'
import FundFilter from './filters/FundFilter'
import StageFilter from './filters/StageFilter'
import dealsTableCols from './columns/dealsTableCols'
import dealsTableGlobalFilter from './filters/global/dealsTableGlobalFilter'
import useSWR from 'swr'
import { fetcher } from 'helpers/swr'
import LoadingSpinner from '../core/LoadingSpinner'

export default function DealsTable() {
  const { data, isValidating, isLoading } = useSWR('companies?limit=250&aggregated=true', fetcher, {
    revalidateOnFocus: false
  })
  const { data: stageRollups } = useSWR('stage_rollups', fetcher, {
    revalidateOnFocus: false
  })
  const [sorting, setSorting] = useState([{ id: 'Date', desc: true }])
  const [globalFilter, setGlobalFilter] = useState({ fund: 'all', stages: [] })
  const table = useReactTable({
    data: data || [],
    columns: dealsTableCols(globalFilter),
    state: {
      sorting,
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    globalFilterFn: dealsTableGlobalFilter
  })

  return (
    <>
      <div className='flex justify-end items-center gap-[6px] px-4'>
        <FundFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
        <StageFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          stageRollups={stageRollups?.map(s => s.name)}
        />
      </div>
      <div className='relative overflow-x-auto px-4'>
        {(isValidating || isLoading) && (
          <div className='absolute top-6 left-6'>
            <LoadingSpinner />
          </div>
        )}
        <table className={styles.table}>
          <thead className='bg-white'>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none flex items-center gap-1 whitespace-nowrap'
                              : '',
                            onClick: header.column.getToggleSortingHandler()
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <FeatherIcon icon='arrow-up-circle' size='16' />,
                            desc: <FeatherIcon icon='arrow-down-circle' size='16' />
                          }[header.column.getIsSorted()] ?? (
                            <span className='h-[1px] min-w-[16px]'></span>
                          )}
                        </div>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => {
              return (
                <tr
                  key={row.id}
                  className={styles.tableRow}
                  onClick={() => window.open(`/opportunities/${row.original.slug}`, '_blank')}
                >
                  {row.getVisibleCells().map(cell => (
                    <td className={styles.cell} key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}
