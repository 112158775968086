import React, { useCallback, useEffect } from 'react'
import {style} from '../../themes/styles'

/// /// STYLES >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const Container = style.StyledComponent.div`
  width: 100%;
  height: 100%;
  padding-top: ${props => (props.hasHeader ? style.vars.headerHeight : 0)}px;
  overflow: auto;
  ${style.vars.box};
`

/// /// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export default function ScrollContent({
  isScrolled,
  setIsScrolled,
  children,
  className,
  hasHeader,
  goToTop,
  setGoToTop
}) {
  const handleScroll = useCallback(
    event => {
      const scrolled = event.target.scrollTop > 0
      scrolled !== isScrolled && setIsScrolled(scrolled)
    },
    [isScrolled, setIsScrolled]
  )

  useEffect(() => {
    if (goToTop) {
      const container = document.getElementById('scroll_container')
      container.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      setGoToTop(false)
    }
  }, [goToTop])

  return (
    <Container
      id='scroll_container'
      onScroll={handleScroll}
      scrollEventThrottle={50}
      className={className}
      hasHeader={hasHeader}
      goToTop={goToTop}
      setGoToTop={setGoToTop}
    >
      {children}
    </Container>
  )
}
