import React, { useState, useEffect } from 'react'
import { style } from '../themes/styles'
import Button from './core/Button'
import SavingProgress from './SavingProgress'
import { H2 } from '../constants/StyleComponents'
import DisplayAndEditEntity from './DisplayAndEditEntity'
import { getEntitiesByUser, saveNewEntity, editEntity, deleteEntity } from '../services/DataStore'
import ConfirmDialog from './core/ConfirmDialog'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const Actions = style.StyledComponent.div`
  ${style.flexRow};
  display: grid;
  grid-template-columns: 75% 15% 10%;
  grid-gap: 10px;
  justify-content: space-between;
  margin-top: 40px;
  margin-right: 24px;
  @media ${style.screen.sm} {
    margin-top: 24px;
    margin-right: 40px;
    grid-template-columns: 0 50% 50%;
  }
`

const Container = style.StyledComponent.div`
  overflow: auto;
  padding: 40px;
  position: relative;
  @media ${style.screen.sm} {
    overflow-x: hidden;
    padding: 24px;
  }
`

const Title = style.StyledComponent(H2)`
  font-size: ${style.titleFont};
  padding-bottom: 20px;
  @media ${style.screen.sm} {
    padding-bottom: 12px;
  }
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export default function ManageEntities({ user, close }) {
  const [saving, setSaving] = useState(false)
  const [successSaving, setSuccessSaving] = useState(false)
  const [message, setMessage] = useState(null)
  const [entities, setEntities] = useState([])
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false)
  const [disableAdd, setDisableAdd] = useState(false)

  const handleAddEntity = async () => {
    setDisableAdd(true)
    const newEntity = [{ name: '', initial: false }]
    setEntities(entities.concat(newEntity))
  }

  const removeEntity = async (id, justCancel = false) => {
    const entitiesAux = entities
    setSaving(true)
    try {
      if (entitiesAux[id].total_funded === 0 || justCancel) {
        if (!justCancel) {
          await deleteEntity(entitiesAux[id].id)
        }
        entitiesAux.splice(id, 1)
        setEntities([...entitiesAux])
        setSaving(false)
      } else {
        setSuccessSaving(true)
        setMessage('Entities can not be deleted if they are being used to make commitments.')
      }
      setDisableAdd(false)
    } catch (error) {
      setDisableAdd(false)
      setSuccessSaving(true)
      setMessage('Error deleting entity, please try again')
    }
  }

  const editEntityName = async (id, newName) => {
    setSaving(true)
    try {
      await editEntity(id, newName)
      setSaving(false)
      setDisableAdd(false)
    } catch (err) {
      setDisableAdd(false)
      setSuccessSaving(true)
      setMessage('Error editing entity name, please try again')
    }
  }

  const createEntity = async (organizationId, name) => {
    try {
      setSaving(true)
      await saveNewEntity(organizationId, name, user.id)
      getAndSetEntities()
      setSaving(false)
      setDisableAdd(false)
    } catch (err) {
      setDisableAdd(false)
      setSuccessSaving(true)
      setMessage('Error creating entity, please try again')
    }
  }

  const closeAfterSuccess = () => {
    setSaving(false)
    setSuccessSaving(false)
    close()
  }

  const getAndSetEntities = async () => {
    const ent = await getEntitiesByUser(user.id)
    setEntities(ent)
  }

  useEffect(() => {
    getAndSetEntities()
    // eslint-disable-next-line
  }, [])

  return (
    <Container>
      <Title>Manage Entities</Title>
      {user && entities.length > 0 ? (
        entities.map((entity, id) => {
          return (
            <DisplayAndEditEntity
              user={user}
              saveNewEntity={createEntity}
              editEntity={editEntityName}
              removeEntity={(id, justCancel) =>
                setShowRemoveConfirm({ showModal: true, id: id, justCancel: justCancel })
              }
              id={id}
              entity={entity}
              key={id}
              initiallyDisabled={entity.initial}
              disableAdd={setDisableAdd}
              hideDeleteButton={entities.length === 1}
            />
          )
        })
      ) : (
        <div>No entities found.</div>
      )}

      <Actions>
        <div></div>
        <Button label='Add Entity' inactive={disableAdd} action={() => handleAddEntity()} />
        <Button label='Close' action={close} />
      </Actions>

      <ConfirmDialog
        show={showRemoveConfirm}
        close={() => setShowRemoveConfirm(false)}
        message='Are you sure you want to remove this entity?'
        action={() => removeEntity(showRemoveConfirm.id, showRemoveConfirm.justCancel)}
      />

      <SavingProgress
        saving={saving}
        success={successSaving}
        close={closeAfterSuccess}
        message={message}
      />
    </Container>
  )
}
