import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
const Tab = styled.div`
  display: inline-flex;
  padding: 1em;
  width: 150px;
  text-align: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 2em;
  font-weight: 600;
  letter-spacing: 0.4px;

  &:hover {
    background-color: #eee;
  }

  &.active {
    background-color: #d4dde9;
  }
`
// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export default function PlatformTab({ platformName, platform, setPlatform }) {
  const [active, setActive] = useState(false)

  useEffect(() => {
    if (platform !== platformName.toLowerCase()) {
      setActive(false)
    } else {
      setActive(true)
    }
  }, [platform, platformName])

  const switchPlatform = () => {
    setPlatform(platformName.toLowerCase())
  }
  return (
    <Tab className={active ? 'active' : ''} onClick={switchPlatform}>
      {platformName}
    </Tab>
  )
}
