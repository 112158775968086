import React, { useState, useEffect } from 'react'
import { style } from '../../themes/styles'
import { Content, PageHeader, PageTitle } from '../../constants/StyleComponents'
import ScrollContainer from '../../components/core/ScrollContainer'
import ListContainer from '../../components/core/ListContainer'
import Header from '../../components/core/Header'
import Button from '../../components/core/Button'
import Headings from '../../components/core/Headings'
import EditItem from '../../components/core/admin/EditItem'
import Modal from '../../components/core/Modal'
import { DataStore } from '../../services/DataStore'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const StyledPageTitle = style.StyledComponent(PageTitle)`
  margin: 0 auto 0 0;
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// Component descriptions / data.
const componentData = {
  dbName: 'investors',
  mainRoute: 'admin/investors',
  title: 'Investors',
  buttonLabel: 'New Investor',
  headingItems: [
    {
      label: 'Name',
      minWidth: 250,
      orderable: true
    }
  ],
  listColumns: ['name', 'created_at']
}

export default function Deals({ history }) {
  const [investors, setInvestors] = useState([])
  const [isScrolled, setIsScrolled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showNewDealModal, setShowNewDealModal] = useState(false)

  function loadInvestors() {
    setLoading(true)
    DataStore.findAll(componentData.dbName)
      .then(res => {
        if (res.length === 0) return
        setInvestors(
          res
            .map(r => ({ ...r, ...{ value: r.id, label: r.name } }))
            .sort((a, b) => a.label.localeCompare(b.label))
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    loadInvestors()
  }, [])

  function newDealDone() {
    loadInvestors()
    setShowNewDealModal(false)
  }

  return (
    <ScrollContainer hasHeader isScrolled={isScrolled} setIsScrolled={setIsScrolled}>
      <Header onAdmin isScrolled={isScrolled} />

      <Content isAdmin>
        <PageHeader>
          <StyledPageTitle>{componentData.title}</StyledPageTitle>
          <Button label={componentData.buttonLabel} action={() => setShowNewDealModal(true)} />
        </PageHeader>
        <Headings items={componentData.headingItems} />
        <ListContainer
          columns={componentData.listColumns}
          data={investors}
          loading={loading}
          route={componentData.mainRoute}
          history={history}
        ></ListContainer>
      </Content>
      <Modal show={showNewDealModal} shadeDoesNotClose close={() => setShowNewDealModal(false)}>
        {showNewDealModal && (
          <EditItem
            type='investor'
            dbName={componentData.dbName}
            route={componentData.mainRoute}
            close={newDealDone}
            history={history}
          />
        )}
      </Modal>
    </ScrollContainer>
  )
}
