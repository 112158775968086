import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { userInfo } from '../../services/authentication'
import ScrollContainer from '../../components/core/ScrollContainer'
import Header from '../../components/core/Header'
import { PageTitle, SectionContent } from '../../constants/StyleComponents'
import Portfolio from '../../components/Portfolio'
import Footer from '../../components/core/Footer'
import { Mixpanel } from 'services/mixpanel'

const Content = styled(SectionContent)`
  margin-bottom: 30%;
`

/**
 * Page for clients to view their portfolios
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function PortfolioPage() {
  const [isScrolled, setIsScrolled] = useState(null)
  const [organizationId, setOrganizationId] = useState(null)

  useEffect(() => {
    userInfo().then(u => setOrganizationId(u.organization.id))
    Mixpanel.track(`Portfolio View`)
  })

  return (
    <ScrollContainer hasHeader isScrolled={isScrolled} setIsScrolled={setIsScrolled}>
      <Header isScrolled={isScrolled} />
      <Content>
        <PageTitle>Your portfolio</PageTitle>
        <Portfolio organizationId={organizationId} />
      </Content>
      <Footer />
    </ScrollContainer>
  )
}
