import configuration from './config'
import jwt_decode from 'jwt-decode'
import qs from 'qs'
import { Mixpanel } from './mixpanel'

const JWT_STORAGE_KEY = 'jwt'
const USER_STORAGE_KEY = 'user'

/**
 * Will be set if the user is logged in
 * @type {Object}
 */
let _userInfo

function setToken(token) {
  window.localStorage.setItem(JWT_STORAGE_KEY, token)
}

export function setUser(user) {
  window.localStorage.setItem(USER_STORAGE_KEY, user)
}

export function loadUserInfo() {
  const _ui = window.localStorage.getItem(USER_STORAGE_KEY)
  if (_ui) {
    _userInfo = JSON.parse(_ui)
  }
}

// We need to declare the error messages here.
export const ERROR_TYPES = {
  INVALID_EMAIL_OR_PASSWORD: 'Invalid email or password.'
}

export function getToken() {
  // Maybe todo: use sessionStorage with a listener that allows the token to be shared between tabs
  return window.localStorage.getItem(JWT_STORAGE_KEY)
}

export function isAdmin() {
  if (!_userInfo) {
    loadUserInfo()
  }

  return _userInfo && _userInfo.is_admin
}

export function isSuperAdmin() {
  if (!_userInfo) {
    loadUserInfo()
  }

  return _userInfo && _userInfo.super_admin
}

export async function reloadUserInfo() {
  return authenticateWithToken()
}

async function authenticateWithToken() {
  const token = getToken()
  if (!token) {
    return Promise.reject()
  }

  return fetch(configuration.apiUrl + 'login', {
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      platform: currentPlatform()
    },
    body: JSON.stringify({
      platform: currentPlatform() // ??
    })
  }).then(handleLoginResponse)
}

const loginServerErrorMessage =
  'There was a problem connecting to the server. Please try again later.'

function handleLoginResponse(response, history, forwardTo) {
  return response.json().then(json => {
    if (response.ok) {
      const authKey = response.headers.get('Authorization')

      if (authKey) {
        setToken(authKey)
        if (!_userInfo) {
          json.hubspot_id && Mixpanel.identify(json.hubspot_id)
          Mixpanel.people.set({
            $first_name: json.person.fname,
            $last_name: json.person.lname,
            $email: json.username
          })
          Mixpanel.register_once({ created: json.created_at })
          Mixpanel.register({ platform: json.platform })
          Mixpanel.track('Login')
        }
        _userInfo = json
        setUser(JSON.stringify(_userInfo))
        if (history) {
          routeUserAfterLogin(history, forwardTo)
        }
        return Promise.resolve(_userInfo)
      } else {
        return Promise.reject({ message: loginServerErrorMessage })
      }
    } else {
      json.message = json.message || loginServerErrorMessage
      return Promise.reject(json)
    }
  })
}

/**
 * @param history - the history object
 * @param {string | undefined} forwardTo - path to forward to after logging in. Overrides the "forward_to" value in the location. Optional.
 */
export function routeUserAfterLogin(history, forwardTo) {
  const forwardPath =
    forwardTo || qs.parse(window.location.search, { ignoreQueryPrefix: true }).forward_to
  if (!isAdmin()) {
    if (hasSignedNDA()) {
      if (forwardPath) {
        history.push(forwardPath)
      } else {
        history.push('/opportunities')
      }
    } else {
      history.push('/nda')
    }
  } else {
    if (forwardPath) {
      history.push(forwardPath)
    } else {
      history.push('/admin/clients')
    }
  }
}

/**
 * Log in the user
 * @param email
 * @param password
 * @param {history} history - React history object. Optional. If present, user will be redirected to the appropriate place after logging in
 * @param {string | undefined} forwardTo - Where to forward the user after loggin in
 * @returns {Promise<Object>} A Promise that resolves if the login was successful, and rejects if it wasn't
 */
export async function logIn(email, password, history, forwardTo) {
  return fetch(configuration.apiUrl + 'login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      platform: currentPlatform()
    },
    body: JSON.stringify({
      user: {
        username: email,
        password: password,
        platform: currentPlatform()
      }
    })
  })
    .catch(e => {
      // provide a more friendly error message when fetch() can't get to the server
      return Promise.reject({
        message: loginServerErrorMessage
      })
    })
    .then(r => {
      return handleLoginResponse(r, history, forwardTo)
    })
}

/**
 * Calls fetch() with authentication headers set, and returns a promise that resolves with the parsed JSON response.
 *
 * @param {string} relativePath - Path to send the request to, relative to the API root
 * @param {object} init - The fetch init object
 * @returns {Promise<object>} The JSON returned by the request. The response headers will be included in the "header" property
 */
export async function authenticatedFetch(relativePath, init) {
  init = init || {}
  init.headers = init.headers || {}

  const token = getToken()
  if (!token) {
    throw new Error('The user must be authenticated to call this function')
  }

  init.headers.Authorization = token

  // default to json content type if it was not set and we are not submitting a multipart form
  if (!init.headers['Content-Type'] && !FormData.prototype.isPrototypeOf(init.body)) {
    init.headers['Content-Type'] = 'application/json'
  }

  return doFetch(relativePath, init)
}

/**
 * Calls fetch() with no authentication headers, and returns a promise that resolves with the parsed JSON response.
 *
 * @param {string} relativePath - Path to send the request to, relative to the API root
 * @param {object} init - The fetch init object
 * @returns {Promise<object>} The JSON returned by the request. The response headers will be included in the "header" property
 */
export async function doFetch(relativePath, init) {
  init = init || {}
  init.headers = init.headers || {}
  init.headers.platform = currentPlatform()

  return fetch.call(this, configuration.apiUrl + relativePath, init).then(async response => {
    const json = await response.json().catch(e => {
      console.error('Could not parse JSON')
      return Promise.resolve({})
    })

    if (response.status === 401) {
      clearUserInfoAndForwardToLogin()
      return
    }

    json.headers = [...response.headers.entries()].reduce((a, v) => {
      a[v[0]] = v[1]
      return a
    }, {})

    if (!response.ok) {
      throw json
    }

    return json
  })
}

/**
 * Registers the user
 *
 * @param email
 * @param fname
 * @param lname
 * @param password
 * @param isQualifiedPurchaser
 * @param qualifiedPurchaserStatus
 * @returns {Promise<Response>}
 */
export async function register(params) {
  const data = {
    email: params.email,
    password: params.password,
    person_fname: params.fname.trim(),
    person_lname: params.lname.trim(),
    platform: params.platform
  }

  if (params.isQualifiedPurchaser != null) {
    data.qualified_purchaser = params.isQualifiedPurchaser
  }

  if (params.qualifiedPurchaserStatus != null) {
    data.qualified_purchaser_status_id = params.qualifiedPurchaserStatus
  }

  if (params.linkedin_url != null) {
    data.linkedin_url = params.linkedin_url
  }

  if (params.accredited_investor_id != null) {
    data.accredited_investor_id = params.accredited_investor_id
  }

  return fetch(configuration.apiUrl + 'register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      platform: currentPlatform()
    },
    body: JSON.stringify(data)
  }).then(r => {
    const json = r.json().catch(e => {
      console.error('Could not parse JSON')
      return Promise.resolve({})
    })

    return !r.ok ? json.then(j => Promise.reject(j)) : json
  })
}

/**
 * Save the user values for marketing.
 * @param {string} email - The user's email address
 * @param {string} fname - The user's first name
 * @param {string} lname - The user's last name
 * @returns {Promise<Response>}
 */
export async function saveHubspotContact(email, fname, lname) {
  const data = {
    email: email,
    person_fname: fname,
    person_lname: lname,
    platform: currentPlatform()
  }

  return fetch(configuration.apiUrl + 'save_contact', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      platform: currentPlatform()
    },
    body: JSON.stringify(data)
  }).then(r => {
    const json = r.json()
    return !r.ok ? json.then(j => Promise.reject(j)) : json
  })
}

/**
 * Resend the registration email to the given email.
 *
 * @param email
 */
export async function resendRegistrationEmail(email) {
  return fetch(configuration.apiUrl + 'register/resend', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      platform: currentPlatform()
    },
    body: JSON.stringify({ email })
  }).then(r => {
    const json = r.json().catch(e => {
      console.error('Could not parse JSON')
      return Promise.resolve({})
    })

    return !r.ok ? json.then(j => Promise.reject(j)) : json
  })
}

/**
 * Send an email to the given addresss to reset their password.
 *
 * @param email
 */
export async function sendForgotPasswordEmail(email) {
  return fetch(configuration.apiUrl + 'passwords/request_reset', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      platform: currentPlatform()
    },
    body: JSON.stringify({ email })
  }).then(r => {
    const json = r.json().catch(e => {
      // request_reset returns no response
      return Promise.resolve({})
    })

    return !r.ok ? json.then(j => Promise.reject(j)) : json
  })
}

/**
 * Resend the registration email to the given email.
 *
 * @param {string} token
 * @param {string} password
 */
export async function resetPassword(token, password) {
  return fetch(configuration.apiUrl + 'passwords/reset', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      platform: currentPlatform()
    },
    body: JSON.stringify({
      reset_password_token: token,
      password
    })
  }).then(r => {
    const json = r.json().catch(e => {
      return Promise.resolve({})
    })

    return !r.ok ? json.then(j => Promise.reject(j)) : json
  })
}

/**
 * Changes the password
 * @param {string} currentPassword
 * @param {string} newPassword
 * @returns {Promise<object>}
 */
export async function changePassword(currentPassword, newPassword) {
  return authenticatedFetch('passwords/change', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      platform: currentPlatform()
    },
    body: JSON.stringify({
      current_password: currentPassword,
      new_password: newPassword
    })
  })
}

/**
 * Logs out the user
 */
export async function logOut() {
  return authenticatedFetch('logout', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      platform: currentPlatform()
    }
  }).finally(clearUserInfoAndForwardToLogin())
}

/**
 * Clears the user info and forwards the use to the login page
 * TODO (maybe): Add an argument for a "flash" message to show the user
 */
function clearUserInfoAndForwardToLogin() {
  _userInfo = undefined
  window.localStorage.clear()
  // Not sure how to get at the router from here, so doing this:
  // Probably should do something like this: https://reactrouter.com/web/example/auth-workflow
  // RW: Usually have to work with an app-wide eventemitter, and pickup the events in the App component for this kind of thing...
  window.location = `/?login=true&forward_to=${window.location.pathname}`
}

/**
 * @type {boolean} True if we have loaded user info since the last reload
 * The object in localStorage can get stale if the user or someone else edits things (e.g. changes names or adds commitments)
 * This flag ensures that the object is at least up-to-date as of the most recent page reload
 * TODO: Don't store user info in localStorage; fetch it before doing any routing
 */
let userInfoIsFresh = false

/**
 * Retrieves info about the logged-in user. If the user is not logged in, forces the user to the login page.
 *
 * TODO (if/once we allow users to edit themselves): return rx observable
 *
 * @returns {Promise}
 */
export async function userInfo() {
  if (!userInfoIsFresh || !_userInfo) {
    try {
      await authenticateWithToken()
      userInfoIsFresh = true
    } catch (e) {
      clearUserInfoAndForwardToLogin()
    }
  }
  return Promise.resolve(Object.assign({}, _userInfo))
}

/**
 * @returns {boolean} True if the user is logged in, false otherwise
 */
export function isLoggedIn() {
  // ensure token is correct format and not expired
  const token = window.localStorage.getItem(JWT_STORAGE_KEY)
  if (token) {
    try {
      const decoded = jwt_decode(token)
      const expDate = new Date(decoded.exp * 1000)

      return expDate >= new Date()
    } catch (e) {
      return false
    }
  }
  return false
}

/**
 * Confirms the users email
 *
 * @param {string} token
 * @returns {Promise<Response>}
 */
export async function confirmEmail(token) {
  return fetch(configuration.apiUrl + `confirmation?confirmation_token=${token}`).then(r => {
    const json = r.json().catch(e => {
      console.error('Could not parse JSON')
      return Promise.reject()
    })

    return !r.ok ? json.then(j => Promise.reject(j)) : json
  })
}

/**
 * Endpoint for the logged-in user to sign the most recent NDA
 * @returns {Promise<Object>} A promise that resolves when the response is received
 */
export async function signNDA() {
  await authenticatedFetch('register/sign_nda', { method: 'POST' })
  // request new user data from server
  return authenticateWithToken().catch(clearUserInfoAndForwardToLogin)
}

export function hasSignedNDA() {
  if (!_userInfo) {
    loadUserInfo()
  }
  return _userInfo && _userInfo.signed_most_recent_nda
}

export function userInfoSync(forceResync = false) {
  if (!_userInfo || forceResync) {
    loadUserInfo()
  }

  return _userInfo
}

/**
 * Site a.k.a. "platform" types
 * @type {{CHAMPION: string, MVP: string}}
 */
export const PLATFORMS = {
  CHAMPION: 'champion',
  MVP: 'mvp'
}

/**
 * Determines which platform/site the user is currently on
 *
 * @return {string} 'mvp'|'champion'
 */
export function currentPlatform() {
  const host = window.location.host
  // if (host.includes('localhost') && process.env.REACT_APP_PLATFORM) {
  // this depends no logged-in user
  if (isLoggedIn()) {
    if (!_userInfo) {
      loadUserInfo()
    }
    return _userInfo.platform
  } else {
    return 'mvp' // process.env.REACT_APP_PLATFORM
  }
  // }

  // if (host.includes('champ')) {
  //  return PLATFORMS.CHAMPION
  // }

  // return PLATFORMS.MVP
}

/**
 * Validate correct passwords.
 * @param {string} password - Password to validate
 */
export function validatePassword(password) {
  const errorMessage = []
  if (password.length < 8) {
    errorMessage.push('Password must be at least 8 characters long')
  }
  return errorMessage
}

/**
 * Validate available email.
 * @param {string} email - Email to validate
 * @param {string} platform - User platform
 */
export async function validateEmail(email) {
  return fetch(configuration.apiUrl + `email_available?email=${email}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(r => {
      if (r.status === 400) {
        return 'Please choose an available email address'
      } else {
        return null
      }
    })
    .catch(() => {
      return 'Please choose an available email address'
    })
}
