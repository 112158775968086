import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import sv from '../../themes/styles'
import useDebounce from '../../services/useDebounce'
import { Content, PageHeader, PageTitle } from '../../constants/StyleComponents'
import Header from '../../components/core/Header'
import Headings from '../../components/core/Headings'
import Button from '../../components/core/Button'
import Input from '../../components/core/fields/Input'
import MultiselectDropdown from '../../components/core/fields/MultiselectDropdown'
import ScrollContainer from '../../components/core/ScrollContainer'
import LoadingSpinner from '../../components/core/LoadingSpinner'
import Modal from '../../components/core/Modal'
import NewUser from '../../components/NewUser'
import ClientRow from '../../components/ClientRow'
import PlatformTab from '../../components/PlatformTab'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import UserStatusOptions from '../../constants/UserStatusOptions'

import { getUsersPage } from '../../services/DataStore'
import { currentPlatform, authenticatedFetch, PLATFORMS } from '../../services/authentication'
// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const StyledPageTitle = styled(PageTitle)`
  margin: 0;
`

const StyledPageHeader = styled(PageHeader)`
  margin-bottom: 32px;
`

const SearchField = styled(Input)`
  width: 200px;
  margin-left: auto;
  margin-right: ${sv.grid * 2}px;
`

const ListContainer = styled.div`
  overflow: auto;
  flex: 1;
`

const List = styled.div`
  flex: 1;
`

const Loading = styled.div`
  width: 100%;
  height: 300px;
  ${sv.flexCenter};
`

const PlatformTabs = styled.div`
  display: flex;
  margin-bottom: 2em;
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export default function Clients() {
  const [isScrolled, setIsScrolled] = useState(false)
  const [showNewClientModal, setShowNewClientModal] = useState(false)
  const [clients, setClients] = useState([])
  const [loading, setLoading] = useState(false)
  const [hasNextPage, setHasNextPage] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [filterStatuses, setFilterStatuses] = useState([
    'pending_approval',
    'pending_email_validation',
    'approved'
  ])
  const [searchMetadata, setSearchMetadata] = useState({
    order_by: 'created_at',
    order: 'desc'
  })
  const [platform, setPlatform] = useState(currentPlatform())
  const [clientCount, setClientCount] = useState(0)
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [headingItems, setHeadingItems] = useState([])

  const setClientsValues = newClients => {
    setClients([...clients, ...newClients])
    setClientCount(newClients.headers.total)
    if (
      newClients.headers &&
      Number(newClients.headers.offset) + Number(newClients.length) >= newClients.headers.total
    ) {
      setHasNextPage(false)
    }
  }

  const handleLoadMore = useCallback(() => {
    setLoading(true)

    getUsersPage({
      ...searchMetadata,
      offset: clients.length,
      statuses: filterStatuses,
      platform: platform
    }).then(results => {
      setLoading(false)
      setClientsValues(results)
    })

    headersUpdate()
  }, [clients, searchMetadata, filterStatuses, platform])

  const newSearch = useCallback(
    (force = false) => {
      clients.length = 0

      setClients(clients)
      setHasNextPage(true)
      handleLoadMore()
    },
    [handleLoadMore, clients]
  )

  const headersUpdate = () => {
    setHeadingItems([])
    const newHeadItems = [
      {
        label: 'Name',
        width: null,
        action: () => handleOrder('people.fname'),
        orderable: true,
        selected: searchMetadata.order_by === 'people.fname',
        asc: searchMetadata.order === 'asc'
      },
      {
        label: 'Invested (This Year)',
        width: 200,
        action: () => handleOrder('invested_current'),
        orderable: true,
        selected: searchMetadata.order_by === 'invested_current',
        asc: searchMetadata.order === 'asc'
      },
      {
        label: 'Invested (All-Time)',
        width: 200,
        action: () => handleOrder('invested_all_time'),
        orderable: true,
        selected: searchMetadata.order_by === 'invested_all_time',
        asc: searchMetadata.order === 'asc'
      },
      {
        label: 'Account Status',
        width: 200,
        action: () => handleOrder('status'),
        orderable: true,
        selected: searchMetadata.order_by === 'status',
        asc: searchMetadata.order === 'asc'
      },
      {
        label: 'Date Created',
        width: 200,
        action: () => handleOrder('created_at'),
        orderable: true,
        selected: searchMetadata.order_by === 'created_at',
        asc: searchMetadata.order === 'asc'
      },
      {
        label: 'Last login',
        width: 200,
        action: () => handleOrder('last_sign_in_at'),
        orderable: true,
        selected: searchMetadata.order_by === 'last_sign_in_at',
        asc: searchMetadata.order === 'asc'
      }
    ]

    setHeadingItems(newHeadItems)
  }

  useEffect(() => {
    setSearchMetadata({
      order_by: 'created_at',
      order: 'desc'
    })
    headersUpdate()
    setSearchMetadata({ ...searchMetadata, search_term: searchTerm })
  }, [platform])

  useEffect(() => {
    setSearchMetadata({ ...searchMetadata, search_term: debouncedSearchTerm })
    // do NOT listen to the linter here; if you add 'newSearch' or 'seearchMetaData' to the dependency array the view will get stuck in an infinite loop
  }, [debouncedSearchTerm])

  // Run the search when the searchMetadata changes
  useEffect(newSearch, [searchMetadata, filterStatuses, platform]) // do NOT add `newSearch` to the dependencies array here

  const handleOrder = property => {
    setSearchMetadata({
      ...searchMetadata,
      order_by: property,
      order: searchMetadata.order === 'asc' ? 'desc' : 'asc'
    })
  }

  const updateLocalClient = client => {
    const clientsCopy = clients
    const clientToUpdate = clientsCopy.find(c => c.id === client.id)
    clientToUpdate.status = client.status
    if (client.commitments) {
      clientToUpdate.commitments = [...client.commitments]
    }
    setClients([...clientsCopy])
  }

  const handleNewClientSuccess = () => {
    setShowNewClientModal(false)
    newSearch(true)
  }

  const handleFilterStatuses = statuses => {
    const newStatuses = statuses.map(status => status.value)
    setFilterStatuses(newStatuses)
  }

  const infiniteRef = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: handleLoadMore,
    scrollContainer: 'parent'
  })

  const defaultStatusFilters = UserStatusOptions.filter(option => {
    return !(option.value === 'disabled' || option.value === 'not_approved')
  })
  return (
    <ScrollContainer hasHeader isScrolled={isScrolled} setIsScrolled={setIsScrolled}>
      <Header onAdmin isScrolled={isScrolled} />

      <Content isAdmin>
        <StyledPageHeader>
          <StyledPageTitle>Clients ({clientCount})</StyledPageTitle>
          <SearchField
            noMargin
            icon='search'
            placeholder='Search clients...'
            onChange={value => setSearchTerm(value)}
          />
          <MultiselectDropdown
            options={UserStatusOptions} // Options to display in the dropdown
            placeholder='Filter by status...'
            onChange={handleFilterStatuses}
            displayValue='label'
            icon='filter'
            defaultValue={defaultStatusFilters}
          />
          <Button label='New Client' action={() => setShowNewClientModal(true)} />
        </StyledPageHeader>

        <PlatformTabs>
          <PlatformTab platformName='Champion' platform={platform} setPlatform={setPlatform} />
          <PlatformTab platformName='MVP' platform={platform} setPlatform={setPlatform} />
        </PlatformTabs>

        <Headings items={headingItems} />
        <ListContainer>
          <List ref={infiniteRef}>
            {clients &&
              clients.map((client, index) => (
                <ClientRow
                  key={index}
                  client={client}
                  updateLocalClient={updateLocalClient}
                  setClients={setClients}
                />
              ))}
            {loading && (
              <Loading>
                <LoadingSpinner />
              </Loading>
            )}
          </List>
        </ListContainer>
      </Content>
      <Modal show={showNewClientModal} close={() => setShowNewClientModal(false)} shadeDoesNotClose>
        <NewUser close={() => handleNewClientSuccess()} />
      </Modal>
    </ScrollContainer>
  )
}
