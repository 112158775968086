import React, { useState, useRef, useMemo } from 'react'
import { authenticatedFetch } from '../services/authentication'
import ResponsiveTable from './core/ResponsiveTable'
import ConfirmDialog from './core/ConfirmDialog'
import { dollarFormat } from '../constants/DollarsFormat'
import { style } from '../themes/styles'
import { Link, Text } from '../constants/StyleComponents'
import Input from './core/fields/Input'

/// /// STYLES >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const StyledLink = style.StyledComponent(Link)`
  color: ${style.color.championBlue};
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
`

const StyledInput = style.StyledComponent(Input)`
  border: 1px solid ${style.color.championBlue};
  width: 140px;
  display: inline;
  padding: 0;
  margin-bottom: 0;
  div {
    display: inline;
  }
  input {
    width: 120px;
    height: auto;
  }
`

/// /// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

/**
 *
 * @param {object} carries // Object with the list of carries to be displayed.
 * @param {function} setCarries // Fnction to update the parent state of carries.
 * @param {object} carryTracking // Object with the carry tracking info.
 */
const CarryTable = ({ carries, setCarries, carryTracking }) => {
  const [error, setError] = useState(false)
  const [originalCarries] = useState(carries)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [selectedCarry, setSelectedCarry] = useState(null)
  const [backupCarries, setBackupCarries] = useState([]) // Used to save the temporal info before save [*for sorting purposes].
  const updatedValues = useRef([])

  const handleCarryUpdate = carry => {
    setBackupCarries({ ...backupCarries, [carry.id]: carry })
    const carrylist = carries.map(c => {
      if (c.id === carry.id) {
        const updatedCarry = {
          ...c,
          on_edit: true
        }
        return updatedCarry
      }
      return c
    })
    setCarries(carrylist)
  }

  const remaining = useMemo(() => {
    let totalPercent = 100
    carries.forEach(c => {
      totalPercent -= parseFloat(c.carry)
    })
    return {
      totalPercent: totalPercent.toFixed(2),
      sum: carryTracking?.overall?.carry * (totalPercent * 0.01) || 0
    }
  }, [carries, carryTracking])

  const handleChange = (carry, type, value) => {
    carry[type] = value
    updatedValues.current[carry.id] = carry
  }

  const handleCarryDelete = carry => {
    const cData = carries.filter(c => c.id !== carry.id)
    setCarries(cData)
  }

  const removeCarry = () => {
    setShowConfirmDelete(false)
    authenticatedFetch(`carries/${selectedCarry.id}`, {
      method: 'DELETE'
    })
      .then(resp => {
        handleCarryDelete(selectedCarry)
      })
      .catch(err => {
        setError(err.message)
      })
  }

  const handleRemoveCarry = carry => {
    setShowConfirmDelete(true)
    setSelectedCarry(carry)
  }

  const cancelEdit = carry => {
    const originalCarryData = originalCarries.find(oc => oc.id === carry.id)

    const updatedCarries = carries.map(obj => {
      if (obj.id === carry.id) {
        const { on_edit, ...updatedCarry } = obj
        return {
          ...updatedCarry,
          carry: originalCarryData.carry
        }
      }
      return obj
    })

    setCarries(updatedCarries)
    // Remove from temporal carries
    delete backupCarries[carry.id]
    setBackupCarries(backupCarries)
  }

  const saveCarry = (e, carry) => {
    e.preventDefault()
    const carryData = updatedValues.current[carry.id]

    authenticatedFetch(`carries/${carry.id}`, {
      method: 'PUT',
      body: JSON.stringify(carryData)
    })
      .then(resp => {
        const updatedCarries = carries.map(obj => {
          return obj.id === resp.id ? resp : obj
        })

        setCarries(updatedCarries)
        // Remove from temporal carries
        delete backupCarries[carry.id]
        setBackupCarries(backupCarries)
      })
      .catch(err => {
        setError(err.message)
      })
  }

  const isEditableCarry = id => {
    const editableItems = Object.values(carries).filter(obj => obj.hasOwnProperty('on_edit'))
    for (let i = 0; i < editableItems.length; i++) {
      if (editableItems[i].id === id) {
        return true
      }
    }
    return false
  }

  return (
    <>
      {error && <Text color={style.vars.colors.warning}>{error}</Text>}
      <ResponsiveTable
        headingItems={[
          { title: 'Client', key: 'client' },
          { title: 'Carry %', key: 'carry' },
          { title: 'Carry', key: 'carrySum' },
          { title: '', key: 'edit' },
          { title: '', key: 'delete' }
        ]}
        pagination={false}
        defaultSortingColumn='carry'
        defaultSortingDirection='down'
        forceSorting
        dataItems={carries.map(carry => {
          const renderInput = (name, carryId, value, onChange) => {
            return isEditableCarry(carry.id) ? (
              <StyledInput
                className={
                  backupCarries.length > 0 ? Number(backupCarries[carryId][name]) : Number(value)
                }
                inputId={`${name}_${carryId}`}
                minValue={0}
                maxValue={100}
                type='number'
                value={value}
                onChange={onChange}
              />
            ) : (
              <span className={Number(value)}>{value}%</span>
            )
          }

          return {
            client: carry.user.name,
            carry: renderInput('carry', carry.id, carry.carry, value =>
              handleChange(carry, 'carry', value)
            ),
            carrySum: dollarFormat(
              carryTracking?.overall?.carry * (parseFloat(carry.carry) * 0.01) || 0
            ),
            edit: isEditableCarry(carry.id) ? (
              <StyledLink id={`edit_${carry.id}`} onClick={() => cancelEdit(carry)}>
                cancel
              </StyledLink>
            ) : (
              <StyledLink id={`edit_${carry.id}`} onClick={() => handleCarryUpdate(carry)}>
                edit
              </StyledLink>
            ),
            delete: isEditableCarry(carry.id) ? (
              <StyledLink id={`edit_${carry.id}`} onClick={e => saveCarry(e, carry)}>
                save
              </StyledLink>
            ) : (
              <StyledLink id={`delete_${carry.id}`} onClick={() => handleRemoveCarry(carry)}>
                delete
              </StyledLink>
            )
          }
        })}
        footerItems={
          carries
            ? [
                {
                  client: <b>Remaining</b>,
                  carry: `${remaining.totalPercent}%`,
                  carrySum: dollarFormat(remaining.sum),
                  edit: '',
                  delete: ''
                }
              ]
            : null
        }
      />
      <ConfirmDialog
        show={showConfirmDelete}
        close={() => setShowConfirmDelete(false)}
        message='Are you sure you want to remove this carry?'
        action={() => removeCarry()}
      />
    </>
  )
}

export default CarryTable
