import React, { useEffect, useState } from 'react'
import { style } from '../themes/styles'
import ConfirmDialog from './core/ConfirmDialog'
import Modal from './core/Modal'
import Label from './core/Label'
import FeatherIcon from 'feather-icons-react'
import OrganizationMemberDetails from './OrganizationMemberDetails'
import { removeMemberFromOrganization } from '../services/DataStore'
import { authenticatedFetch, userInfo } from '../services/authentication'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const BlankProfileImg = style.StyledComponent.div`
  background-color: #c4c4c4;
  border-radius: 999px;
  height: 40px;
  margin-right: 15px;
  width: 40px;
  @media ${style.screen.sm} {
    display: none;
  }
`

const CurrentMember = style.StyledComponent.li`
  display: flex;
  margin: 10px 0;
`

const CurrentMemberRoleButton = style.StyledComponent.div`
  ${style.borderRadius};
  ${style.flexRow};
  color: ${props => props.textColor};
  cursor: pointer;
  height: ${style.buttonHeight}px;
  padding: 0 ${style.grid * 2}px;
  position: relative;
`

const Left = style.StyledComponent.div`
  display: inline-flex;
  flex: 1;
`

const MemberLabel = style.StyledComponent(Label)`
  height: 16px;
  position: relative;
`

const MemberName = style.StyledComponent.p`
  font-size: 16px;
`

const Right = style.StyledComponent.div`
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export default function OrganizationMember({
  organization_id,
  member,
  setMembers,
  setError,
  setSuccess,
  setSaving
}) {
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false)
  const [isExisting, setIsExisting] = useState(true)
  const [showClientDetails, setShowClientDetails] = useState(false)
  const [selectedMember, setSelectedMember] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    userInfo().then(usr => {
      setCurrentUser(usr)
    })
  }, [])

  const removeMember = () => {
    setSaving(true)

    setSuccess(null)
    setError(null)

    removeMemberFromOrganization(organization_id, member.username)
      .then(response => {
        setSaving(false)
        setSuccess(`${member.username} has been removed from your organization ✔`)

        setIsExisting(false)
      })
      .catch(response => {
        setSaving(false)
        setError(response.message)
      })
  }

  if (!isExisting) {
    return <></>
  }

  const handleAdminManage = member => {
    if (member.is_site_admin && member.user_id !== currentUser.id) {
      setShowClientDetails(true)
      authenticatedFetch(`users/${member?.user_id}`).then(data => {
        setSelectedMember(data)
      })
    }
  }

  return (
    <>
      <CurrentMember
        onClick={() => {
          handleAdminManage(member)
        }}
      >
        <Left>
          <BlankProfileImg />
          <MemberName>
            {member.username}
            {!member.is_site_admin && (
              <span>
                <MemberLabel label={member.role === 'admin' ? 'Admin' : 'Member'} />
              </span>
            )}
          </MemberName>
        </Left>
        {member.is_site_admin && member.user_id !== currentUser?.id && (
          <>
            <CurrentMemberRoleButton>
              <FeatherIcon icon='edit' />
            </CurrentMemberRoleButton>
          </>
        )}
        {!member.is_site_admin && (
          <>
            {member.role !== 'admin' && (
              <FeatherIcon icon='trash-2' onClick={() => setShowRemoveConfirm(true)} />
            )}
          </>
        )}
      </CurrentMember>

      <ConfirmDialog
        show={showRemoveConfirm}
        close={() => setShowRemoveConfirm(false)}
        message='Are you sure you want to remove this member?'
        action={() => removeMember()}
      />

      <Modal minHeight='10vh' show={showClientDetails} close={() => setShowClientDetails(false)}>
        <OrganizationMemberDetails
          member={selectedMember}
          setSelectedMember={setSelectedMember}
          setMembers={setMembers}
          close={() => setShowClientDetails(false)}
          setSuccess={setSuccess}
        />
      </Modal>
    </>
  )
}
