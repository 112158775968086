import React from 'react'
import { ExternalLink, Link } from '../../constants/StyleComponents'
import sv, { style } from '../../themes/styles'
import history from '../../constants/History'
import Button from './Button'
import { currentPlatform, isLoggedIn, PLATFORMS } from '../../services/authentication'
import whiteMvp from 'assets/images/mvp-white.png'
import styled from 'styled-components'

/// /// STYLES >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const Container = style.StyledComponent.div`
  align-items: center;
  display: grid;
  background: ${style.color.mvpGray};
  border-top: 0.5px solid ${style.color.white};
  color: ${style.vars.colors.buttonText};
  grid-template-columns: auto;
  
  @media ${style.screen.gt_sm} {
    grid-template-columns: 40% 60%;
    padding: 3vh 5vw;
  }
`

const BackToTop = style.StyledComponent.div`
  margin-top: 20px;
  text-align: center;
  
  p {
    color: ${style.color.white};
    margin-bottom: 0px;
  }

  @media ${style.screen.gt_sm} {
    display: none;
  }
`

const FooterLogo = style.StyledComponent.img`
  margin: 20px auto;
  max-width: 100px;
  
  @media ${style.screen.gt_sm} {
    margin: 0;
  }
`

const FooterOptions = style.StyledComponent.div`
  display: none;
  
   @media ${style.screen.gt_sm} {
    display: grid;
  }
`

const FooterLoggedOptions = style.StyledComponent.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(100px, 1fr) );
  text-align: center;
`

const Item = style.StyledComponent(Link)`
  color: ${style.color.white};
  font-size: 14px;
`

const ExternalItem = styled(ExternalLink)`
  color: ${style.color.white};
  font-size: 14px;
`

const StyledButton = style.StyledComponent(Button)`
  background: ${style.color.mvpGray};
  border: 1px solid ${style.color.white};
  color: ${style.color.white};
  margin: 10px 5px;
  white-space: normal;
`

/// /// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//  Footer for the new MVP design,
// if the new design will be applied to all the site then replaces the current web footer.

/**
 * @param {string} className - Custom class name to apply to the footer.
 * @param {function} setGoToTop - Function to scroll to the top of the page.
 * @returns {JSX.Element}
 * @constructor
 */
export default function CustomFooter({ children, className, setGoToTop }) {
  const loggedIn = isLoggedIn()
  const callLink =
    currentPlatform() === PLATFORMS.CHAMPION
      ? 'https://calendly.com/champion-investor-relations/champion'
      : 'https://calendly.com/andre-mvp/catching-up-with-mvp'
  const emailLink = 'mailto:founders@mvp-vc.com'

  return (
    <>
      <Container className={className}>
        <BackToTop>
          <p onClick={() => setGoToTop(true)}>Back to top</p>
        </BackToTop>
        <FooterLogo src={whiteMvp} />
        <FooterOptions>
          {loggedIn && (
            <FooterLoggedOptions>
              <ExternalItem target='_blank' href='https://mvp-vc.com/team'>
                Our Team
              </ExternalItem>
              <StyledButton label='Meet Us' action={() => window.open(callLink, '_blank')} />
              <StyledButton action={() => window.open(emailLink)} label='Email Us' />
            </FooterLoggedOptions>
          )}
          {!loggedIn && (
            <>
              <div></div>
              <div></div>
              <StyledButton label='Log in' action={() => history.push('/?login=true')} />
              <StyledButton label='Register' action={() => history.push('/register')} />
            </>
          )}
        </FooterOptions>
      </Container>
    </>
  )
}
