import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import ScrollContainer from '../../components/core/ScrollContainer'
import Header from '../../components/core/Header'
import { PageTitle, SectionContent } from '../../constants/StyleComponents'
import Portfolio from '../../components/Portfolio'
import { DataStore } from '../../services/DataStore'
import LogoLetter from '../../components/LogoLetter'
import { authenticatedFetch, isLoggedIn } from '../../services/authentication'
import HeaderNew from 'components/core/HeaderNew'
import logo from '../../assets/images/mvp-logo-new.png'
import styles from 'assets/scss/table.module.scss'

/**
 * Page for admins to view client portfolios
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function ClientPortfolio() {
  const { user_id } = useParams()
  const { search } = window.location
  const query = new URLSearchParams(search).get('type')
  const isOrganization = query === 'org'

  const [isScrolled, setIsScrolled] = useState(false)
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (isOrganization) {
      authenticatedFetch(`organizations/${user_id}`).then(setUser)
    } else {
      DataStore.find('users', user_id).then(setUser)
    }
  }, [user_id, setUser])

  return (
    <ScrollContainer hasHeader isScrolled={isScrolled} setIsScrolled={setIsScrolled}>
      <Header className={!isLoggedIn() ? 'blue-header' : 'whitex'} isScrolled={isScrolled} />
      {user && (
        <div className='relative'>
          <Portfolio
            platform={isOrganization ? user.org_type : user.platform}
            organizationId={isOrganization ? user_id : user.organization.id}
            userId={isOrganization ? null : user_id}
            user={user}
          />
        </div>
      )}
    </ScrollContainer>
  )
}
