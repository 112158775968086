import React, { useCallback, useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { FilePicker } from 'react-file-picker'
import styled from 'styled-components'
import sv from '../../themes/styles'
import { DataStore } from '../../services/DataStore'
import { PageTitle, TextLink } from '../../constants/StyleComponents'
import { authenticatedFetch, PLATFORMS } from '../../services/authentication'
import Button from '../core/Button'
import ResponsiveTable from '../core/ResponsiveTable'
import EditCommitment from './EditCommitment'
import { dateFormat } from '../../services/TimeUtils'
import { dollarFormat } from '../../constants/DollarsFormat'
import Dropdown from './../core/fields/Dropdown'
import championLogo from 'assets/images/champion.png'
import mvpLogo from 'assets/images/mvp.png'

// STYLE ##########################################################

const Header = styled.div`
  ${sv.flexRow};
`

const SelectActions = styled.div`
  ${sv.flexRow};
  justify-content: flex-end;
  margin-bottom: ${sv.grid * 2}px;
  margin-left: auto;

  span {
    margin-right: ${sv.grid * 2}px;
  }
`

const Actions = styled.div`
  margin: ${sv.grid * 2}px 0;
  ${sv.flexRow};
  justify-content: flex-end;
`

const Export = styled(CSVLink)`
  ${sv.buttonBase};
  text-decoration: none;
  margin-right: ${sv.grid}px;
`

const Upload = styled.span`
  ${sv.buttonBase};
  margin-right: ${sv.grid}px;
`

const ErrorUL = styled.ul`
  color: ${sv.colors.danger};
`

const SuccessMsg = styled.div`
  color: ${sv.colors.success};
  font-size: 1.25em;
  margin-bottom: 10px;
`

const ErrorMsg = styled.div`
  color: ${sv.colors.danger};
  font-size: 1.25em;
`

const Title = styled(PageTitle)`
  font-size: clamp(1rem, 0.7143rem + 1.4286vw, 2rem);
`

// COMPONENT ##########################################################

const CSV_ROWS = [
  'User_ID',
  'Name',
  'Organization',
  'Entity_ID',
  'Entity',
  'Fund Investment',
  'Platform',
  'Requested Amount',
  'Distributed Amount',
  'Fee years',
  'Final Invested',
  'Final Fees',
  'Final Total',
  'Notified'
]
const ORIGINS = [
  { value: '', label: 'All' },
  { value: 'champion', label: 'Champion' },
  { value: 'mvp', label: 'MVP' }
]
/**
 * Shows a table with commitments for an opportunity
 *
 * @param {number} id - The id of the opportunity to show commitments for
 * @returns {JSX.Element}
 * @constructor
 */
export default function OpportunityCommitments({ id }) {
  const [opportunity, setOpportunity] = useState(null)
  const [commitments, setCommitments] = useState(null)
  const [totalInvestments, setTotalInvestments] = useState(null)
  const [totalFees, setTotalFees] = useState(null)
  const [totalAmtRequested, setTotalAmtRequested] = useState(null)
  const [commitmentsCsv, setCommitmentsCsv] = useState([])
  const [editCommitment, setEditCommitment] = useState(false)
  const [message, setMessage] = useState(null)
  // an array of error messages
  const [errorMessages, setErrorMessages] = useState(null)
  const [notificationCheckboxes, setNotificationCheckboxes] = useState([])
  const [origin, setOrigin] = useState({ value: '' })
  const [organizationCount, setOrganizationCount] = useState(0)
  const [tableCommitments, setTableCommitments] = useState([])

  function setCommitmentValues(commitments) {
    setCommitments(commitments.sort(c => c.user?.name))
    const mvpCommitments = commitments.filter(commitment => commitment.platform === PLATFORMS.MVP)
    const championCommitments = commitments.filter(
      commitment => commitment.platform === PLATFORMS.CHAMPION
    )
    setTotalInvestments({
      total: commitments.reduce((a, c) => a + Number(c.final_amount_invested || 0), 0),
      mvp: mvpCommitments.reduce((a, c) => a + Number(c.final_amount_invested || 0), 0),
      champion: championCommitments.reduce((a, c) => a + Number(c.final_amount_invested || 0), 0)
    })
    setTotalFees({
      total: commitments.reduce((a, c) => a + Number(c.final_amount_fee || 0), 0),
      mvp: mvpCommitments.reduce((a, c) => a + Number(c.final_amount_fee || 0), 0),
      champion: championCommitments.reduce((a, c) => a + Number(c.final_amount_fee || 0), 0)
    })
    setTotalAmtRequested({
      total: commitments.reduce((a, c) => a + Number(c.amount_requested || 0), 0),
      mvp: mvpCommitments.reduce((a, c) => a + Number(c.amount_requested || 0), 0),
      champion: championCommitments.reduce((a, c) => a + Number(c.amount_requested || 0), 0)
    })
  }

  // Fetch and set commitments for CSV
  const fetchCommitments = (force = true) => {
    DataStore.clear()
    DataStore.findAll('commitments', { opportunity_id: opportunity.id }, { force: force }).then(
      res => {
        setCommitmentValues(res)
        setOrganizationCount(
          [...new Set(res.filter(c => c.final_amount_invested > 0).map(c => c.organization))].length
        )
        // sortList calls setCommitments.
        setCommitments(res)
      }
    )
  }

  /**
   * Fetch and set opportunities and commitments objects asynchronously, and sort commitments for displaying on table
   */
  useEffect(() => {
    DataStore.clear()
    DataStore.find('opportunities', id).then(opp => {
      setOpportunity(opp)
      DataStore.findAll('commitments', { opportunity_id: opp.id }, { force: true }).then(
        commitments => {
          setOpportunity(opp)
          setCommitmentValues(commitments)
          setOrganizationCount(
            [
              ...new Set(
                commitments.filter(c => c.final_amount_invested > 0).map(c => c.organization)
              )
            ].length
          )
          // sortList calls setCommitments.
          setCommitments(commitments)
        }
      )
    })
    // eslint-disable-next-line -- adding sortList to dependency list causes infinite sorting
  }, [id])

  const handleSelectAllChecboxes = checkedValue => {
    const inputs = document.getElementsByTagName('input')
    const checkedCommitments = []
    for (const input of inputs) {
      if (input.type === 'checkbox') {
        input.checked = checkedValue
        checkedValue && checkedCommitments.push(input.id)
      }
    }
    setNotificationCheckboxes(checkedCommitments)
  }

  const prepareCSV = useCallback(() => {
    const rows = [CSV_ROWS]

    commitments.forEach(c => {
      const csvData = [
        c.user.id || null,
        `${c.name}`,
        c.organization.name,
        c.entity.id,
        c.entity.name,
        c.vintage_investment_amount ?? c.collection_investment_amount,
        c.platform,
        c.amount_requested,
        c.distributed_amount,
        c.fee_years,
        c.final_amount_invested,
        c.final_amount_fee,
        c.final_amount_total
      ]

      rows.push(csvData)
    })

    setCommitmentsCsv(rows)
    // eslint-disable-next-line
  }, [commitments])

  const csvPicked = useCallback(
    fileObject => {
      setMessage(null)
      setErrorMessages(null)
      const formData = new FormData()
      formData.append('csv', fileObject)

      authenticatedFetch(`opportunities/${opportunity.id}/commitments/csv`, {
        method: 'POST',
        body: formData
      })
        .then(resp => {
          fetchCommitments(true)
          setMessage(resp.message || 'Commitments updated')
        })
        .catch(resp => {
          setMessage(resp.message || 'Commitments could not be updated')
          resp.errors && setErrorMessages(resp.errors)
        })
    },
    [opportunity]
  )

  const sendNotifications = () => {
    const usersToNotify = []

    if (notificationCheckboxes.length === 0) {
      setMessage('No users selected; use the checkboxes to select users to send notifications to')
      return true
    } else {
      setMessage('Sending notifications...')
      commitments.forEach(commitment => {
        if (notificationCheckboxes.includes(commitment.id.toString())) {
          usersToNotify.push(commitment)
        }
      })
    }

    const notificationPromises = usersToNotify.map(c => {
      return c.sendNotification().catch(err => {
        // eslint-disable-next-line no-throw-literal
        throw `Could not send notification to ${c.user.username}: ${err.message || err}`
      })
    })

    Promise.allSettled(notificationPromises).then(results => {
      const failures = results.filter(r => r.status === 'rejected').map(r => r.reason)
      if (failures.length === 0) {
        setMessage('All selected notifications sent')
      } else {
        if (failures.length === notificationPromises.length) {
          setMessage('No notifications sent')
        } else {
          setMessage('Only some notifications were sent')
        }
        setErrorMessages(failures)
      }
      fetchCommitments()
    })
  }

  const handleCheckboxClick = e => {
    const commitmentId = e.target.id
    let checkedCommitments = notificationCheckboxes || []
    if (checkedCommitments?.includes(commitmentId)) {
      checkedCommitments = checkedCommitments.filter(c => c !== commitmentId)
    } else {
      checkedCommitments.push(commitmentId)
    }
    setNotificationCheckboxes(checkedCommitments)
  }

  const commitmentsData = () => {
    const data = []

    commitments.forEach(commitment => {
      const og_logo = commitment.platform === 'mvp' ? mvpLogo : championLogo
      data.push({
        id: commitment.id,
        name: commitment.name,
        origin: commitment.platform,
        origin_logo: og_logo,
        created_at: dateFormat(commitment.created_at),
        fundCommitment: dollarFormat(
          commitment.vintage_investment_amount ?? commitment.collection_investment_amount
        ),
        amount_requested: dollarFormat(commitment.amount_requested),
        fee_years: commitment.fee_years,
        final_invested: dollarFormat(commitment.final_amount_invested, true),
        final_fees: dollarFormat(commitment.final_amount_fee, true),
        final_total: dollarFormat(commitment.final_amount_total, true),
        notified: commitment.client_notified_at
          ? `${dateFormat(commitment.client_notified_at)}`
          : '2/01/01',
        notified_dates: commitment.client_notified_at ? (
          `Sent ${dateFormat(commitment.client_notified_at)}`
        ) : (
          <input type='checkbox' id={commitment.id} onClick={handleCheckboxClick} />
        ),
        clickEvent: () => setEditCommitment(commitment),
        hiddenItem: origin.value !== '' && commitment.platform !== origin.value
      })
    })

    return data
  }

  useEffect(() => {
    commitments && setTableCommitments(commitmentsData)
  }, [commitments, origin])

  return (
    <>
      {opportunity && (
        <>
          <Header>
            <Title>
              Commitments for {opportunity.name} ({organizationCount})
            </Title>
            <SelectActions>
              <Dropdown
                small
                placeholder='Origins'
                style={{ margin: '5px 15px 0 0', width: '140px' }}
                value={origin.value}
                options={ORIGINS || []}
                onChange={val => setOrigin(val)}
              />
              <TextLink onClick={() => handleSelectAllChecboxes(true)}>Select All</TextLink>
              <TextLink onClick={() => handleSelectAllChecboxes(false)}>Select None</TextLink>
            </SelectActions>
          </Header>

          {message && message === 'Commitments updated' ? (
            <SuccessMsg>{message}</SuccessMsg>
          ) : (
            <ErrorMsg>{message}</ErrorMsg>
          )}

          {errorMessages && (
            <ErrorUL>
              {errorMessages.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ErrorUL>
          )}
          {commitments && (
            <>
              <ResponsiveTable
                headingItems={[
                  { title: 'Name', key: 'name' },
                  { title: '', key: 'origin' },
                  { title: 'Origin', key: 'origin_logo' },
                  { title: 'Date', key: 'created_at' },
                  { title: 'Fund Commitment', key: 'fundCommitment' },
                  { title: 'Requested Amount', key: 'amount_requested' },
                  { title: 'Fee years', key: 'fee_years' },
                  { title: 'Final Invested', key: 'final_invested' },
                  { title: 'Final Fees', key: 'final_fees' },
                  { title: 'Final Total', key: 'final_total' },
                  { title: '', key: 'notified' },
                  { title: 'Notified', key: 'notified_dates' }
                ]}
                dataItems={tableCommitments}
                pagination={false}
                pageItems={10000}
                hiddenItemsByText={['origin', 'notified']}
                hiddenItemsConditional={['_logo', '_dates']}
              />
              <ResponsiveTable
                headingItems={[
                  { title: '', key: 'name' },
                  { title: 'Requested Amount', key: 'amount_requested' },
                  { title: 'Final Invested', key: 'final_invested' },
                  { title: 'Final Fees', key: 'final_fees' },
                  { title: 'Final Total', key: 'final_total' }
                ]}
                dataItems={[
                  {
                    name: opportunity?.isDealshare() ? 'MVP Co-Invests' : 'MVP Users',
                    amount_requested: dollarFormat(totalAmtRequested?.mvp, true),
                    final_invested: dollarFormat(totalInvestments?.mvp, true),
                    final_fees: dollarFormat(totalFees?.mvp, true),
                    final_total: dollarFormat(totalFees?.mvp + totalInvestments?.mvp, true)
                  },
                  {
                    name: opportunity?.isDealshare() ? 'Champion Co-Invests' : 'Champion Users',
                    amount_requested: dollarFormat(totalAmtRequested?.champion, true),
                    final_invested: dollarFormat(totalInvestments?.champion, true),
                    final_fees: dollarFormat(totalFees?.champion, true),
                    final_total: dollarFormat(
                      totalFees?.champion + totalInvestments?.champion,
                      true
                    )
                  },
                  {
                    name: opportunity?.isDealshare() ? 'Total Co-Invests' : 'Total',
                    amount_requested: dollarFormat(totalAmtRequested?.total, true),
                    final_invested: dollarFormat(totalInvestments?.total, true),
                    final_fees: dollarFormat(totalFees?.total, true),
                    final_total: dollarFormat(totalFees?.total + totalInvestments?.total, true),
                    cellClassName: 'semi-bold'
                  },
                  {
                    name: 'Fund Investments',
                    amount_requested: '',
                    final_invested: dollarFormat(opportunity.valuation.fund_total || 0, true),
                    final_fees: '',
                    final_total: dollarFormat(opportunity.valuation.fund_total || 0, true),
                    hiddenItem: !opportunity?.isDealshare(),
                    cellClassName: 'border-top'
                  },
                  {
                    name: 'Total',
                    amount_requested: '',
                    final_invested: dollarFormat(
                      Number(opportunity.valuation.fund_total || 0) + totalInvestments?.total,
                      true
                    ),
                    final_fees: '',
                    final_total: dollarFormat(
                      Number(opportunity.valuation.fund_total || 0) +
                        totalFees?.total +
                        totalInvestments?.total,
                      true
                    ),
                    hiddenItem: !opportunity?.isDealshare(),
                    cellClassName: 'border-top bold'
                  }
                ]}
                pagination={false}
                minHeight='160px'
                noSorting
              />
              {
                <>
                  <Actions>
                    <Export
                      data={commitmentsCsv}
                      onClick={prepareCSV}
                      filename={opportunity.name + '-commitments.csv'}
                    >
                      Export CSV
                    </Export>
                    <FilePicker
                      extensions={['csv']}
                      onChange={csvPicked}
                      maxSize={2}
                      onError={errMsg => {
                        setMessage('Problem picking file:')
                        setErrorMessages([errMsg])
                      }}
                    >
                      <Upload>Upload CSV</Upload>
                    </FilePicker>
                    <Button action={sendNotifications} label='Send email notification to members' />
                  </Actions>

                  <EditCommitment
                    commitment={editCommitment}
                    show={editCommitment}
                    close={() => setEditCommitment(false)}
                  />
                </>
              }
            </>
          )}
        </>
      )}
    </>
  )
}
