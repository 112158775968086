import React, { useEffect, useState } from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { authenticatedFetch } from '../../services/authentication'
import { DataStore } from '../../services/DataStore'
import useDebounce from '../../services/useDebounce'
import { style } from 'themes/styles'
import Button from '../core/Button'
import Input from '../core/fields/Input'
import { H2, Text, Link } from '../../constants/StyleComponents'
import Modal from '../core/Modal'
import CarryTable from '../CarryTable'
import { dollarFormat } from '../../constants/DollarsFormat'
import styled from 'styled-components'
import sv from '../../themes/styles'
import MoicToggle from '../MoicToggle'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const ButtonsContainer = style.StyledComponent.div`
  display: grid;
  grid-template-columns: 50% 50%;
  margin-left: 60%;
  margin-top: 32px;
  width: 40%;
  margin-bottom: 20px;
`

const CarryUsersModal = style.StyledComponent.div`
  min-height: 20vh;
`

const Container = style.StyledComponent.div`
  ${style.vars.flexColumn};
  flex: 1;
  overflow: hidden;
`

const ErrorContainer = style.StyledComponent.div`
  position: absolute;
  top: 85px;
`

const Header = style.StyledComponent.div`
  ${style.vars.flexRow};
  flex: 0 0 auto;
  padding-bottom: ${style.vars.appPadding}px;
`

const CarryActions = style.StyledComponent.div`
  ${style.vars.flexRow};
  justify-content: flex-end;
  margin-left: 365px;
  margin-top: 20px;
  font-size: 15px;
`

const CarryInputs = style.StyledComponent.div`
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 1rem;
  margin-top: 32px;
`

const Title = style.StyledComponent(H2)`
  margin-right: auto;
`

const StyledLink = style.StyledComponent(Link)`
  color: ${style.color.championBlue};
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
`

const DealInfo = styled.div`
  ${sv.flexRow};
  max-width: 600px;
  margin-bottom: 15px;
  align-items: baseline;
`
const InfoHeader = styled.div`
  font-weight: bold;
  border-bottom: 1px solid #ccc;
`
const InfoLabel = styled.div`
  display: inline-block;
  width: 130px;
`
const ValuationInfo = styled.div`
  flex: 1;
  padding-right: 15px;
`
const CarryInfo = styled.div`
  flex: 1;
`
const InlineDiv = styled.div`
  display: inline-flex;
  cursor: pointer;
`
const Filters = styled.div`
  margin-top: -20px;
  text-align: right;
`

const OpportunityCarries = ({ id }) => {
  const [loading, setLoading] = useState(false)
  const [deal, setDeal] = useState(null)
  const [error, setError] = useState(false)
  const [users, setUsers] = useState([])
  const [clientModal, setClientModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [selectedUser, setSelectedUser] = useState(false)
  const [carry, setCarry] = useState(0)
  const [carryData, setCarryData] = useState([])
  const [editPotentialMoic, setEditPotentialMoic] = useState(false)
  const [pMoic, setPMoic] = useState(3)
  const [showSaveAll, setShowSaveAll] = useState(false)
  const [currentMoic, setCurrentMoic] = useState(0)
  const [carryTracking, setCarryTracking] = useState(null)

  const handleAddClient = () => {
    handleUsers()
    setError(null)
    setClientModal(true)
  }

  const handleCreateCarry = () => {
    const clientData = {
      user_id: selectedUser,
      opportunity_id: deal.id,
      carry
    }
    authenticatedFetch(`carries`, {
      method: 'POST',
      body: JSON.stringify(clientData)
    })
      .then(resp => {
        setError(null)
        setSelectedUser(false)
        setCarry(0)
        setClientModal(false)
        loadCarryUsers(deal)
      })
      .catch(err => {
        setError(err.message)
      })
  }

  const handleSaveAll = () => {
    const carryDataUpdate = carryData.filter(c => c.on_edit)
    authenticatedFetch(`carries/bulk_update`, {
      method: 'POST',
      body: JSON.stringify({
        opportunity_id: deal.id,
        carries: carryDataUpdate
      })
    })
      .then(resp => {
        handleCancel()
      })
      .catch(err => {
        setError(err.message)
      })
  }

  const handleUsers = () => {
    authenticatedFetch(
      `users?search_term=${searchTerm}&statuses[]=approved&slim=true&no_carry_for=${deal?.id}`
    ).then(users => {
      setUsers(users)
    })
  }

  const loadCarryUsers = (opp, clear = null) => {
    setLoading(true)
    setCarryData([])
    setError(null)
    // carryDataValues.current = []
    authenticatedFetch(`carries?opportunity_id=${opp?.id}`)
      .then(carries => {
        setCarryData(carries)
        setLoading(false)
      })
      .catch(err => {
        setError(err.message)
        setLoading(false)
      })
  }

  const formatResult = item => {
    return (
      <>
        <span>{item.name}</span>
        <br />
        <span>{item.username}</span>
      </>
    )
  }

  const handleCancel = () => {
    setError(null)
    loadCarryUsers(deal, true)
    setShowSaveAll(false)
  }

  const cancelEditPMoic = () => {
    setPMoic(deal.potential_moic ? deal.potential_moic : 3)
    setEditPotentialMoic(false)
  }
  const savePMoic = () => {
    DataStore.update('opportunities', deal.id, { potential_moic: pMoic }).then(res => {
      setEditPotentialMoic(false)
    })
  }

  useEffect(() => {
    DataStore.clear()
    DataStore.find('opportunities', id).then(opp => {
      setDeal(opp)
      setCarryTracking(opp.carry_tracking)
      setPMoic(opp?.potential_moic ? opp.potential_moic : 3)
      setCurrentMoic(opp.valuation.current_multiple)
      loadCarryUsers(opp)
    })
  }, [id])

  useEffect(() => {
    // hide/show save all button
    if (carryData.filter(c => c.on_edit === true).length > 0) {
      setShowSaveAll(true)
    } else {
      setShowSaveAll(false)
    }
  }, [carryData, carryTracking])

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
      handleUsers()
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    if (carryTracking && carryTracking.moic !== currentMoic) {
      // update carry tracking from db
      authenticatedFetch(`opportunities/${id}/carry_tracking?moic=${currentMoic}`, {
        method: 'get'
      }).then(ct => {
        setCarryTracking(ct)
      })
    }
  }, [currentMoic])

  return (
    <Container>
      <Header>
        <Title>Carry for {deal?.name}</Title>
        <Button label='Add Client' action={() => handleAddClient()} />
      </Header>

      {deal && (
        <>
          <DealInfo>
            <ValuationInfo>
              <InfoHeader>Valuation</InfoHeader>
              <div>
                <InfoLabel>Initial Value:</InfoLabel>
                {dollarFormat(deal.valuation.total_invested)}
              </div>
              <div>
                <InfoLabel>Current Value:</InfoLabel>
                {dollarFormat(deal.valuation.current_value)}
              </div>
              <div>
                <InfoLabel>MOIC:</InfoLabel>
                {Number(deal.valuation.current_multiple).toFixed(2)}x
              </div>
            </ValuationInfo>
            {carryTracking?.overall && (
              <CarryInfo>
                <InfoHeader>Carry</InfoHeader>
                <div>
                  <InfoLabel>Total:</InfoLabel>
                  {dollarFormat(carryTracking.overall.carry)}
                </div>
              </CarryInfo>
            )}
          </DealInfo>
          <DealInfo>
            <InfoLabel>Projected MOIC:</InfoLabel>
            {editPotentialMoic ? (
              <>
                <Input
                  className='inline-input'
                  inputId='potential_moic'
                  minValue={0}
                  maxValue={1000}
                  type='number'
                  value={pMoic}
                  onChange={value => setPMoic(value)}
                />
                <span>x</span>
                <StyledLink onClick={() => cancelEditPMoic()}>cancel</StyledLink>
                <StyledLink onClick={() => savePMoic()}>save</StyledLink>
              </>
            ) : (
              <InlineDiv onClick={() => setEditPotentialMoic(true)}>
                {Number(deal.potential_moic).toFixed(2)}x
              </InlineDiv>
            )}
          </DealInfo>
        </>
      )}
      <ErrorContainer>
        {error && <Text color={style.vars.colors.warning}>{error}</Text>}
      </ErrorContainer>
      {deal && (
        <Filters>
          <MoicToggle
            moic={deal.valuation.current_multiple}
            potentialMoic={deal.potential_moic}
            currentMoic={currentMoic}
            setCurrentMoic={setCurrentMoic}
          ></MoicToggle>
        </Filters>
      )}
      {carryTracking && carryData && carryData.length > 0 && !loading ? (
        <CarryTable carries={carryData} setCarries={setCarryData} carryTracking={carryTracking} />
      ) : (
        <span>No Carry Data Available</span>
      )}

      {showSaveAll && (
        <ButtonsContainer>
          <Button className='cancel' label='Cancel' action={handleCancel} marginRight subtle />
          <Button label='Save all' action={() => handleSaveAll()} />
        </ButtonsContainer>
      )}

      <Modal minHeight='20vh' show={clientModal} close={() => setClientModal(false)}>
        <CarryUsersModal>
          <h2>Add Carry for user</h2>
          <ReactSearchAutocomplete
            items={users}
            onSearch={value => setSearchTerm(value)}
            autoFocus
            formatResult={formatResult}
            onSelect={item => setSelectedUser(item.id)}
          />
          <CarryInputs>
            <Input
              minValue={0}
              maxValue={100}
              label='Carry'
              type='number'
              value={carry}
              onChange={value => setCarry(value)}
            />
          </CarryInputs>
          <CarryActions>
            <ErrorContainer>
              {error && <Text color={style.vars.colors.warning}>{error}</Text>}
            </ErrorContainer>
            <Button
              className='cancel'
              marginRight='10px'
              label='Cancel'
              action={() => setClientModal(false)}
            />
            <Button label='Save' action={() => handleCreateCarry()} />
          </CarryActions>
        </CarryUsersModal>
      </Modal>
    </Container>
  )
}

export default OpportunityCarries
