import React, { useState, useEffect } from 'react'
import FeatherIcon from 'feather-icons-react'
import { useMediaQuery } from 'react-responsive' // Detect the device screen size.
import { style } from '../../themes/styles'
import emptyImage from 'assets/images/image.svg'

/// /// STYLES >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
const TableContainer = style.StyledComponent.div`
  margin-bottom: 5px;
  ${props => props.minHeight && `min-height: ${props.minHeight};`}
  ${props => props.maxHeight && `max-height: ${props.maxHeight};`}
  overflow: auto;
  
  ::-webkit-scrollbar {
    display: none;
  }
  
.active {
    color: ${style.color.white};
    
    :after {
      background-color: ${style.color.championLightGrey};
      border-radius: 5px;
      color: ${style.color.championGreen};
      font-weight: 600;
      content: 'Live';
      margin-left: -45px;
      padding: 6px;
      text-align: left;
    }
  }
  
  .archived {
    color: ${style.color.white};
    
    :after {
      background-color: ${style.color.championLightGrey};
      border-radius: 5px;
      color: ${style.color.championDarkGrey};
      font-weight: 600;
      content: 'Archived';
      margin-left: -65px;
      padding: 6px;
      text-align: left;
    }
  }
  .hidden-text {
    color: ${style.color.white};
    font-size: 1px;
  }
  .conditional-fixed-picture {
    margin-left: 10px;
  }
  
  .picture-with-dark-background {
    border-radius: 6px;
    padding: 4px;
  }
  .inactive {
    color: ${style.color.white};
    
    :after {
      background-color: ${style.color.clearOrange};
      border-radius: 5px;
      color: ${style.color.warning.main};
      font-weight: 600;
      content: 'Draft';
      margin-left: -65px;
      padding: 6px;
      text-align: left;
    }
  }
  
  .finalized {
    color: ${style.color.white};
    
    :after {
      background-color: ${style.color.championLightGrey};
      border-radius: 5px;
      color: ${style.color.championGreen};
      font-weight: 600;
      content: 'Finalized';
      margin-left: -65px;
      padding: 6px;
      text-align: left;
    }
  }
  
  .register-table {
    p {
      font-size: clamp(0.75rem, 0.6429rem + 0.5357vw, 1.125rem);
    }
    
    thead tr {
      pointer-events:none
    }
    
    tr:hover {
      background-color: ${style.color.championBlue};
    }
    
    td { 
      color: ${style.color.white};
    }

    th {
      color: ${style.color.black};
    }
  }
`

const ResTable = style.StyledComponent.table`
  border: 1px solid ${style.color.championWhiteGrey};
  border-radius: 5px;
  border-spacing: 0;
  cursor: pointer;
  margin: 2px;
  width: 99%;
`

const ResHeader = style.StyledComponent.thead`
  background-color: ${style.color.championWhiteGrey};
  cellspacing: 0px;
  height: 30px;
  position: sticky;
  top: 0;
`

const ResHeaderColumn = style.StyledComponent.th`
  font-size: clamp(0.75rem, 0.6429rem + 0.5357vw, 1.125rem);
  font-weight: 600;
  line-break: auto;
  text-align: left;
  padding: 5px;
`

const ResBody = style.StyledComponent.tbody`
  cellspacing: 0px;
  overflow-y: scroll;
`

const ResBodyColumn = style.StyledComponent.td`
  font-size: clamp(0.75rem, 0.6786rem + 0.3571vw, 1rem);
  font-weight: 100;
  line-break: auto;
  text-align: left;
  padding: 5px;
  
  h4 {
    color: red !important;
  }
  
  &.border-top {
    border-top: 2px solid ${style.color.championWhiteGrey};
  }

  &.semi-bold {
     font-weight: 600;
  }

  &.bold {
    font-weight: 700;
  }
  
`

const ResRow = style.StyledComponent.tr`
  padding: 5px;
  
  &.fixedRow {
    background-color: ${style.color.championWhiteGrey};
  }
  
  &:hover{
    background-color: ${style.color.championLightGrey};
  }
`
const PaginationContainer = style.StyledComponent.div`
  display: grid;
  grid-gap: space-between;
  grid-template-columns: auto auto;
  grid-template-rows: 30px 30px;
  margin-bottom: 5px;
  
  p {
    font-size: clamp(0.75rem, 0.6786rem + 0.3571vw, 1rem);
    
    &.right-align {
      text-align: right;
      margin-right: 10px;
    }
  }
`

const PaginationButton = style.StyledComponent.button`
  background-color: ${style.color.championBlue};
  border: 1px solid ${style.color.white};
  border-radius: 5px;
  color: ${style.color.white};
  font-size: clamp(0.75rem, 0.6786rem + 0.3571vw, 1rem);
  font-weight: 100;
  height: 30px;
  line-break: auto;
  width: 100px;
  
  :disabled {
    background-color: ${style.color.championLightGrey};
    color: ${style.color.championBlue};
  }
`

/// /// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
/**
 *
 * @param {object} headingItems
 * headingItems = [
 *  { title: 'Name', key: '{item.id}' },
 * ]
 * @param {object} dataItems
 * dataItems = [
 *  { id: 1,
 *    name: 'John',
 *    age: '30',
 *    city: 'New York',
 *    item<string>: 'item name',
 *    item_logo: {route to picture},
 *    clickEvent: () => openModal(item)}, // Only if is needed.
 *    object: <Object className='class-for-sorting' />
 *    hiddenItem: true / false // Only if is needed.
 *    className: {string} // Optional custom class for row design.
 * ]
 * @param {object} footerItems Used to display totals or any fixed value at the end of the table.
 * @param {string} minHeight The minimum height of the table component.
 * @param {string} maxHeight The maximum height of the table component.
 * @param {boolean} noHeader // Set true to hide the header.
 * @param {boolean} noSorting // Set true to prevent the table sorting
 * @param {boolean} pagination // Used to display the pagination of the items.
 * @param {number} pageItems // Number of items to display per page.
 * @param {number} customItemsLength // Number of total items [not necessarily the displayed items on the table].
 * @param {function} pageBack // Custom function lo page back table items.
 * @param {function} pageNext // Custom function lo page next data table items.
 * @param {object} searchMetadata // Params for get items query, Ex. : { order_by: 'created_at' }.
 * @param {function} setSearchMetadata // Function to update search metadata.
 * @param {Array} hiddenItemsByText // Text inputs to hide by key.. EX: ['origin', 'notified'] should hide the key value in column.
 * @param {Array} hiddenItemsConditional // Conditional for sorting.. EX: ['_logo'] column key to be sorted.
 * @param {string} defaultSortingColumn // Default table sorting by key.
 * @param {string} defaultSortingDirection // Default table sorting direction. 'down' || 'up'
 * @param {boolean} forceSorting //  Used on edit rows, to fix the sorting. [We need to re-sort to keep the positions.]
 */

const invalidRowKeys = new Set([
  'id',
  'hiddenItem',
  'className',
  'className',
  'cellClassName',
  'deal_background',
  'clickEvent',
  'childRows'
])

function RenderCol({
  row,
  hiddenItemsByText,
  hiddenItemsConditional,
  className,
  child,
  tableKey,
  showChildren,
  setShowChildren
}) {
  if (row.childRows && (tableKey === 'stage' || tableKey === 'date')) return null

  if (
    typeof row[tableKey] === 'string' &&
    (row[tableKey].includes('/media') ||
      row[tableKey].includes('opportunity/logo/') ||
      row[tableKey].includes('data:image'))
  )
    return (
      <img
        className={
          tableKey === `${hiddenItemsByText}${hiddenItemsConditional}`
            ? 'conditional-fixed-picture'
            : className === 'admin-deals' && 'picture-with-dark-background'
        }
        src={row[tableKey]}
        alt={row.tableKey}
        width='35'
        onError={e => (e.target.src = emptyImage)}
        style={{
          background: row.deal_background || style.color.championLightGrey
        }}
      />
    )

  if (row.childRows && tableKey === 'deal')
    return (
      <div
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={e => {
          e.stopPropagation()
          setShowChildren(!showChildren)
        }}
      >
        <FeatherIcon icon={showChildren ? 'chevron-down' : 'chevron-right'} size={16} />{' '}
        {row[tableKey]}
      </div>
    )

  return (
    <>
      {child && tableKey === 'deal' ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '16px', height: '16px' }} />
          {row[tableKey]}
        </div>
      ) : (
        row[tableKey]
      )}
    </>
  )
}

function RenderRow({ row, hiddenItemsByText, hiddenItemsConditional, className, child }) {
  const [showChildren, setShowChildren] = useState(false)

  return (
    <>
      <ResRow {...(child ? { style: { background: '#e7e7e7' } } : {})}>
        {Object.keys(row).map(key => {
          if (invalidRowKeys.has(key)) return null

          return (
            <ResBodyColumn
              key={key}
              {...(typeof row[key] === 'string' ? { className: row.className } : {})}
              {...(typeof row[key] === 'string' ? { onClick: row.clickEvent } : {})}
              className={
                hiddenItemsByText?.includes(key)
                  ? 'hidden-text'
                  : row.cellClassName
                  ? row.cellClassName
                  : row[key]
              }
            >
              <RenderCol
                tableKey={key}
                row={row}
                hiddenItemsByText={hiddenItemsByText}
                hiddenItemsConditional={hiddenItemsConditional}
                className={className}
                child={child}
                showChildren={showChildren}
                setShowChildren={setShowChildren}
              />
            </ResBodyColumn>
          )
        })}
      </ResRow>
      {showChildren &&
        row?.childRows?.map(childRow => (
          <RenderRow
            key={childRow.id + Math.random().toString()}
            row={childRow}
            hiddenItemsByText={hiddenItemsByText}
            hiddenItemsConditional={hiddenItemsConditional}
            className={className}
            child={true}
          />
        ))}
    </>
  )
}

export default function ResponsiveTable({
  id,
  className,
  headingItems,
  dataItems,
  footerItems,
  minHeight = '10px',
  maxHeight,
  noHeader = false,
  pagination = true,
  pageItems = 10,
  noSorting = false,
  customItemsLength,
  pageBack,
  pageNext,
  searchMetadata,
  setSearchMetadata,
  hiddenItemsByText,
  hiddenItemsConditional,
  defaultSortingColumn,
  defaultSortingDirection = 'up',
  forceSorting = false
}) {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 769 })
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(!isDesktopOrLaptop ? 5 : pageItems)
  const [orderKey, setOrderKey] = useState('')
  const [orderDirection, setOrderDirection] = useState(defaultSortingDirection)
  const totalItems = customItemsLength || dataItems?.length

  const handlePagination = rows => {
    setPage(1)
    setRowsPerPage(rows)
    if (searchMetadata) {
      setSearchMetadata({
        limit: rows,
        offset: 0
      })
      // pageNext()
    }
    // load page one of the list using the callback
    // if (pageNext) {
    //  pageNext(1);
    // }
    localStorage.setItem(`${className}_page_items`, JSON.stringify(rows))
  }

  const sortObjectByAttribute = (list, key) => {
    function compare(a, b) {
      a = a[key]
      b = b[key]
      const type =
        typeof a === 'string' && typeof b === 'string'
          ? 'string'
          : typeof b === 'number'
          ? 'number'
          : 'object'
      let result
      if (type === 'string' && a.includes('$') && b.includes('$')) {
        a = a.replace('$', '').replaceAll(',', '')
        b = b.replace('$', '').replaceAll(',', '')
        result = orderDirection === 'down' ? a - b : b - a
      } else if (type === 'string' && a.includes('%') && b.includes('%')) {
        a = a.replace('%', '')
        b = b.replace('%', '')
        result = orderDirection === 'down' ? a - b : b - a
      } else if (type === 'string') {
        if (a.includes('/')) {
          a = new Date(a)
          b = new Date(b)
          result = orderDirection === 'down' ? a - b : b - a
        } else {
          result = orderDirection === 'down' ? a?.localeCompare(b) : b?.localeCompare(a)
        }
      } else if (type === 'number') {
        a = parseFloat(a)
        b = parseFloat(b)
        result = orderDirection === 'down' ? a - b : b - a
      } else {
        a = a?.props?.className
        b = b?.props?.className
        if (typeof a === 'number') {
          // Added to sort by number type classes.
          a = parseFloat(a)
          b = parseFloat(b)
          result = orderDirection === 'down' ? a - b : b - a
        } else {
          result = orderDirection === 'down' ? a?.localeCompare(b) : b?.localeCompare(a)
        }
      }
      return result
    }
    setOrderDirection(orderDirection === 'up' ? 'down' : 'up')
    return list?.sort(compare)
  }

  const handleOrder = orderKeyValue => {
    if (!noSorting && orderKeyValue) {
      if (!pagination) {
        const sortingKey =
          hiddenItemsConditional &&
          hiddenItemsConditional.includes(`_${orderKeyValue.split('_')[1]}`)
            ? orderKeyValue.substring(0, orderKeyValue.indexOf('_'))
            : orderKeyValue

        sortObjectByAttribute(dataItems, sortingKey)
      } else {
        if (searchMetadata) {
          setSearchMetadata({
            current_page: 1,
            order_by: orderKeyValue,
            order: orderDirection === 'down' ? 'desc' : 'asc',
            limit: rowsPerPage,
            offset: 0
          })
          // pageNext()
        }
        // list reloaded by call to setSearchMetadata
        setPage(1)
        setRowsPerPage(rowsPerPage)
        setOrderDirection(orderDirection === 'up' ? 'down' : 'up')
      }
      setOrderKey(orderKeyValue)
    }
  }
  const handlePageBack = () => {
    if (pageBack) {
      pageBack(page - 1)
    }
    setPage(page - 1)
  }

  const handlePageNext = () => {
    if (pageNext) {
      pageNext(page + 1)
    }
    setPage(page + 1)
  }

  useEffect(() => {
    if (searchMetadata) {
      setPage(searchMetadata.current_page)
      setRowsPerPage(searchMetadata.limit)
    }

    if ((!orderKey && defaultSortingColumn) || (forceSorting && orderKey)) {
      handleOrder(orderKey || defaultSortingColumn)
    }
  }, [page, dataItems])

  return (
    <TableContainer minHeight={minHeight} maxHeight={maxHeight}>
      <ResTable id={id} className={className}>
        {!noHeader && (
          <ResHeader>
            <ResRow>
              {headingItems.map((item, index) => (
                <ResHeaderColumn key={index} onClick={() => handleOrder(item.key)}>
                  <div className='flex items-center'>
                    {item.title}&nbsp;
                    {orderKey === item.key && (
                      <FeatherIcon icon={'arrow-' + orderDirection} size='10' />
                    )}
                  </div>
                </ResHeaderColumn>
              ))}
            </ResRow>
          </ResHeader>
        )}
        <ResBody>
          {dataItems
            ?.filter(item => !item.hiddenItem)
            .map(item => {
              return (
                <RenderRow
                  key={item.id + Math.random().toString()}
                  row={item}
                  hiddenItemsByText={hiddenItemsByText}
                  hiddenItemsConditional={hiddenItemsConditional}
                  className={className}
                />
              )
            })}
          {footerItems?.map(fixedItem => {
            return (
              <ResRow key={Math.random().toString()} className={'fixedRow'}>
                {Object.keys(fixedItem).map(key => {
                  return (
                    <ResBodyColumn key={Math.random().toString()} className={''}>
                      {fixedItem[key]}
                    </ResBodyColumn>
                  )
                })}
              </ResRow>
            )
          })}
        </ResBody>
      </ResTable>
      {pagination && (
        <PaginationContainer>
          {rowsPerPage < totalItems && (
            <>
              <div>
                <PaginationButton onClick={() => handlePageBack()} disabled={page === 1}>
                  Previous
                </PaginationButton>
                <PaginationButton
                  onClick={() => handlePageNext()}
                  disabled={page === Math.ceil(totalItems / rowsPerPage)}
                >
                  Next
                </PaginationButton>
              </div>
              <p className='right-align'>
                Page: <b>{page}</b> of {Math.ceil(totalItems / rowsPerPage)}
              </p>
            </>
          )}
          <p>
            Rows per page:
            <select value={rowsPerPage} onChange={e => handlePagination(+e.target.value)}>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
          </p>
        </PaginationContainer>
      )}
    </TableContainer>
  )
}
