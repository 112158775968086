import React, { useState } from 'react'
import { style } from '../../themes/styles'
import Header from '../../components/core/Header'
import Footer from '../../components/core/Footer'
import ScrollContainer from '../../components/core/ScrollContainer'
import { SectionContent } from '../../constants/StyleComponents'
import TeamSection from '../../components/marketingSections/MVP/Team'
import ChampTeam from '../../components/marketingSections/Champion/ChampTeam'
import { currentPlatform } from '../../services/authentication'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const StyledSectionContent = style.StyledComponent(SectionContent)`
  margin-bottom: ${style.vars.titlePadding * 2}px;
  max-width: 100vw;

  @media ${style.screen.sm} {
    margin: 0px;
    margin-bottom: ${style.vars.titlePadding * 2}px;
  }
`
// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export default function ATeam({ history }) {
  const [isScrolled, setIsScrolled] = useState(false)
  return (
    <ScrollContainer hasHeader isScrolled={isScrolled} setIsScrolled={setIsScrolled}>
      <Header isScrolled={isScrolled} />
      <StyledSectionContent>
        {currentPlatform() === 'champion' ? (
          <ChampTeam activeSection={true} isPage={true} />
        ) : (
          <TeamSection isPage={true} />
        )}
      </StyledSectionContent>
      <Footer />
    </ScrollContainer>
  )
}
