import React from 'react'
import { Link, ExternalLink } from '../../constants/StyleComponents'
import styled from 'styled-components'
import { style } from '../../themes/styles'
import history from '../../constants/History'
import Button from './Button'
import { currentPlatform, isLoggedIn } from '../../services/authentication'
import whiteMvp from 'assets/images/mvp-white.png'

/// /// STYLES >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const ButtonsContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50% 50%;
  height: 100%;
`

const ExternalItem = styled(ExternalLink)`
  color: ${style.vars.colors.white};
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
`

const FooterContainer = styled.div`
  align-items: center;
  background: ${style.vars.colors.championBlue};
  bottom: 0;
  color: ${style.vars.colors.buttonText};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 60px;
  position: absolute;
  text-align: center;
  width: 100%;
`

const FooterLogo = styled.img`
  width: 100px;
  margin: auto;
`

const Item = styled(Link)`
  color: ${style.vars.colors.white};
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
`

const LinksContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100%;
  @media screen and (max-width: 760px) {
    grid-template-columns: 100%;
    grid-template-rows: 50% 50%;
  }
`

const StyledButton = styled(Button)`
  background-color: ${style.vars.colors.white};
  color: ${style.vars.colors.championBlue};
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
  height: 22px;
  margin: auto;
  max-width: 100px;
`

/// /// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export default function Footer({ children, className }) {
  const loggedIn = isLoggedIn()
  const callLink = 'https://calendly.com/mvp-investor-relations/introduction-to-mvp-ventures'
  const emailLink = 'mailto:founders@mvp-vc.com'

  return (
    <>
      <FooterContainer>
        <>
          <Item to={loggedIn && '/opportunities'}>
            <FooterLogo src={whiteMvp} />
          </Item>
        </>
        {loggedIn ? (
          <ExternalItem target='_blank' href='https://mvp-vc.com/team'>
            Our Team
          </ExternalItem>
        ) : (
          <></>
        )}
        <>
          {loggedIn ? (
            <ButtonsContainer>
              <StyledButton label='Meet Us' action={() => window.open(callLink, '_blank')} />
              <StyledButton action={() => window.open(emailLink)} label='Email Us' />
            </ButtonsContainer>
          ) : (
            <ButtonsContainer>
              <StyledButton label='Log in' action={() => history.push('/?login=true')} />
              <StyledButton label='Register' action={() => history.push('/register')} />
            </ButtonsContainer>
          )}
        </>
      </FooterContainer>
    </>
  )
}
