import React, { useState, useEffect } from 'react'
import { style } from 'themes/styles'
import { SectionContent, PageTitle, H2 } from '../../constants/StyleComponents'
import Header from '../../components/core/Header'
import Footer from '../../components/core/Footer'
import ScrollContainer from '../../components/core/ScrollContainer'
import OpportunityCard from '../../components/OpportunityCard'
import { OPPORTUNITY_TYPES, OPPORTUNITY_STATUSES } from '../../services/opportunities'
import { DataStore } from '../../services/DataStore'
import {
  authenticatedFetch,
  currentPlatform,
  PLATFORMS,
  setUser,
  userInfoSync
} from '../../services/authentication'
import Modal from 'components/core/Modal'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const OpportunitiesContainer = style.StyledComponent(ScrollContainer)`
  padding-top: 96px;
  @media ${style.screen.sm} {
    padding-top: 64px;
  }
`

const Content = style.StyledComponent(SectionContent)`
  margin-bottom: 30%;
`

const Pitch = style.StyledComponent(PageTitle)`
  font-size: clamp(1.25rem, 1.0357rem + 1.0714vw, 2rem);
  margin: 16px 0;
  @media ${style.screen.sm} {
    margin: 10px;
  }
`

const Options = style.StyledComponent.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: ${style.grid}px;
  width: 100%;
  margin-bottom: 40px;
  @media ${style.screen.sm} {
    grid-template-columns: 100%;
    margin-bottom: 16px;
  }
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export default function Opportunities({ history }) {
  const [isScrolled, setIsScrolled] = useState(false)
  const [vintages, setVintages] = useState([])
  const [dealShares, setDealShares] = useState([])
  const [collections, setCollections] = useState([])
  const [deals, setDeals] = useState([])
  const [dealCount, setDealCount] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const platform = currentPlatform()

  useEffect(() => {
    const query = {}
    query.status = OPPORTUNITY_STATUSES.ACTIVE
    query.open_only = true
    if (platform === PLATFORMS.CHAMPION) {
      query.visible_on_champion = true
    } else {
      query.visible_on_mvp = true
    }

    DataStore.findAll('opportunities', query).then(opps => {
      setVintages(opps.filter(option => option.opportunity_type === OPPORTUNITY_TYPES.VINTAGE))
      setDealShares(opps.filter(option => option.opportunity_type === OPPORTUNITY_TYPES.DEAL_SHARE))
      setCollections(
        opps.filter(option => option.opportunity_type === OPPORTUNITY_TYPES.COLLECTION)
      )
    })
  }, [setVintages, setDealShares])

  useEffect(() => {
    setDeals(vintages.concat(collections).concat(dealShares))
  }, [vintages, collections, dealShares, platform])

  useEffect(() => {
    if (deals.length === 1) {
      setDealCount(1)
    } else if (deals.length === 2 || deals.length === 4) {
      setDealCount(2)
    } else {
      setDealCount(3)
    }
  }, [deals])

  useEffect(() => {
    const userInfo = userInfoSync(true)
    if (userInfo?.modal_viewed === true) return

    authenticatedFetch(`users/${userInfo.id}`)
      .then(user => {
        DataStore.update('users', userInfo.id, { ...user, modal_viewed: true }).then(() => {
          setShowModal(true)
          setUser(JSON.stringify({ ...userInfo, modal_viewed: true }))
        })
      })
      .catch(error => console.log(error))
  }, [])

  return (
    <>
      {showModal && (
        <Modal show={showModal} close={() => setShowModal(false)} noPadding>
          <div style={{ textAlign: 'center', marginTop: '3rem' }}>
            <H2>Welcome to MVP, take a quick platform tour here</H2>
          </div>
          <div
            style={{
              position: 'relative',
              paddingBottom: '59.4059405940594%',
              height: 0,
              marginTop: '3rem'
            }}
          >
            <iframe
              src='https://www.loom.com/embed/d63f9fd5d5bb4b15bfbd0b2608915f21?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true'
              webkitallowfullscreen
              mozallowfullscreen
              allowFullScreen
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            ></iframe>
          </div>
        </Modal>
      )}

      <OpportunitiesContainer isScrolled={isScrolled} setIsScrolled={setIsScrolled}>
        <Header isScrolled={isScrolled} />

        <Content>
          <Pitch>Our current investment opportunities</Pitch>
          <Options cardRow={dealCount}>
            {deals.map(opp => (
              <OpportunityCard
                cardRow={dealCount}
                key={opp.id}
                opportunity={opp}
                history={history}
                fromOpportunities={true}
              />
            ))}
          </Options>
        </Content>
        <Footer />
      </OpportunitiesContainer>
    </>
  )
}
