import React, { useEffect, useMemo, useState } from 'react'
import { authenticatedFetch, isSuperAdmin } from '../services/authentication'
import { Opportunity, getEntities } from '../services/DataStore'
import { isBrowser } from 'react-device-detect'
import { TD } from './core/Table'
import ResponsiveTable from './core/ResponsiveTable'
import CollapsibleContainer from './core/CollapsibleContainer'
import Dropdown from './core/fields/Dropdown'
import { dollarFormat } from '../constants/DollarsFormat'
import { multipleFormat } from '../constants/MultipleFormat'
import { SectionContent } from '../constants/StyleComponents'
import { style, quantityComparisonColor } from '../themes/styles'
import MoicToggle from './MoicToggle'
import styled from 'styled-components'
import aggregateCarries from 'helpers/portfolio/aggregateCarries'
import aggregateDealShareReports from 'helpers/portfolio/aggregateDealShareReports'
import mutateRow from 'helpers/portfolio/mutateRow'
import ReactTooltip from 'react-tooltip'
import logo from '../assets/images/mvp-white.png'
import CollapsibleContainerNew from './core/CollapsibleContainerNew'
import TableNew from './core/TableNew'
import styles from 'assets/scss/table.module.scss'

// STYLE ##########################################################

const Header = style.StyledComponent.div`
  ${isBrowser && style.vars.flexRow};
  width: 100%;
`

const ChartContainer = style.StyledComponent.div`
  margin-bottom: 20px;
  margin-left: auto;
`

const Chart = style.StyledComponent.div`
  height: 200px;
  width: 300px;
  overflow: visible;

  svg {
    overflow: visible;
  }
`

const Filters = style.StyledComponent.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: -35px;
  width: 100%;
`
const FundTable = style.StyledComponent.table`
  margin-bottom: 20px;
`

// Component ##########################################################

const StyledSectionContent = style.StyledComponent(SectionContent)`
  margin-bottom: ${style.vars.titlePadding}px;
  max-width: 1400px !important;
  table {
    
    a {
      color: ${style.color.championDarkGrey};
      font-size: 16px;
      text-decoration: none;
      
      &:hover {
        color: ${style.color.championLightBlue};
      }
    }
  }
`

const Disclaimer = styled.div`
  text-align: right;
  font-style: italic;
  font-size: small;
`
const RightOpts = styled.div`
  margin: -20px 20px 10px 0;
  text-align: right;
  display: block;
`
const SupInfo = styled.sup`
  font-size: 0.8rem;
  display: inline-block;
  width: 0;
`

const DetailsTable = ({ deal, statusColor, totals }) => {
  const {
    investment_in_deal,
    total_invested_to_date,
    distributed,
    current_value,
    multiple_on_investment,
    irr,
    label,
    created_at
  } = deal || {}

  return (
    <>
      <FundTable className='px-16 w-full mr-3'>
        {label && (
          <thead>
            <h5 className='m-0 font-bold text-[#2C5476] mb-3'>{label}</h5>
          </thead>
        )}

        <tbody>
          {investment_in_deal && (
            <tr>
              <TD bold>Capital Committed</TD>
              <TD right>{dollarFormat(investment_in_deal)}</TD>
            </tr>
          )}
          {created_at && (
            <>
              <tr>
                <TD bold>Commitment Date</TD>
                <TD right>{new Date(created_at).toLocaleDateString('en-US')}</TD>
              </tr>
              <tr style={{ height: '100%' }} />
            </>
          )}
          {distributed && (
            <tr>
              <TD bold>Distributed</TD>
              <TD right>{dollarFormat(distributed)}</TD>
            </tr>
          )}
          {totals && (
            <>
              <tr>
                <TD bold>Commitment</TD>
                <TD right>{dollarFormat(totals?.invested)}</TD>
              </tr>
              <tr>
                <TD bold>Invested to Date</TD>
                <TD right>{dollarFormat(totals?.investedToDate)}</TD>
              </tr>
              <tr>
                <TD bold>Invested in Co-Invests</TD>
                <TD right>{dollarFormat(totals.investedInDealshares)}</TD>
              </tr>
              <tr>
                <TD bold>Invested Value</TD>
                <TD right>{dollarFormat(totals.investedValue)}</TD>
              </tr>
              <tr>
                <TD bold>Multiple on Invested Capital</TD>
                <TD right>{multipleFormat(totals.investedValue / totals.investedToDate)}</TD>
              </tr>
            </>
          )}
          {total_invested_to_date && (
            <tr>
              <TD bold>Deployed to Date</TD>
              <TD right>{dollarFormat(total_invested_to_date)}</TD>
            </tr>
          )}
          {current_value && (
            <tr>
              <TD bold>Current Value</TD>
              <TD right style={{ color: statusColor }}>
                {dollarFormat(current_value)}
              </TD>
            </tr>
          )}

          {multiple_on_investment && (
            <tr>
              <TD bold>MOIC</TD>
              <TD right style={{ color: statusColor }}>
                {multipleFormat(multiple_on_investment)}
              </TD>
            </tr>
          )}
          {irr && (
            <tr>
              <TD bold>IRR</TD>
              <TD right style={{ color: statusColor }}>
                {irr > 100000 ? '100000%+' : `${irr}%`}
              </TD>
            </tr>
          )}
        </tbody>
      </FundTable>
      {/* {deal && (
          <Label>
            Total Capital Invested: {dollarFormat(vintage_invested_to_date)}. Percent of Initial
            Vintage Invested:{' '}
            {Math.round((100 * vintage_invested_to_date) / vintage_anticipated_amount)}%
          </Label>
        )} */}
    </>
  )
}

/**
 * Page for showing an organization's portfolio
 *
 * @param {number} organizationId - The ID of the organization to show the portfolio for
 * @constructor
 */
export default function Portfolio({ organizationId, userId = null, user }) {
  const [report, setReport] = useState(null)
  const [totals, setTotals] = useState(null)
  const [dealshareReports, setDealshareReports] = useState(null)
  const [vintageAndCollectionReports, setVintageAndCollectionReports] = useState(null)
  const [entity, setEntity] = useState({})
  const [entities, setEntities] = useState(null)
  const [carries, setCarries] = useState([])
  const [currentMoic, setCurrentMoic] = useState(0)
  const [userUpdates, setUserUpdates] = useState([])

  useEffect(() => {
    // retrieve ok if superAdmin - otherwise it will retrieve for current user only
    const query = isSuperAdmin() && userId && userId !== null ? `?user_id=${userId}` : ''
    authenticatedFetch(`carries/portfolio${query}`, { method: 'get' }).then(res => {
      setCarries(aggregateCarries(res))
    })
  }, [userId])

  useEffect(() => {
    if (organizationId) {
      getEntities(organizationId).then(ent => {
        let entForDd = ent.map(e => {
          return { value: e.id, label: e.name }
        })
        entForDd = [{ value: null, label: 'All Entities' }, ...entForDd]
        setEntities(entForDd)
        setEntity(entForDd[0])

        authenticatedFetch(`notifications?read=false`, { read: false }).then(notifications => {
          let current
          const notificationsValues = []
          notifications.forEach(item => {
            if (!current || item.subject_id !== current.subject_id) {
              notificationsValues.push(item)
            }
            current = item
          })
          setUserUpdates(notificationsValues)
        })
      })
    }
  }, [organizationId])

  useEffect(() => {
    if (organizationId && Object.keys(entity).length > 0) {
      let url = `organizations/${organizationId}/portfolio`
      if (entity && entity.value) {
        url = url + `?entity_id=${entity.value}`
      }
      authenticatedFetch(url, { method: 'get' }).then(response => {
        setReport(response)
        const vintages = response
          .filter(r => r.opportunity_type === Opportunity.TYPE_VINTAGE)
          .map(v => {
            if (!v.dealshares) return v
            return {
              ...v,
              dealshares: aggregateDealShareReports(v.dealshares)
            }
          })
        const dealshares = response.filter(r => r.opportunity_type === Opportunity.TYPE_DEALSHARE)
        const collections = response
          .filter(r => r.opportunity_type === Opportunity.TYPE_COLLECTION)
          .map(v => {
            if (!v.dealshares) return v
            return {
              ...v,
              dealshares: aggregateDealShareReports(v.dealshares)
            }
          })

        setTotals({
          invested: response.reduce((a, v) => {
            return a + parseFloat(v.investment_in_deal)
          }, 0),
          investedInDealshares: dealshares.reduce(
            (a, ds) => a + parseFloat(ds.investment_in_deal),
            0
          ),
          investedToDate: response.reduce((a, v) => {
            return a + parseFloat(v.total_invested_to_date || v.investment_in_deal)
          }, 0),
          value: response.reduce((a, o) => {
            // add uninvested ("reserved") to total value
            return a + parseFloat(o.current_value)
          }, 0),
          investedValue: response.reduce((a, o) => {
            return a + parseFloat(o.current_value)
          }, 0)
        })

        setDealshareReports(aggregateDealShareReports(dealshares))
        setVintageAndCollectionReports([...vintages, ...collections])
      })
    }
  }, [organizationId, entity])

  /* handle changes between moic & potential for carries */
  useEffect(() => {
    if (carries) {
      // update carry tracking from db
      const query = isSuperAdmin() && userId && userId !== null ? [`user_id=${userId}`] : []
      if (currentMoic === 1) {
        // use potential
        query.push('use_potential=true')
      }
      authenticatedFetch(`carries/portfolio?${query.join('&')}`, { method: 'get' }).then(res => {
        setCarries(aggregateCarries(res))
      })
    }
  }, [currentMoic])

  const carryTotal = useMemo(
    () => carries.reduce((prev, current) => prev + parseFloat(current.carry.total), 0),
    [carries]
  )

  return (
    <>
      <div className={styles.heroImage}>
        <div className='max-w-[1400px] mx-auto px-[40px] flex flex-col lg:flex-row items-center justify-between pt-[20px] pb-[80px] md:pt-[50px] lg:py-[90px]'>
          <div className='flex flex-col lg:flex-row text-center lg:text-left items-center gap-12 pb-8 lg:pb-[90px]'>
            <img src={logo} className='max-h-[95px] max-w-[196px] object-contain 2xl:ml-[-5rem]' />
            <div className='flex flex-col gap-3'>
              <h3 className='text-white montserrat text-4xl font-[600]'>{user.name}</h3>
              <p className='text-white text-[20px] m-0'>{user.username}</p>
            </div>
          </div>

          {entities?.length > 2 && (
            <div className='w-32 mt-auto'>
              <select
                name='All entities'
                className='w-full p-2.5 mb-2 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600'
                onChange={e => setEntity(JSON.parse(e.target.value))}
              >
                {entities.map((entity, i) => (
                  <option key={i} value={JSON.stringify(entity)}>
                    {entity.label}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      </div>

      {report?.length === 0 && carries.length === 0 && (
        <>
          <p>You have no finalized commitments at this time</p>
        </>
      )}
      {/* <>
        {entities?.length > 2 && (
          <div className=''>
            <select
              name='All entities'
              className='w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600'
              onChange={e => setEntity(JSON.parse(e.target.value))}
            >
              {entities.map((entity, i) => (
                <option key={i} value={JSON.stringify(entity)}>
                  {entity.label}
                </option>
              ))}
            </select>
          </div>
        )}
        </> */}
      <div className='mt-[-5rem] flex flex-col w-full z-10 max-w-[1400px] mx-auto px-[40px] gap-8 pb-8'>
        {report?.length > 0 && (
          <>
            {dealshareReports?.length > 0 && (
              <CollapsibleContainerNew
                title='Co-Invests'
                subTitleContent={
                  isBrowser ? (
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <b>Initial Invested Capital</b>
                          </td>
                          <td>{dollarFormat(totals.investedInDealshares)}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Current Value</b>
                          </td>
                          <td>
                            {dollarFormat(
                              dealshareReports.reduce(
                                (accumulator, item) => accumulator + parseFloat(item.current_value),
                                0
                              )
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : null
                }
              >
                {/* <TableNew
                    cols={[
                      'Deal',
                      'Stage',
                      'Date',
                      'Sector',
                      'Investment',
                      'MOIC',
                      'Current Value'
                    ]}
                    data={dealshareReports}
                  /> */}
                <ResponsiveTable
                  key={Math.random().toString()}
                  className='portfolio-co-invests'
                  headingItems={[
                    { title: '', key: 'notification' },
                    { title: 'Deal', key: 'deal' },
                    ...(isBrowser
                      ? [
                          { title: 'Stage', key: 'stage' },
                          { title: 'Date', key: 'date' },
                          { title: 'Sector', key: 'sector' },
                          { title: 'Investment', key: 'investment' },
                          { title: 'MOIC', key: 'moic' },
                          { title: 'Current Value', key: 'current_value' }
                        ]
                      : [{ title: 'Current Value', key: 'current_value' }])
                  ]}
                  pagination={false}
                  dataItems={dealshareReports.map(r => mutateRow(r, userUpdates))}
                />
              </CollapsibleContainerNew>
            )}
            {vintageAndCollectionReports?.map((v, i) => {
              const vintageTotalStatusColor = quantityComparisonColor(
                Math.round(Number(v.fund_current_value)),
                Math.round(Number(v.fund_invested_to_date))
              )

              return (
                <div key={i}>
                  <CollapsibleContainerNew
                    title={v.name}
                    subTitleContent={
                      isBrowser ? (
                        <div className='flex flex-col md:flex-row md:justify-between'>
                          <DetailsTable
                            deal={{
                              label: 'Fund Details',
                              total_invested_to_date: v.fund_invested_to_date || 0,
                              current_value: parseFloat(v.fund_current_value) || '0',
                              multiple_on_investment:
                                parseFloat(v.fund_current_value) /
                                  parseFloat(v.fund_invested_to_date) || '0'
                            }}
                            statusColor={vintageTotalStatusColor}
                          />
                          <DetailsTable
                            deal={{
                              label: 'Commitment Details',
                              investment_in_deal: v.final_amount_total || '0',
                              created_at: v.created_at
                            }}
                            statusColor={vintageTotalStatusColor}
                          />
                        </div>
                      ) : null
                    }
                    key={v.commitment_id}
                  >
                    <ResponsiveTable
                      key={Math.random().toString()}
                      className='deals-table'
                      headingItems={[
                        { title: '', key: 'notification' },
                        { title: 'Deal', key: 'deal' },
                        ...(isBrowser
                          ? [
                              { title: 'Stage', key: 'stage' },
                              { title: 'Date', key: 'date' },
                              { title: 'Sector', key: 'sector' },
                              { title: 'MOIC', key: 'moic' }
                            ]
                          : [])
                      ]}
                      pagination={false}
                      dataItems={v.dealshares.map(r => mutateRow(r, userUpdates))}
                    />
                  </CollapsibleContainerNew>
                </div>
              )
            })}
          </>
        )}
        {carries?.length > 0 && (
          <div key={Math.random().toString()}>
            <CollapsibleContainerNew
              title='Carry'
              subTitleContent={
                isBrowser ? (
                  <table className='table-auto'>
                    <tbody>
                      <tr>
                        <td>Total</td>
                        <td>
                          <b>{dollarFormat(carryTotal)}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : null
              }
            >
              <RightOpts>
                <MoicToggle
                  moic={0}
                  potentialMoic={1}
                  currentMoic={currentMoic}
                  setCurrentMoic={setCurrentMoic}
                ></MoicToggle>
                <Disclaimer>* Carry amounts are estimates and subject to change</Disclaimer>
              </RightOpts>
              <ResponsiveTable
                key={Math.random().toString()}
                headingItems={[
                  { title: '', key: 'notification' },
                  { title: 'Deal', key: 'deal' },
                  { title: 'Stage', key: 'stage' },
                  { title: 'Date', key: 'date' },
                  { title: 'Sector', key: 'sector' },
                  { title: 'MOIC', key: 'moic' },
                  { title: 'Total', key: 'total' }
                ]}
                pagination={false}
                dataItems={carries.map(r => mutateRow(r, userUpdates))}
                footerItems={[
                  {
                    notification: null,
                    deal: null,
                    stage: null,
                    date: null,
                    sector: null,
                    moic: <b>Total</b>,
                    total: dollarFormat(carryTotal)
                  }
                ]}
              />
            </CollapsibleContainerNew>
          </div>
        )}
      </div>
    </>
  )
}
