import React, { useState, useEffect, Fragment } from 'react'
import { useParams } from 'react-router'
import { Document, Page, pdfjs } from 'react-pdf'
import styled from 'styled-components'
import Header from '../../components/core/Header'
import ScrollContainer from '../../components/core/ScrollContainer'
import sv from '../../themes/styles'
import LoadingSpinner from '../../components/core/LoadingSpinner'
import { authenticatedFetch } from '../../services/authentication'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const DocHolder = styled(Document)`
  height: 100%;
  margin-top: 100px;
  @media print {
    display: none;
  }
  canvas {
    width: 100% !important;
    height: auto !important;
  }
`

const CenteredScrollContainer = styled(ScrollContainer)`
  width: 100%;
  max-width: 1000px;
`

const Title = styled.div`
  ${sv.h1};
  text-align: center;
`

const CenteredLoader = styled(LoadingSpinner)`
  width: 20%;
  margin: auto;
  margin-top: 200px;
`
const EmptyContainer = styled('div')`
  margin-top: 200px;
`
const ErrorTitle = styled(Title)`
  margin-top: 200px;
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export default function Doc({ history }) {
  const { opportunityId } = useParams()
  const { linkId } = useParams()
  const [url, setUrl] = useState(null)
  const [numPages, setNumPages] = useState(null)
  const [isScrolled, setIsScrolled] = useState(false)
  const [linkOpportunityId, setLinkOpportunityId] = useState(null)
  const [error, setError] = useState(false)

  useEffect(() => {
    async function getDocument() {
      try {
        const res = await authenticatedFetch(`/links/${linkId}`, { method: 'GET' })
        setUrl(res.document.url)
        setLinkOpportunityId(res.opportunity_id)
      } catch (e) {
        setError(true)
      }
    }
    getDocument()
  }, [linkId])

  const headerAction = {
    label: 'back',
    icon: 'arrow-left',
    action: () => history.push(`/opportunities/${opportunityId}`)
  }

  const errorMessage = 'Document not found'

  return (
    <CenteredScrollContainer isScrolled={isScrolled} setIsScrolled={setIsScrolled}>
      <Header isScrolled={isScrolled} leftAction={headerAction} />
      {error ? (
        <ErrorTitle>{errorMessage}</ErrorTitle>
      ) : (
        <>
          {url && linkOpportunityId && parseInt(opportunityId) === parseInt(linkOpportunityId) ? (
            <DocHolder
              file={url}
              loading=<CenteredLoader />
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              onContextMenu={e => e.preventDefault()}
              error=<Title>{errorMessage}</Title>
              noData=<Title>{errorMessage}</Title>
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Fragment key={index + 1}>
                  <Page key={index + 1} pageNumber={index + 1} loading='' />
                  <hr />
                </Fragment>
              ))}
            </DocHolder>
          ) : (
            <EmptyContainer>
              <CenteredLoader />
            </EmptyContainer>
          )}
        </>
      )}
    </CenteredScrollContainer>
  )
}
