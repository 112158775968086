import React from 'react'
import styled from 'styled-components'
import sv from '../themes/styles'
import { dollarFormat } from '../constants/DollarsFormat'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
const CurrencyLabels = styled.div`
  color: ${sv.colors.subtleText};
`
const BoldText = styled.span`
  font-weight: bold;
`
const TotalsDiv = styled.div`
  margin-top: 4px;
`
// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
export default function EntityCommitmentFeeAndCapital({
  totalInvestment = 0,
  feeAmount = 0,
  totalCapital = 0,
  initialCapital = 0,
  multipleEntities = false,
  isDealshare = false
}) {
  return (
    <TotalsDiv>
      {(multipleEntities || isDealshare) && (
        <>
          <CurrencyLabels>{`Total Investment: ${dollarFormat(totalInvestment)}`}</CurrencyLabels>
        </>
      )}
      {isDealshare && (
        <>
          <CurrencyLabels>{`Lifetime Management Fee: ${dollarFormat(feeAmount)}`}</CurrencyLabels>
          <CurrencyLabels>
            {'Total Capital: '}
            <BoldText>{`${dollarFormat(totalCapital)}`}</BoldText>
          </CurrencyLabels>
          <CurrencyLabels>
            {'Initial Capital Call: '}
            <BoldText>{`${dollarFormat(initialCapital)}`}</BoldText>
          </CurrencyLabels>
        </>
      )}
    </TotalsDiv>
  )
}
