import React, { useState } from 'react'
import FeatherIcon from 'feather-icons-react'
import { style } from '../../themes/styles'

// STYLE ##########################################################

const Container = style.StyledComponent.div`
  background-color: #F8F8F8;
  border-radius: 16px;
  margin-bottom: 10px;
`

const ContainerContent = style.StyledComponent.div`
  padding: 10px;
`

const Header = style.StyledComponent.div`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto;
  padding: 10px;
  
  h2 {
    margin: 0;
  }
`

const HeaderTitle = style.StyledComponent.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const SubtitleContent = style.StyledComponent.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 10px;
  width: 60%;
`

const ToggleButton = style.StyledComponent.button`
  background-color: transparent;
  border: none;
  color: #888;
  cursor: pointer;
  margin-left: auto;
  outline: none;
  transition: color 0.3s;
`

// Component ##########################################################

/**
 * Collapsible container to hide / display content.
 *
 * @param {string} title - The title for the container
 * @constructor
 */
const CollapsibleContainerNew = ({ title, subTitleContent, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className='rounded-xl border border-t-0 bg-white montserrat shadow-sm'>
      <div
        className='shadow-md rounded-lg border flex items-center justify-between cursor-pointer'
        onClick={toggleOpen}
      >
        <h2 className='ml-8 text-[#10273A]'>{title}</h2>
        <FeatherIcon
          icon='chevron-down'
          size={22}
          className={`mr-10 duration-300 ${isOpen ? 'rotate-180' : ''}`}
        />
      </div>
      {subTitleContent && (
        <div className='my-6 ml-8 grid gap-5 mt-10 w-[70%]'>{subTitleContent}</div>
      )}
      <div
        className={`${isOpen ? 'max-h-[2000px]' : 'max-h-0'} overflow-hidden duration-300 px-2.5`}
      >
        {children}
      </div>
    </div>
  )
}

export default CollapsibleContainerNew
