import React, { useEffect, useState } from 'react'
import { useImagePreloader } from 'hooks'
import { isDesktop } from 'react-device-detect' // Detect the decive type.
import { useMediaQuery } from 'react-responsive' // Detect the device screen size.
import styled from 'styled-components'
import SectionContainer from './ChampComponents/SectionContainer'
import SectionHeader from './ChampComponents/SectionHeader'
import { CTA, StyledH3 } from './ChampComponents/styled'
import CustomContainer from '../../core/CustomContainer'
import { theTeam } from './teamBios.js'
import TeamBio from './TeamBio.js'
import { style } from 'themes/styles'
const preloadSrcList = []
for (const person of theTeam) {
  const asset = isDesktop
    ? require(`assets/images/team/${person.photo}`).default
    : require(`assets/images/team_mobile/${person.photo}`).default
  preloadSrcList.push(asset)
}

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
const CustomCTA = styled(CTA)`
  width: 50vw;

  @media ${style.screen.sm} {
    font-size: 22px;
    padding-top: 1vh;
    width: 80vw;
  }
`

const ImgContainer = styled.div`
  background: ${props => (props.background ? 'url(' + props.background + ') center center / cover' : style.color.white)};
  display: inline-block;
  height: 100vh;
  position: absolute;

  &.active {
    opacity: 1;
    transition: opacity 1.5s ease-in;
    width: 30%;
  }

  &.latest {
    opacity: 0;
    transition: opacity 1.5s ease-out;
    width: 30%;
  }
`

const PersonCell = styled.div`
  display: flex;
  margin: 10px 0;
  width: 21vw;
  margin-left: 50px;
  @media screen and (max-width: 1045px) {
    width: 22vw;
    margin-left: 30px;
  }
  @media screen and (max-width: 928px) {
    width: 24vw;
    margin-left: 20px;
  }

  @media ${style.screen.sm} {
    background: url(${props => props.background});
    background-size: cover;
    background-position: center;
    margin: 0;
    width: 100%;
  }
`
const PersonBar = styled.div`
  background: ${style.color.championGrey};
  width: 3vw;
  height: 4px;
  margin-top: 0.7em;
`

const PersonBarFill = styled.div`
  background: ${style.color.championBlue};
  width: 0;
  height: 4px;
  margin-top: 0.5em;
  &.activePerson {
    margin-top: 0;
    width: 100%;
    transition: width 4s linear;
  }
`

const PersonTexts = styled.div`
  margin-left: 1vw;
  font-size: 1.2em;
  text-transform: uppercase;
  cursor: pointer;
  & span {
    font-size: 0.8em;
    text-transform: none;
  }
  @media screen and (max-width: 1440px) {
    font-size: 1em;
  }
  @media ${style.screen.sm} {
    background: ${style.color.championBlue};
    color: ${style.color.white};
    font-size: 10px;
    height: 20px;
    margin-left: 5%;
    margin-top: 15vh;
    padding-top: 5px;
    text-align: center;
    width: 90%;
  }
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// Component descriptions / data.
const componentData = {
  title: ' We Champion Teamwork',
  subtitle: isDesktop
    ? `We've worked together for years investing with the best VCs and generating valuation growth.`
    : `We are experts at co-investing alongside top-tier VCs in their`,
  subtitle_2: isDesktop
    ? ` Get to know the team behind Champion and MVP Ventures...`
    : ` best companies.`
}

export default function ChampTeam({ activeSection, goTop, isPage = false }) {
  const [latestImage, setLatestImage] = useState(theTeam[0].photo)
  const [currentImage, setCurrentImage] = useState(theTeam[0].photo)
  const [teamMember, setTeamMember] = useState(0)
  const [displayBio, setDisplayBio] = useState(false)
  const [slideOn, setSlideOn] = useState(false)
  const [time, setTime] = React.useState(0)
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 769 })

  const imageSwiper = () => {
    let auxTeamMember
    if (teamMember + 1 === theTeam.length) {
      auxTeamMember = 0
    } else {
      auxTeamMember = teamMember + 1
    }
    setLatestImage(currentImage)
    setCurrentImage(theTeam[auxTeamMember].photo)
    setTeamMember(auxTeamMember)
  }

  useEffect(() => {
    if (activeSection && slideOn && !displayBio) imageSwiper()
  }, [activeSection, slideOn, !displayBio, time])

  useEffect(() => {
    if (activeSection) setSlideOn(true)
  }, [activeSection, setSlideOn])

  useEffect(() => {
    let timer
    if (activeSection && slideOn && !displayBio) {
      timer = window.setInterval(() => {
        setTime(prevTime => prevTime + 1)
      }, 4000)
    } else {
      window.clearInterval(timer)
    }
    return () => {
      if (timer) window.clearInterval(timer)
    }
  }, [activeSection, slideOn, displayBio])

  const hoverPersonHandler = index => {
    setSlideOn(false)
    setCurrentImage(theTeam[index].photo)
    setTeamMember(index)
  }

  const outPersonHandler = () => {
    setSlideOn(true)
  }

  const handlePersonClick = index => {
    // stop image rolling then next lines
    setSlideOn(false)
    !isPage && document.getElementById('fp-nav').classList.add('hideMenu')
    setCurrentImage(theTeam[index].photo)
    setDisplayBio(true)
    setTeamMember(index)
  }

  const handleCLoseModal = () => {
    setSlideOn(true)
    setDisplayBio(false)
    !isPage && document.getElementById('fp-nav').classList.remove('hideMenu')
  }

  const TeamMembersPictures = () => {
    useImagePreloader(preloadSrcList, activeSection)
    const isTabletOrMobile = useMediaQuery({ maxWidth: 768 })
    if (!isTabletOrMobile) {
      const latestAssetName = latestImage.split('.')[0]
      const assetName = currentImage.split('.')[0]
      return preloadSrcList.map(asset => {
        const randomKey = (Math.random() + 1).toString(36).substring(7)
        if (asset.includes(assetName)) {
          return (
            <ImgContainer
              className={'active'}
              background={asset || ''}
              key={`img_container_${randomKey}`}
            />
          )
        } else if (asset.includes(latestAssetName)) {
          return (
            <ImgContainer
              className={'latest'}
              background={asset || ''}
              key={`img_container_${randomKey}`}
            />
          )
        }
        return <ImgContainer background={asset || ''} key={`img_container_${randomKey}`} />
      })
    }
  }

  return (
    <SectionContainer
      customLeftPadding={'0vw'}
      customMargin={'auto 0'}
      background={style.color.white}
    >
      {!isPage && (
        <SectionHeader
          circleColor={style.color.secondary.light}
          goTop={goTop}
          investTextColor={style.color.secondary.darkBlue}
        />
      )}
      <CustomContainer
        className={isPage ? 'fullpage-team' : ''}
        columns={isDesktopOrLaptop && '30% 70%'}
        noOverflow
      >
        <div>{TeamMembersPictures()}</div>
        <CustomContainer
          className={isPage ? 'fullpage-team-info' : ''}
          padding={isDesktopOrLaptop ? '10vh' : '14vh 10vw'}
          rows={isDesktopOrLaptop ? '5vh 26vh auto' : '5vh 12% auto'}
        >
          <StyledH3 color={style.color.secondary.darkBlue}>
            <span>———</span> {componentData.title}
          </StyledH3>
          <CustomCTA>
            {componentData.subtitle}
            <span>{componentData.subtitle_2}</span>
          </CustomCTA>
          <CustomContainer
            columns={isDesktopOrLaptop ? '1fr 1fr' : '1fr 1fr 1fr'}
            height={isDesktopOrLaptop ? '25vh' : '54vh'}
            margin={!isDesktopOrLaptop && '13vh -10vw'}
            width={isDesktopOrLaptop && '45vw'}
            noOverflow
          >
            {theTeam.map((member, index) => (
              <PersonCell
                background={!isDesktopOrLaptop && preloadSrcList[index]}
                key={index}
                onClick={() => handlePersonClick(index)}
                onMouseLeave={() => outPersonHandler(index)}
              >
                {isDesktopOrLaptop && (
                  <PersonBar>
                    <PersonBarFill className={teamMember === index ? 'activePerson' : ''} />
                  </PersonBar>
                )}
                <PersonTexts
                  onMouseEnter={() => hoverPersonHandler(index)}
                  onClick={() => handlePersonClick(index)}
                >
                  {member.name}
                  {isDesktopOrLaptop && (
                    <>
                      <br />
                      <span>{member.position}</span>
                    </>
                  )}
                </PersonTexts>
              </PersonCell>
            ))}
          </CustomContainer>
        </CustomContainer>
      </CustomContainer>
      {displayBio && (
        <TeamBio isPage={isPage} teamMember={theTeam[teamMember]} hide={() => handleCLoseModal()} />
      )}
    </SectionContainer>
  )
}
