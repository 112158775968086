import React from 'react'
import { style } from 'themes/styles'
import { currentPlatform, PLATFORMS } from 'services/authentication'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
const GridContainer = style.StyledComponent.div`
  align-items: ${props => (props.alignItems ? props.alignItems : 'auto')};
  column-gap: ${props => (props.columnGap ? props.columnGap : 'auto')};
  background-color: ${props => (props.background ? props.background : '')};
  display: inline-grid;
  grid-template-columns: ${props => (props.columns ? props.columns : 'auto')};
  grid-template-rows: ${props => (props.rows ? props.rows : 'auto')};
  height: ${props => (props.height ? props.height : '100vh')};
  margin: ${props => (props.margin ? props.margin : '0')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '')};
  overflow:  ${props => (props.noOverflow ? props.noOverflow : 'auto')};
  padding: ${props => (props.padding ? props.padding : '0')};
  position: ${props => (props.position ? props.position : 'relative')};
  width: ${props => (props.width ? props.width : '100vw')};

  // Custom fund investment edit row.
  &.fund-investment-edit-row {
    border: 1.14035px solid ${style.color.championWhiteGrey};
    box-sizing: border-box;
    border-radius: 4.5614px;
    height: 65px;
    padding: 10px 10px 0px 15px;
    margin-bottom: 4px;

    svg {
      color: ${style.color.black};
      cursor: pointer;
    }

    h4 {
      color: ${style.color.championBlue};
      font-size: 16px;
      font-weight: 400;
    }

    p {
      color: ${style.color.championDarkGrey};
      font-family: 'Work Sans', sans-serif;
      font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
      font-style: normal;
      font-weight: 400;
    }
  }

  // Custom class for fullpage team.
  &.fullpage-team {
    height: 87vh;
    margin-left: -5vw;
  }

  &.fullpage-team-info {
    height: 80vh;
    width: 48vw;
  }
  
  // Login screen.
  &.login-container {
    align-items: center;
    grid-template-rows: 11vh 5vh 30vh;
    height: 64vh;
    margin: 18vh 10vw;
    
    form {
      height: 32vh;
      
      @media ${style.screen.gt_sm} {
        margin: 0px auto;
        width: 35vw;
      }
    }
    
    h4 {
      font-size: 18px;
      font-weight: 100;
      
      @media ${style.screen.gt_sm} {
        text-align: center;
      }
    }
    
    img {
      height: 60px;
      margin: 0 auto;
      
      @media ${style.screen.gt_sm} {
        height: 10vh;
      }
    }
    
    @media ${style.screen.gt_sm} {
      grid-template-rows: 11vh 5vh 35vh;
    }
  }
  
  // Register screen.
  &.register-container {
    align-items: center;
    grid-template-rows: 11vh 5vh 30vh;
    height: 80vh;
    padding: 18vh 12vw;
    
    .mvplogo {
      margin-bottom: 15px;
    }
    &.sm {
      height: 80px;
    }
    &.dark {
      background-color: ${style.color.championBlue};
      
      a {
        color: ${style.color.championGreen};
        text-decoration: none;
      }
      
      h4 {
        color: ${style.color.championGreen};
      }
      
      span {
        color: ${style.color.white};
      }
    }
    
    form {
      height: 32vh;
      
      .double-group {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 1vw;
        width: 79vw;
        
        @media ${style.screen.gt_sm} {
          width: 34vw;
        }
      }
      
      @media ${style.screen.gt_sm} {
        margin: 0px auto;
        min-width: 35vw;
      }
    }
    
    h4 {
      font-size: 18px;
      font-weight: 100;
      
      @media ${style.screen.gt_sm} {
        text-align: center;
      }
    }
    
    img {
      height: 60px;
      margin: 0 auto;
      
      @media ${style.screen.gt_sm} {
        height: 10vh;
      }
    }
    
    input {
      width: 100%;
    }
    
    .register--champion, .register--mvp {
      margin-top: auto;
    }
    
    @media ${style.screen.gt_sm} {
      grid-template-rows: 11vh 5vh 35vh;
      padding: 18vh 16vw;
    }
  }

  // Custom style for Opportunity 2.0
  &.opportunity-design {
    background-color: ${style.color.mvpGray};
    color: ${style.color.white};
    grid-template-rows: auto auto;
    height: auto;
    padding: 10vh 5vw 0 5vw;

    .materials {
      margin-top: 5px;
      
      &.hidden {
        display: none;
        height: 50px;
      }
      
      .material-item {
        align-items: center;
        color: ${style.color.white};
        font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
        height: 25px;
      }
      
      @media ${style.screen.gt_sm} {
        border: none;
      }
    }
    
    a {
      color: ${style.color.white};
      font-family: 'Work Sans', sans-serif;
      font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
      text-decoration: none;
    }

    &__content {
      border-bottom: 0.5px solid ${style.color.white};
      grid-template-rows: 60px 250px 50px auto;
      max-height: 100vh;
      overflow-y: scroll;
      
      ::-webkit-scrollbar {
        display: none;
      }
                        
      .title_container {
        display: grid;
        
        .title {
          border-bottom: 0.5px solid ${style.color.white};
          
          h4 {
            color: ${style.color.white};
            font-size: clamp(1.25rem, 1.0357rem + 1.0714vw, 2rem);
            margin: 0 auto;
            font-weight: 500;
          }
        }
      }
      
      .details_container {
        display: grid;
        grid-template-rows: 32vh auto;
        
        &.quicklook-hidden {
          grid-template-rows: auto auto;
        }
        
        .quicklook {
          border-bottom: 0.5px solid ${style.color.white};
          display: grid;
          
          b {
            font-weight: 650;
          }
          
          p {
            color: ${style.color.white};
            height: 35px;
            margin-bottom: 0px;
            margin-top: 10px;
          }
          
          ul {
            margin-top: 0px;
            overflow-y: scroll;
            padding-left: 0;
            scrollbar-width: none; 
          
            ::-webkit-scrollbar {
              display: none;
            }
            
            @media ${style.screen.gt_sm} {
              width: 100%;
            }
            
            li {
              font-family: 'Work Sans', sans-serif;
              font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
              margin-bottom: 12px;
            }
          }
          
          .quicklook-button {
            cursor: pointer;
            left: 8px;
            position: relative;
            top: 4px;
          }
          
          &.hidden {
            grid-template-rows: 50px 0px;
          }
          
          @media ${style.screen.gt_sm} {
            border-bottom: none;
            grid-template-rows: 52px auto 52px auto 52px auto 52px;
            height: fit-content;
            margin-top: 4%;
          }
        }
        
        .thesis {
          display: grid;
          padding-top: 2vh;
          scrollbar-width: none; 
          
          ::-webkit-scrollbar {
            display: none;
          }
          
          iframe {
            width: 100%;
          }
          
          a {
            color: ${style.color.white};
            text-decoration: none;
          }
          
          b, p {
            font-family: 'Work Sans', sans-serif;
            font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
          }
          
          p {
            color: ${style.color.white};
          }
          
          @media ${style.screen.gt_sm} {
            overflow-y: scroll;
            height: fit-content;
            max-height: 64vh;
            padding: 2vh 2vw 2vh 2vw;
          }
        }
        
        @media ${style.screen.gt_sm} {
          grid-template-columns: 35% 65%;
          margin-top: 2%;
        };
      }
      
      @media ${style.screen.gt_sm} {
        border-bottom: none;
        border-right: 0.5px solid ${style.color.white};
        max-height: 80vh;
        max-width: 100%;
      }
    }
    
    &__actions {
      display: grid;
      align-items: center;
      grid-template-rows: auto 60px 60px 60px 200px auto;
      height: auto;
      max-width: 90vw;
      overflow-y: hidden;
      padding: 2vh 0;
      
      .commitment-actions {
        display: grid;
        grid-template-columns: 100% 2%;
        grid-gap: 4%;
        margin-top: 0px;
        
        button {
          width: 100%;
        }
        
        @media ${style.screen.gt_sm} {
          grid-template-columns: auto;
          margin-bottom: 30px;
          margin-top: 10px;
        }
      }
      
      .commitment-btn {
        background-color: ${style.color.championGreen};
        font-size: 18px;
        font-weight: 600;
        
        &--inactive {
          background-color: ${style.color.championDarkGrey};
          font-size: 20px;
        }
      }
      
      .commitment-share-container {
        .commitment-share {
          background-color: ${style.color.championGreen};
          width: 100%;
          
          @media ${style.screen.gt_sm} {
             width: 15%;
          }
        }
        
        label {
          @media ${style.screen.gt_sm} {
            padding-top: 8px;
          }
        }
        
        #share-btn {
          cursor: pointer;
          margin-top: 5px;
          margin-left: 10px;
        }
        
        @media ${style.screen.gt_sm} {
          align-items: center;
          display: grid;
          grid-template-columns: 60% 40%;
        }
      }

      .informative {
        .info-action-button {
          cursor: pointer;
          position: absolute;
        }
        
        p, h4 {
          color: ${style.color.white};
          margin: 0px;
        }
        
        h4 {
          font-size: 20px;
          letter-spacing: 3px;
        }
      }
      
      @media ${style.screen.gt_sm} {
        grid-template-rows: 80px auto auto auto;
        margin-top: calc(90px - 4vh);
        max-height: 50vh;
        max-width: 23vw;
        padding: 4vh 2vw;
      }
    }
    
     @media ${style.screen.gt_sm} {
      grid-template-columns: 75% 25%;
      padding: 94px 5% 0 5%;
    }
  }
  
  // Custom class for /opportunities/:id
  &.white-cross {
    color: ${style.color.white};
    font-family: 'Inter', sans-serif;
    font-size: 28px;
    font-weight: bold;
    text-align: center;

    span {
      align-self: center;
    }
  }
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// Grid reference: https://css-tricks.com/snippets/css/complete-guide-grid/
/**
 * @param {string} alignItems - Items custom alignment.
 * @param {string} background - The background of the container [Ex. style.color.championBlue]
 * @param {string} className - The className of the container [Ex. 'section']
 * @param {string} columns - The number of columns in the grid. [Ex. '1fr 1fr 1fr']
 * @param {string} columnGap - The space between columns [Ex. '1vw']
 * @param {string} height - The height of the grid. [Ex. '100vh']
 * @param {string} margin - The margin of the grid.
 * @param {string} maxWidth - The maxWidth of the grid. [Ex. '50%']
 * @param {boolean} noOverflow - Deactivate the default overflow value.
 * @param {string} padding - The padding of the grid.
 * @param {string} rows - The rows of the grid. [Ex. 'auto auto auto']
 * @param {string} width - The width of the grid. [Ex. '100vw']
 * @param {element} children - The children element of the container.
 * @returns {JSX.Element}
 * @constructor
 */
export default function CustomContainer({
  alignItems,
  background,
  className,
  columns,
  columnGap,
  height,
  margin,
  maxWidth,
  noOverflow = false,
  padding,
  position,
  rows,
  width,
  children
}) {
  return (
    <GridContainer
      alignItems={alignItems}
      background={background}
      className={className}
      columns={columns}
      columnGap={columnGap}
      height={height}
      margin={margin}
      maxWidth={maxWidth}
      noOverflow={noOverflow}
      padding={padding}
      position={position}
      rows={rows}
      width={width}
    >
      {children}
    </GridContainer>
  )
}
