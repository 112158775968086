import React, { useState } from 'react'
import Header from '../../components/core/Header'
import Footer from '../../components/core/Footer'
import ScrollContainer from '../../components/core/ScrollContainer'
import { isLoggedIn } from '../../services/authentication'
import DealsTable from 'components/table/DealsTable'
import styles from 'assets/scss/table.module.scss'

export default function Companies() {
  const [isScrolled, setIsScrolled] = useState(false)

  return (
    <ScrollContainer hasHeader isScrolled={isScrolled} setIsScrolled={setIsScrolled}>
      <Header className={!isLoggedIn() ? 'blue-header' : 'whitex'} isScrolled={isScrolled} />
      <div className='relative pb-20'>
        <div className={styles.heroImage}>
          <div className='max-w-[1253px] mx-auto flex items-center h-full px-4'>
            <h2 className='text-white montserrat text-2xl sm:text-4xl md:text-5xl font-[500] m-0 pt-[20px] pb-[80px] md:pt-[50px] lg:py-[90px]'>
              OUR COMPANIES
            </h2>
          </div>
        </div>
        <div className='w-full max-w-[1253px] mx-auto mt-[-3.5rem] sm:mt-[-4rem] md:mt-[-5rem] relative'>
          <DealsTable />
        </div>
      </div>
      <Footer />
    </ScrollContainer>
  )
}
