import React from 'react'
import styled from 'styled-components'
import sv from '../../themes/styles'
import Button from './Button'
import { H3, Label } from '../../constants/StyleComponents'

// STYLE ##########################################################

const Container = styled.div`
  width: ${props => (props.show ? '100vw' : 0)};
  height: ${props => (props.show ? '100vh' : 0)};
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 1000;
  ${sv.flexCenter};
`

const Shade = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
`

const Content = styled.div`
  background: ${props => (props.noBackground ? 'none' : sv.colors.background)};
  padding: ${props => (props.noPadding ? 0 : sv.appPadding)}px;
  width: ${props => (props.messageWidth ? props.messageWidth : '90vw')};
  max-width: ${props => (props.messageWidth ? props.messageWidth : '300px')};
  max-height: 90vh;
  overflow: visible;
  position: relative;
  z-index: 2;
  ${sv.flexColumn};
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.4);
  ${sv.borderRadius};
  ${sv.box};
`

const CustomLabel = styled(Label)`
  font-size: 16px;
  font-color: #000;
  font-weight: 600;
  margin-top 10px;
  text-transform: none;
`

const Actions = styled.div`
  margin-top: ${sv.grid * 2}px;
  ${sv.flexRow};
`

// COMPONENT ##########################################################

export default function ConfirmDialog({
  show,
  close,
  shadeDoesNotClose,
  action,
  message,
  subMessage,
  messageWidth = null
}) {
  const handleShadeClose = e => {
    e.stopPropagation()
    !shadeDoesNotClose && close()
  }

  const handleConfirm = () => {
    close()
    action()
  }

  return (
    <>
      {show && (
        <Container show={show}>
          <Shade onClick={handleShadeClose} />
          <Content messageWidth={messageWidth}>
            <H3>{message}</H3>
            {subMessage && <CustomLabel>{subMessage}</CustomLabel>}
            <Actions>
              <Button className='cancel' subtle marginRight label='Cancel' action={close} />
              <Button label='Confirm' action={handleConfirm} />
            </Actions>
          </Content>
        </Container>
      )}
    </>
  )
}
