import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { dollarFormat, dollarShortener } from 'constants/DollarsFormat'
import FeatherIcon from 'feather-icons-react'
import { rgba } from 'polished'
import useQuery from 'services/useQuery'
import { style } from 'themes/styles'
import ReactHtmlParser from 'react-html-parser'
import Header from 'components/core/Header'
import CustomFooter from 'components/core/CustomFooter'
import Button from 'components/core/Button'
import Modal from 'components/core/Modal'
import Toast from 'components/core/Toast'
import ScrollContainer from 'components/core/ScrollContainer'
import MediaItem from 'components/MediaItem'
import CommitmentsForm from 'components/CommitmentsForm'
import ShareDealForm from 'components/ShareDealForm'
import DataRoom from 'components/DataRoom'
import CustomContainer from 'components/core/CustomContainer'
import { isDarkColor, dataTypes, dealName } from 'services/utils'
import { DataStore } from '../../services/DataStore'
import {
  isAdmin,
  PLATFORMS,
  userInfo,
  currentPlatform,
  authenticatedFetch
} from 'services/authentication'
import { OPPORTUNITY_TYPES } from 'services/opportunities'
import moment from 'moment'
import ReactImageVideoLightbox from 'react-image-video-lightbox'
import { useMediaQuery } from 'react-responsive'
import { Label } from '../../constants/StyleComponents'
import { formatCloseDate } from '../../services/TimeUtils'
import SavingProgress from '../../components/SavingProgress'
import { Mixpanel } from 'services/mixpanel'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
const Media = style.StyledComponent.div``

const Commitment = style.StyledComponent.div`
  background: ${rgba(style.vars.colors.success, 0.3)};
  border: 1px solid ${style.vars.colors.success};
  padding: ${style.vars.grid}px ${style.vars.grid * 2}px;
  margin-bottom: ${style.vars.grid * 2}px;
  color: ${style.vars.colors.success};
  ${style.vars.borderRadius};
  ${style.vars.flexRow};
`

const CommitmentMessage = style.StyledComponent(Label)`
  margin: 0 ${style.vars.grid}px 0 0;
  color: ${style.vars.colors.text};
  flex: 1;
`

const CustomLabel = style.StyledComponent.label`
  font-family: 'Work Sans', sans-serif;
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
`

const EditCommitment = style.StyledComponent.div`
  cursor: pointer;
`

const DataRoomLink = style.StyledComponent.div`
  ${style.vars.flexRow};
  align-items: flex-start;
  width: auto;
  cursor: pointer;
  padding: ${style.vars.grid}px 0;
`

const DataRoomIcon = style.StyledComponent.div`
  margin-right: ${style.vars.grid * 1.5}px;
  height: 32px;
  flex: 0 0 32px;
  border-radius: 4px;
  ${style.vars.flexCenter};
`

const VideoPlayer = style.StyledComponent.div`
  width: 100vw;
  position: absolute;
  top: 0;
  height: 100vh;
  z-index: 10000;
  
  div:first-child{
    background-color: rgb(42 42 42 / 89%) !important;
  }
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export default function Opportunities({ history }) {
  const [isScrolled, setIsScrolled] = useState(false)
  const [deal, setDeal] = useState(null)
  const [minimum, setMinimum] = useState(null)
  const [maximum, setMaximum] = useState(null)
  const [showCommitmentForm, setShowCommitmentForm] = useState(false)
  const [showShareForm, setShowShareForm] = useState(false)
  const [showDataRoom, setShowDataRoom] = useState(false)
  const [user, setUser] = useState(null)
  const [currentUserCommitment, setCurrentUserCommitment] = useState(null)
  const [userAllowedToInvest, setUserAllowedToInvest] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [dueDiligenceResources, setDueDiligenceResources] = useState([])
  const [companyUpdatesResources, setCompanyUpdatesResources] = useState([])
  const [dataRoomResources, setDataRoomResources] = useState([])
  const [videoResources, setVideoResources] = useState([])
  const [investorLogo, setInvestorLogo] = useState(null)
  const [displayAlert, setDisplayAlert] = useState(false)
  const [videoPopup, setVideoPopup] = useState(false)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 769 })
  const [hiddenQuicklook, setHiddenQuicklook] = useState(true)
  const [hiddenResources, setHiddenResources] = useState(true)
  const [hiddenVideos, setHiddenVideos] = useState(true)
  const [hiddenUpdates, setHiddenUpdates] = useState(true)
  const [goToTop, setGoToTop] = useState(false)
  const platform = currentPlatform()
  const { id } = useParams()
  const query = useQuery()

  const isClosed = useMemo(() => deal && deal.closed, [deal])
  const darkBg = useMemo(() => deal && isDarkColor(deal.background_color || '#FFFFFF'), [deal])
  const fundYear = deal?.fund_years.at(-1)
  const portfolioUrl =
    deal?.opportunity_type === OPPORTUNITY_TYPES.VINTAGE
      ? `https://www.mvp-vc.com/portfolio-categories/${fundYear}`
      : `/companies?year=${fundYear}`

  useEffect(() => {
    if (deal) {
      setDueDiligenceResources(
        deal.links
          .filter(link => {
            // TODO: filter links on server
            return (
              link.group === 'due_diligence' &&
              ((platform === PLATFORMS.CHAMPION && link.visible_on_champion) ||
                (platform === PLATFORMS.MVP && link.visible_on_mvp))
            )
          })
          .sort((a, b) => a.order - b.order)
      )
      setDataRoomResources(
        deal.links
          .filter(link => {
            // TODO: filter links on server
            return (
              link.group === 'data_room' &&
              ((platform === PLATFORMS.CHAMPION && link.visible_on_champion) ||
                (platform === PLATFORMS.MVP && link.visible_on_mvp))
            )
          })
          .sort((a, b) => a.order - b.order)
      )
      setVideoResources(
        deal.links
          .filter(link => {
            return (
              link.group === 'video_link' &&
              ((platform === PLATFORMS.CHAMPION && link.visible_on_champion) ||
                (platform === PLATFORMS.MVP && link.visible_on_mvp))
            )
          })
          .sort((a, b) => a.order - b.order)
          .map(link => {
            return {
              ...link,
              type: 'video',
              title: link.label_html,
              altTag: 'video'
            }
          })
      )
      setCompanyUpdatesResources(
        deal.links
          .filter(link => {
            return (
              link.group === dataTypes.company_updates &&
              ((platform === PLATFORMS.CHAMPION && link.visible_on_champion) ||
                (platform === PLATFORMS.MVP && link.visible_on_mvp))
            )
          })
          .sort((a, b) => a.order - b.order)
          .map(link => {
            return {
              ...link,
              type: 'company_update',
              title: link.label_html
            }
          })
      )
      Mixpanel.track(`Deal View`, {
        opportunity_id: deal.id,
        opportunity_name: deal.name,
        opportunity_status: deal.status,
        opportunity_type: deal.opportunity_type
      })
      setInvestorLogo(
        darkBg && deal.investor?.logo_light?.url
          ? deal.investor?.logo_light?.url
          : deal.investor?.logo?.url
      )
    }
    // TODO: initialization of other values that depend on `deal` should happen here, not randomly everywhere else and sometimes on every digest
  }, [deal, platform])

  useEffect(() => {
    userInfo()
      .then(u => {
        setUser(u)
      })
      .catch(e => {
        console.error('could not get user', e)
      })
  }, [setUser])

  useEffect(() => {
    query.get('showCommitment') && setShowCommitmentForm(true)
    ;(async () => {
      const opp = await authenticatedFetch(`opportunities/${id}`)
      opp && setDeal(opp)
      if (opp?.opportunity_type === 'DealShare') {
        if (platform === PLATFORMS.CHAMPION) {
          setMinimum(opp.minimum_collection_commitment)
          setMaximum(opp.maximum_collection_commitment)
        } else {
          setMinimum(opp.minimum_vintage_commitment)
          setMaximum(opp.maximum_vintage_commitment)
        }
      } else {
        setMinimum(opp.minimum_commitment)
        setMaximum(opp.maximum_commitment)
      }
    })()
  }, [id, setDeal, setShowCommitmentForm])

  useEffect(() => {
    if (!!deal && !!user) {
      let canInvest
      const commitmentIds = user.commitments.map(c => c.opportunity_id)
      if (platform === PLATFORMS.CHAMPION) {
        const collectionIds = deal?.funds?.map(f => f.fund_collection_id)
        canInvest =
          deal?.opportunity_type === OPPORTUNITY_TYPES.COLLECTION ||
          (deal?.opportunity_type === OPPORTUNITY_TYPES.DEAL_SHARE &&
            !deal.collection_investors_only) ||
          (deal?.opportunity_type === OPPORTUNITY_TYPES.DEAL_SHARE &&
            deal.collection_investors_only &&
            commitmentIds.filter(e => collectionIds.includes(e)).length > 0)
      } else {
        canInvest =
          deal?.opportunity_type === OPPORTUNITY_TYPES.VINTAGE ||
          (deal?.opportunity_type === OPPORTUNITY_TYPES.DEAL_SHARE &&
            !deal.vintage_investors_only) ||
          (deal?.opportunity_type === OPPORTUNITY_TYPES.DEAL_SHARE &&
            deal.vintage_investors_only &&
            deal.funds?.filter(e => commitmentIds.includes(e.fund_id)).length > 0)
      }
      setUserAllowedToInvest(canInvest)
      setShowMessage(!canInvest)
    }
  }, [deal, user, history])

  useEffect(() => {
    if (deal && !isAdmin()) {
      DataStore.clear()
      DataStore.findAll('commitments', { opportunity_id: deal.id }).then(commitments => {
        if (commitments && commitments.length > 0) {
          const amount_req = commitments.reduce((a, c) => a + Number(c.amount_requested || 0), 0)
          const amount_inv = commitments.reduce(
            (a, c) => a + Number(c.final_amount_invested || 0),
            0
          )
          const c = { amount_requested: amount_req, final_amount_invested: amount_inv }
          setCurrentUserCommitment(c)
        } else {
          setCurrentUserCommitment(false)
        }
      })
    }
  }, [deal])

  const headerAction = {
    label: 'back',
    icon: 'arrow-left',
    action: () => history.push('/opportunities')
  }

  function CommitmentInfo({ commitment, showForm }) {
    async function openCommitmentForm() {
      if (isAdmin()) {
        setDisplayAlert(true)
        return
      }
      showForm(true)
    }

    if (commitment?.final_amount_invested) {
      return (
        <Commitment>
          You have invested {dollarFormat(commitment.final_amount_invested)} in this deal.
        </Commitment>
      )
    } else if (commitment && user && !user.is_admin) {
      const amount = commitment?.amount_requested || commitment[0]?.amount_requested

      return (
        <Commitment>
          <CommitmentMessage>
            You have committed {dollarFormat(amount)} to this deal.
          </CommitmentMessage>
          <EditCommitment onClick={() => showForm(true)}>Edit</EditCommitment>
        </Commitment>
      )
    } else {
      return (
        <Button
          action={userAllowedToInvest && openCommitmentForm}
          inactive={!userAllowedToInvest || isClosed || user.is_admin}
          label='Invest'
          marginBottom
          className={`commitment-btn${
            !userAllowedToInvest || isClosed || user.is_admin ? '--inactive' : ''
          }`}
        />
      )
    }
  }

  function ActionsGroup({ deal, showShare }) {
    return (
      <div className='commitment-actions'>
        <div>
          <CommitmentInfo
            deal={deal}
            commitment={currentUserCommitment}
            showForm={setShowCommitmentForm}
          />
          {deal.schedule_call_url && (
            <Button
              action={() => window.open(deal.schedule_call_url)}
              className='opportunity-button'
              label='Schedule a Call'
              marginBottom
            />
          )}
        </div>
      </div>
    )
  }

  const updateLocalCommitment = commitment => {
    if (!isAdmin() && !Number.isInteger(commitment)) {
      setCurrentUserCommitment(commitment)
    } else {
      setCurrentUserCommitment(commitment)
    }
  }

  const handleToggleSections = clickedSection => {
    if (clickedSection === 'quicklook') {
      setHiddenQuicklook(!hiddenQuicklook)
      setHiddenResources(true)
      setHiddenVideos(true)
      setHiddenUpdates(true)
    } else if (clickedSection === 'resources') {
      setHiddenQuicklook(true)
      setHiddenResources(!hiddenResources)
      setHiddenVideos(true)
      setHiddenUpdates(true)
    } else if (clickedSection === 'videos') {
      setHiddenQuicklook(true)
      setHiddenResources(true)
      setHiddenVideos(!hiddenVideos)
      setHiddenUpdates(true)
    } else if (clickedSection === 'updates') {
      setHiddenQuicklook(true)
      setHiddenResources(true)
      setHiddenVideos(true)
      setHiddenUpdates(!hiddenUpdates)
    }
  }

  // Extracted to use it in two places.
  const GeneralInfoSection = () => {
    return (
      <CustomContainer className='opportunity-design__actions'>
        <div>
          <ActionsGroup
            commitment={currentUserCommitment}
            showForm={setShowCommitmentForm}
            showShare={setShowShareForm}
            deal={deal}
          />
        </div>
        <div className='informative'>
          {deal.closes && moment(deal.closes).isValid() && (
            <>
              <p>
                <CustomLabel>{isClosed ? 'Initial Deal Date' : 'Closing Date'}: </CustomLabel>
                {isClosed ? (
                  <>
                    {deal?.deal_dates?.map((item, index) => {
                      return (
                        <p key={index}>
                          <span>
                            {item.stage}: <b>{formatCloseDate(item.closes)}</b>
                          </span>
                        </p>
                      )
                    })}
                  </>
                ) : (
                  <b>{formatCloseDate(deal.closes)}</b>
                )}
              </p>
            </>
          )}
        </div>
        {minimum !== null && moment(deal.closes).isAfter() ? (
          <>
            <div className='informative'>
              <>
                <p>
                  <CustomLabel>Requirement: </CustomLabel>
                  {maximum ? (
                    <b>
                      &nbsp;{dollarShortener(minimum)} - {dollarShortener(maximum)}
                    </b>
                  ) : (
                    <b>&nbsp{dollarShortener(minimum)}+</b>
                  )}
                </p>
              </>
            </div>
            <div className='informative'>
              <p onClick={() => setShowShareForm(true)}>
                Share this deal &nbsp;
                <FeatherIcon
                  className='website-icon quicklook-button'
                  icon='share'
                  size={22}
                  color={style.color.championGreen}
                />
              </p>
            </div>
          </>
        ) : (
          <div className='informative'>
            <p onClick={() => setShowShareForm(true)}>
              Share this deal &nbsp;
              <FeatherIcon
                className='website-icon quicklook-button'
                icon='share'
                size={22}
                color={style.color.championGreen}
              />
            </p>
          </div>
        )}
      </CustomContainer>
    )
  }

  return (
    <>
      {displayAlert && (
        <Toast message="Admins can't make commitments" close={() => setDisplayAlert(false)} />
      )}
      {deal && (
        <ScrollContainer
          isScrolled={isScrolled}
          setIsScrolled={setIsScrolled}
          goToTop={goToTop}
          setGoToTop={setGoToTop}
        >
          <Header
            className='black-header'
            isScrolled={isScrolled}
            light={darkBg}
            leftAction={headerAction}
            isTabletOrMobile={isTabletOrMobile}
          />

          <CustomContainer className='opportunity-design'>
            <CustomContainer className='opportunity-design__content'>
              <div className='title_container'>
                <div className='title'>
                  <h4>{isClosed ? deal.name : dealName(deal)}</h4>
                  {isClosed ? (
                    <span>{deal?.investor_list && `Alongside: ${deal.investor_list}`}</span>
                  ) : (
                    <span>{deal?.investor?.name && `Alongside: ${deal.investor.name}`}</span>
                  )}
                </div>
              </div>
              {isTabletOrMobile && <GeneralInfoSection />}

              <div
                className={
                  hiddenQuicklook ? 'details_container quicklook-hidden' : 'details_container'
                }
              >
                <div className={hiddenQuicklook ? 'quicklook hidden' : 'quicklook'}>
                  <p className='flex items-center gap-2'>
                    <CustomLabel>QUICK LOOK </CustomLabel>
                    <FeatherIcon
                      icon={hiddenQuicklook ? 'arrow-up-circle' : 'arrow-down-circle'}
                      size={22}
                      color={style.color.championGreen}
                      onClick={() => handleToggleSections('quicklook')}
                    />
                  </p>
                  <ul>
                    {deal?.key_points
                      ?.sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
                      .map((item, index) => {
                        return <li key={index}>{ReactHtmlParser(item.text_html)}</li>
                      })}
                  </ul>
                  {(dueDiligenceResources?.length > 0 || dataRoomResources?.length > 0) && (
                    <div className='informative'>
                      <p className='flex items-center gap-2'>
                        <CustomLabel>
                          {isClosed ? 'RESOURCES' : 'DUE DILIGENCE MATERIALS'} &nbsp;
                        </CustomLabel>
                        <FeatherIcon
                          icon={hiddenResources ? 'arrow-up-circle' : 'arrow-down-circle'}
                          size={22}
                          color={style.color.championGreen}
                          onClick={() => handleToggleSections('resources')}
                        />
                      </p>
                    </div>
                  )}
                  <div className={hiddenResources ? 'materials hidden' : 'materials'}>
                    <>
                      <Media>
                        {dueDiligenceResources.map((item, index) => {
                          return (
                            <MediaItem
                              className='material-item'
                              key={index}
                              item={item}
                              opportunity={deal}
                              history={history}
                            />
                          )
                        })}
                        {dataRoomResources?.length > 0 && (
                          <DataRoomLink
                            className='material-item'
                            onClick={() => setShowDataRoom(true)}
                          >
                            <DataRoomIcon>
                              <FeatherIcon
                                icon='folder'
                                size={22}
                                color={style.color.championGreen}
                              />
                            </DataRoomIcon>
                            <span>Data Room</span>
                          </DataRoomLink>
                        )}
                      </Media>
                    </>
                  </div>
                  {videoResources?.length > 0 && (
                    <div className='informative'>
                      <p className='flex items-center gap-2'>
                        <CustomLabel>VIDEOS &nbsp;</CustomLabel>
                        <FeatherIcon
                          icon={hiddenVideos ? 'arrow-up-circle' : 'arrow-down-circle'}
                          size={22}
                          color={style.color.championGreen}
                          onClick={() => handleToggleSections('videos')}
                        />
                      </p>
                    </div>
                  )}
                  <div className={hiddenVideos ? 'materials hidden' : 'materials'}>
                    <>
                      <Media>
                        {videoResources.map((item, index) => {
                          return (
                            <MediaItem
                              className='material-item'
                              key={index}
                              item={item}
                              opportunity={deal}
                              history={history}
                              customClickAction={() => setVideoPopup(true)}
                            />
                          )
                        })}
                      </Media>
                    </>
                  </div>
                  {companyUpdatesResources?.length > 0 && isClosed && (
                    <div className='informative'>
                      <p className='flex items-center gap-2'>
                        <CustomLabel>COMPANY UPDATES</CustomLabel>
                        <FeatherIcon
                          icon={hiddenUpdates ? 'arrow-up-circle' : 'arrow-down-circle'}
                          size={22}
                          color={style.color.championGreen}
                          onClick={() => handleToggleSections('updates')}
                        />
                      </p>
                    </div>
                  )}
                  <div className={hiddenUpdates ? 'materials hidden' : 'materials'}>
                    <>
                      <Media>
                        {companyUpdatesResources.map((item, index) => {
                          return (
                            <MediaItem
                              className='material-item'
                              key={index}
                              item={item}
                              opportunity={deal}
                              history={history}
                              onClick={() => window.open(item.url, '_blank')}
                            />
                          )
                        })}
                      </Media>
                    </>
                  </div>
                  {portfolioUrl && deal?.opportunity_type !== OPPORTUNITY_TYPES.DEAL_SHARE && (
                    <div className='informative'>
                      <br />
                      <a
                        href={portfolioUrl}
                        target='_blank'
                        rel='noreferrer'
                        className='flex items-center gap-2'
                      >
                        VIEW PORTFOLIO &nbsp;
                        <FeatherIcon icon='globe' size={22} color={style.color.championGreen} />
                      </a>
                    </div>
                  )}
                  <div className='informative'>
                    <br />
                    {deal.company_url && (
                      <a
                        href={deal.company_url}
                        target='_blank'
                        rel='noreferrer'
                        className='flex items-center gap-2'
                      >
                        OPEN WEBSITE &nbsp;
                        <FeatherIcon icon='globe' size={22} color={style.color.championGreen} />
                      </a>
                    )}
                  </div>
                </div>
                <div className='thesis'>
                  <p>{ReactHtmlParser(deal.long_description_html)}</p>
                </div>
              </div>
            </CustomContainer>
            {!isTabletOrMobile && <GeneralInfoSection />}
          </CustomContainer>
          <CustomFooter setGoToTop={setGoToTop}>Footer</CustomFooter>
          {showCommitmentForm && (
            <Modal
              show={showCommitmentForm}
              noPadding
              shadeDoesNotClose={true}
              close={() => setShowCommitmentForm(false)}
            >
              <CommitmentsForm
                opportunityId={deal.id}
                editedCommitment={currentUserCommitment}
                setCommitment={setCurrentUserCommitment}
                userId={user && user.id}
                afterSuccess={c => updateLocalCommitment(c)}
                close={() => setShowCommitmentForm(false)}
              />
            </Modal>
          )}

          <Modal show={showShareForm} close={() => setShowShareForm(false)}>
            <ShareDealForm deal={deal} close={() => setShowShareForm(false)} />
          </Modal>

          <Modal small show={showDataRoom} close={() => setShowDataRoom(false)}>
            <DataRoom
              items={dataRoomResources}
              close={() => setShowDataRoom(false)}
              deal={deal}
              history={history}
              isClosed={isClosed}
            />
          </Modal>

          {videoPopup && (
            <VideoPlayer className='video-popup'>
              <ReactImageVideoLightbox
                data={videoResources}
                startIndex={0}
                onCloseCallback={() => setVideoPopup(false)}
              />
            </VideoPlayer>
          )}

          <SavingProgress
            saving={showMessage}
            success={showMessage}
            close={() => setShowMessage(false)}
            message={`To make a commitment in this opportunity a ${
              platform === PLATFORMS.CHAMPION ? 'collection' : 'vintage'
            } investment is needed `}
          />
        </ScrollContainer>
      )}
    </>
  )
}
