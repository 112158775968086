import React from 'react'
import FeatherIcon from 'feather-icons-react'
import { style } from '../../themes/styles'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const Container = style.StyledComponent.div``

const Icon = style.StyledComponent(FeatherIcon)`
  animation: rotate 1s linear infinite;
  stroke: ${props => (props.color ? props.color : style.color.black)};
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  color: ${style.color.white};
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

/**
 * @param {string} className - Container custom classname.
 * @param {string} color - Custom loading spinner color.
 * @constructor
 */
export default function Label({ className, color = null }) {
  return (
    <Container className={className}>
      <Icon icon='loader' color={color} />
    </Container>
  )
}
