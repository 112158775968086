import React, { useState, useEffect } from 'react'
import { style } from '../../themes/styles'
import useQuery from '../../services/useQuery'
import Button from '../../components/core/Button'
import MarketingContainer from '../../components/MarketingContainer'
import Input from '../../components/core/fields/Input'
import { logIn, resetPassword } from '../../services/authentication'
import { ComboSectionTitle, Text, ComboText, Link } from '../../constants/StyleComponents'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const Form = style.StyledComponent.form`
  margin-top: ${style.vars.appPadding}px;
`

const Actions = style.StyledComponent.div`
  ${style.vars.flexRow};
  width: 100%;
  margin-top: ${style.vars.padSmall}px;
`

const MainButton = style.StyledComponent(Button)`
  flex: 1;
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export default function ResetPassword({ history, location }) {
  const query = useQuery()

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const [token, setToken] = useState('')

  useEffect(() => {
    if (!query.get('reset_password_token')) {
      setError('A reset token was not provided. Please click the link from your email.')
    } else {
      setToken(decodeURIComponent(query.get('reset_password_token')))
    }
  }, [query])

  const handleSendPasswordReset = e => {
    e.preventDefault()
    setError('')
    setSuccess(false)

    if (password !== confirmPassword) {
      return setError('Please ensure the passwords match.')
    }
    if (password.length < 8) {
      return setError('Your password must be at least 8 characters.')
    }

    resetPassword(token, password)
      .then(response => {
        setSuccess(true)
        // log in the user
        logIn(response.username, password, history)
      })
      .catch(e => {
        console.error('Error', e)
        setError(e.message || 'Unable to update your password')
      })
  }

  const backAction = {
    label: 'cancel',
    icon: 'x',
    action: () => history.push('/')
  }

  return (
    <MarketingContainer action={backAction}>
      <ComboSectionTitle noMargin={true}>Reset your password</ComboSectionTitle>
      <ComboText>Enter a new password. Password must be at least 8 characters.</ComboText>
      <Form onSubmit={handleSendPasswordReset}>
        <Input
          type='password'
          label='password'
          onChange={value => setPassword(value)}
          value={password}
        />
        <Input
          type='password'
          label='confirm'
          onChange={value => setConfirmPassword(value)}
          value={confirmPassword}
        />
        <Button type='submit' label='Submit' />
      </Form>

      {success && (
        <React.Fragment>
          <Text style={style.vars.success}>
            Your password has been reset! You may log in below.
          </Text>
          <Actions>
            <MainButton
              linkStyle
              label='Login'
              marginRight
              action={() => history.push('/?login=true')}
            />
          </Actions>
        </React.Fragment>
      )}

      {error && (
        <>
          <Text style={style.vars.error}>{error}</Text>
        </>
      )}
    </MarketingContainer>
  )
}
