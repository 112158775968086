import React from 'react'
import { screen, style } from '../themes/styles'
import styled from 'styled-components'
import FeatherIcon from 'feather-icons-react'

// for toggling between MOIC & Projected (Potential) MOIC when viewing Carry

const Container = style.StyledComponent.div`
 
`

const RadioBtns = styled.div`
  display: flex;
  justify-content: flex-end;

  @media ${screen.sm} {
    margin-top: 7.5vh;
  }
`

const RdBtn = styled(FeatherIcon)`
  width: 20px;
  &:first-of-type {
    margin-left: 0;
  }
  margin-left: 16px;
`

export default function MoicToggle({ moic, potentialMoic, currentMoic, setCurrentMoic }) {
  return (
    <Container>
      <RadioBtns>
        <RdBtn
          icon={currentMoic === moic ? 'disc' : 'circle'}
          onClick={() => setCurrentMoic(moic)}
        />{' '}
        MOIC
        <RdBtn
          icon={currentMoic === potentialMoic ? 'disc' : 'circle'}
          onClick={() => setCurrentMoic(potentialMoic)}
        />{' '}
        Projected MOIC
      </RadioBtns>
    </Container>
  )
}
