import React from 'react'
import styled from 'styled-components'
import sv from './themes/styles'
import history from './constants/History'
import { Router, Switch } from 'react-router-dom'
import routes from './routes/Routes'
import AppRoute from './routes/AppRoute'
import { GlobalStyle } from 'themes'
import ReactGA from 'react-ga4'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const Container = styled.div`
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background: ${sv.colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export default function App() {
  ReactGA.initialize('G-ZCXWRSD1MY')
  return (
    <Container>
      <GlobalStyle />
      <Router history={history}>
        <Switch>
          {routes.map(route => (
            <AppRoute
              exact
              key={route.path}
              path={route.path}
              component={route.component}
              isProtected={route.isProtected}
              history={history}
              platform={route.platform}
            />
          ))}
        </Switch>
      </Router>
    </Container>
  )
}
