import React, { useCallback, useState, useEffect } from 'react'
import sv, { style } from '../themes/styles'
import moment from 'moment'
import { dollarShortener, dollarFormat } from '../constants/DollarsFormat'
import {
  createCommitmentsInBatch,
  DataStore,
  getEntitiesByUser,
  getCommitmentsByUser,
  createCommitmentForEntity,
  Opportunity,
  getCommitmentsByUserForOpp,
  getCommitmentsByOrgForOpp
} from '../services/DataStore'
import Button from './core/Button'
import OpportunitiesDropdown from './OpportunitiesDropdown'
import SavingProgress from './SavingProgress'
import { H2, Label, Text } from '../constants/StyleComponents'
import { authenticatedFetch, isAdmin, PLATFORMS } from '../services/authentication'
import ConfirmDialog from './core/ConfirmDialog'
import Checkbox from './core/Checkbox'
import EntitiesDropdown from './EntitiesDropdown'
import EntityCommitment from './EntityCommitment'
import EntityCommitmentFeeAndCapital from './EntityCommitmentFeeAndCapital'
import ManageEntities from './ManageEntities'
import Modal from './core/Modal'
import Input from './core/fields/Input'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import useDebounce from '../services/useDebounce'
import { OPPORTUNITY_TYPES } from '../services/opportunities'
import { dealName } from '../services/utils'
import styled from 'styled-components'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const Actions = style.StyledComponent.div`
  justify-content: flex-end;
  margin-top: 15px;
`

const Column = style.StyledComponent.div`
  flex: 1;
  line-height: 2em;
  min-width: 40%;
  margin-right: ${props => (props.marginRight ? style.appPadding : 0)}px;
`

const Container = style.StyledComponent.div`
  overflow: visible;
  padding: 40px;
  @media ${style.screen.sm} {
    padding: 24px;
  }
`

const Explainer = style.StyledComponent.div`
  padding: 0;
  margin: 0;
`

const FeeText = style.StyledComponent(Text)`
  font-size: 80%;
`

const Form = style.StyledComponent.div`
  ${style.flexRow};
  align-items: flex-start;
  flex: 1;
  min-height: 200px;
  overflow-y: visible;
  padding-bottom: 6px;
  z-index: 0;
`

const Header = style.StyledComponent.div`
  ${style.flexRow};
  padding-bottom: 20px;
  @media ${style.screen.sm} {
    padding-bottom: 12px;
  }
  h2 {
    font-size: ${style.titleFont};
  }
`

const HiddenLink = style.StyledComponent.a`
  color: black;
`

const Info = style.StyledComponent.div`
  flex: 1;
`

const ModalActions = style.StyledComponent.div`
  display: grid;
  grid-template-columns: 40% 30% 30%;
  grid-gap: 10px;
  margin-top: 24px;
  margin-right: 40px;
  @media ${style.screen.sm} {
    margin-right: 24px;
  }
`

const SmallText = style.StyledComponent(Text)`
  font-size: 16px;
`

const Spaced = style.StyledComponent.span`
  white-space: pre-wrap;
`
const Inputs = style.StyledComponent.div`
  display: flex
`
const Col = style.StyledComponent.div`
  flex-grow: 2; 
  padding: 10px 10px 10px 0;
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
const VINTAGE_PREF_LIMIT_0 = 250000
const VINTAGE_PREF_LIMIT_1 = 500000
const VINTAGE_PREF_LIMIT_2 = 1000000
const VINTAGE_PREF_LIMIT_3 = 2000000

const DEALSHARE_FEE_PERCENT = 4
const DEALSHARE_FEE_PERCENT_0 = 3
const DEALSHARE_FEE_PERCENT_1 = 2
const DEALSHARE_FEE_PERCENT_2 = 1
const DEALSHARE_FEE_PERCENT_3 = 0

const DEALSHARE_FEE_YEARS = 5

/**
 * @param {int|undefined} opportunityId - If set, this opportunity will be selected and user will not be able to change it
 * @param {function} close - Call when a "close" button is clicked
 * @param {function} afterSuccess - Called after successfully creating/editing/deleting commitment. Argument passed is the commitment, or null if the commitment was deleted
 * @param {boolean|undefined} editedCommitment - If true, form will be used to edit the commitment
 * @param {number|undefined} userId - if undefined, editedCommitment.user_id is used
 * @returns {JSX.Element}
 * @constructor
 */
export default function CommitmentsForm({
  opportunityId,
  close,
  afterSuccess,
  setCommitment,
  editedCommitment,
  userId,
  orgType,
  orgName
}) {
  const [user, setUser] = useState(null)
  const [feePercent, setFeePercent] = useState(0)
  const [feeAmount, setFeeAmount] = useState(0)
  const [opportunity, setOpportunity] = useState(null)
  const [showValidationWarning, setShowValidationWarning] = useState(false)
  // Whether the entered amount is valid. Always true for admins
  const [isAmountValid, setIsAmountValid] = useState(false)
  const [saving, setSaving] = useState(false)
  const [showSaveCompleteMessage, setShowSaveCompleteMessage] = useState(false)
  const [message, setMessage] = useState(null)
  // const [newOpportunityId, setNewOpportunityId] = useState(false)
  const [oppId, setOppId] = useState(opportunityId)
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false)
  const [showConfirmSubmit, setShowConfirmSubmit] = useState(false)
  const [disableEmailNotif, setdisableEmailNotif] = useState(false)
  const [commitments, setCommitments] = useState([])
  const [entities, setEntities] = useState([])
  const [totalInvestment, setTotalInvestment] = useState(0)
  const [totalCapital, setTotalCapital] = useState(0)
  const [initialCapital, setInitialCapital] = useState(0)
  const [showManageEntities, setShowManageEntities] = useState(false)
  const [minimum, setMinimum] = useState(null)
  const [maximum, setMaximum] = useState(null)
  const [showCarryShare, setShowCarryShare] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const [opps, setOpps] = useState([])

  // values to submit
  const [selectedEntities, setSelectedEntities] = useState([])

  async function loadOpportunity(id) {
    // Try to fetch from memory. If it's not there, ask the server
    let opp = DataStore.get('opportunities', id)
    if (!opp) {
      opp = await DataStore.find('opportunities', id)
    }

    if (Array.isArray(opp)) {
      setOpportunity(Object.assign(new Opportunity(), opp[0]))
    } else {
      setOpportunity(Object.assign(new Opportunity(), opp))
    }
  }
  // Fetch the user object
  useEffect(() => {
    if (userId) {
      DataStore.find('users', userId).then(setUser)
    } else {
      setUser(null)
    }
  }, [userId])

  useEffect(() => {
    if (editedCommitment && editedCommitment.opportunity_id) {
      setOppId(editedCommitment.opportunity_id)
    }
  }, [editedCommitment])

  // load entities when user changes
  useEffect(() => {
    if (user && user.id) {
      getEntitiesByUser(user.id).then(ents => {
        setEntities(ents)
        const options = ents.map((e, id) => ({
          label: e.name,
          value: e.id,
          default: id === true,
          carryShare: 100,
          amountRequested: 0
        }))
        setSelectedEntities([options[0]])
      })
    }
  }, [user, showManageEntities])

  // trigger after a new opportunity is load after select one in admin
  useEffect(() => {
    if (user && opportunity && user.id) {
      // user can be an org...., so use org to get commits
      const org_id = user.organization.id
      if (opportunity?.isDealshare()) {
        DataStore.findAll('commitments', {
          organization_id: org_id,
          opportunity_id: opportunity?.funds?.map(e => [e.fund_collection_id, e.fund_id]).flat()
        }).then(commitments => {
          const maxFundInvestment = Math.max(
            ...[0],
            ...commitments.map(e => e.final_amount_total ?? e.amount_requested)
          )

          if (maxFundInvestment >= VINTAGE_PREF_LIMIT_3) {
            setFeePercent(DEALSHARE_FEE_PERCENT_3)
          } else if (maxFundInvestment >= VINTAGE_PREF_LIMIT_2) {
            setFeePercent(DEALSHARE_FEE_PERCENT_2)
          } else if (maxFundInvestment >= VINTAGE_PREF_LIMIT_1) {
            setFeePercent(DEALSHARE_FEE_PERCENT_1)
          } else if (maxFundInvestment >= VINTAGE_PREF_LIMIT_0) {
            setFeePercent(DEALSHARE_FEE_PERCENT_0)
          } else {
            setFeePercent(DEALSHARE_FEE_PERCENT)
          }
        })
      }
    }
  }, [user, opportunity])

  useEffect(() => {
    if (user && user.id && entities && oppId) {
      // user can be an org...., so use org to get commits
      const org_id = user.organization.id

      authenticatedFetch(`commitments?organization_id=${org_id}&opportunity_id=${oppId}`)
        .then(comms => {
          if (comms.length > 0) {
            let newEntities = []

            for (let i = 0; i < comms.length; i++) {
              const opp = comms[i]
              const oppEntity = entities.filter(x => x.id === opp.entity_id)[0]
              oppEntity.amountRequested = parseInt(opp.amount_requested)
              oppEntity.commitmentId = opp.id
              oppEntity.carryShare = opp.carry_share || 100
              newEntities = newEntities.concat(oppEntity)
            }

            setSelectedEntities(
              newEntities.map((e, id) => ({
                label: e.name,
                value: e.id,
                default: id === true,
                amountRequested: e.amountRequested,
                commitmentId: e.commitmentId,
                carryShare: e.carryShare
              }))
            )
          } else if (entities.length > 0) {
            const options = entities.map((e, id) => ({
              label: e.name,
              value: e.id,
              default: id === true,
              carryShare: 100
            }))
            setSelectedEntities([options[0]])
          } else {
            setSelectedEntities([])
          }

          setCommitments(comms)
        })
        .catch(e => {})
    }
  }, [user, opportunity, entities])

  // this only triggers if the component mount and do something if is an edit

  /**  useEffect(() => {
    if (!editedCommitment) {
      clearForm()
    }
    loadOpportunity(oppId)
  }, [oppId, editedCommitment])
**/
  // get new opportunity if active opportunity is changed on the admin
  useEffect(() => {
    if (oppId) {
      loadOpportunity(oppId)
    }
  }, [oppId])

  useEffect(() => {
    if (user && opportunity) {
      let valid
      if (opportunity?.isDealshare()) {
        if (user.platform === 'champion') {
          valid =
            isAdmin() ||
            (totalInvestment >= opportunity?.minimum_collection_commitment &&
              (!opportunity?.maximum_collection_commitment ||
                totalInvestment <= opportunity?.maximum_collection_commitment))
          setMinimum(opportunity?.minimum_collection_commitment)
          setMaximum(opportunity?.maximum_collection_commitment)
        } else {
          valid =
            isAdmin() ||
            (totalInvestment >= opportunity?.minimum_vintage_commitment &&
              (!opportunity?.maximum_vintage_commitment ||
                totalInvestment <= opportunity?.maximum_vintage_commitment))
          setMinimum(opportunity?.minimum_vintage_commitment)
          setMaximum(opportunity?.maximum_vintage_commitment)
        }
      } else {
        valid =
          isAdmin() ||
          (totalInvestment >= opportunity?.minimum_commitment &&
            (!opportunity?.maximum_commitment ||
              totalInvestment <= opportunity?.maximum_commitment))
        setMinimum(opportunity?.minimum_commitment)
        setMaximum(opportunity?.maximum_commitment)
      }
      setIsAmountValid(valid)
      // if going from invalid to valid, clear the show validation warning
      if (valid && showValidationWarning) {
        setShowValidationWarning(false)
      }
    }
  }, [user, totalInvestment, opportunity, setIsAmountValid, showValidationWarning])

  const handleCancel = () => {
    setSaving(false)
    setMessage(null)
    setShowSaveCompleteMessage(false)

    if (!editedCommitment) {
      clearForm()
    } else {
      // reset to previous values
      // handleAmountChanged(editedCommitment.amount_requested)
    }
    close()
  }

  const handleSubmit = () => {
    isAmountValid ? setShowConfirmSubmit(true) : setShowValidationWarning(true)
  }

  useEffect(() => {
    const reducer = (previousValue, currentValue) => previousValue + currentValue
    const amounts = selectedEntities.map(e => e.amountRequested || 0)
    const total = amounts.reduce(reducer, 0)
    setTotalInvestment(total)

    const annualFee = (total * feePercent) / 100
    setFeeAmount(annualFee * DEALSHARE_FEE_YEARS)
    setTotalCapital(total + annualFee * DEALSHARE_FEE_YEARS)
    setInitialCapital(total + annualFee)
  }, [feePercent, selectedEntities, user, opportunity])

  const updateAmounts = (entityId, amount) => {
    const newAmount = parseInt(amount)
    const entityIndex = selectedEntities.findIndex(e => e.value === entityId)
    const newSelected = [...selectedEntities]
    newSelected[entityIndex] = { ...newSelected[entityIndex], amountRequested: newAmount }
    setSelectedEntities(newSelected)
  }
  const updateCarryShare = (entityId, carryShare) => {
    const entityIndex = selectedEntities.findIndex(e => e.value === entityId)
    const newSelected = [...selectedEntities]
    newSelected[entityIndex] = { ...newSelected[entityIndex], carryShare: carryShare }
    setSelectedEntities(newSelected)
  }

  const commitmentSubmit = useCallback(() => {
    setSaving(true)

    const user_id = user ? user.id : null

    let allSubmitPromises

    if (editedCommitment) {
      // editing an existing commitment
      allSubmitPromises = selectedEntities.map(ent => {
        if (ent.amountRequested && ent.amountRequested > 0) {
          // edit those that have  commitment id and create those that dont
          const newCommitmentData = [
            {
              entity_id: ent.value,
              disable_email_notif: disableEmailNotif,
              amount_requested: ent.amountRequested,
              user_id: user_id,
              opportunity_id: oppId,
              hubspot_deal_id: editedCommitment.hubspot_deal_id,
              carry_share: ent.carryShare
            }
          ]

          let submitPromise

          if (ent.commitmentId) {
            submitPromise = DataStore.update(
              'commitments',
              ent.commitmentId,
              newCommitmentData[0]
            ).then(com => {
              return com
            })
          } else {
            submitPromise = createCommitmentForEntity(newCommitmentData, user_id, ent.value).then(
              com => {
                return com[0]
              }
            )
          }
          return submitPromise
        } else if (ent.amountRequested && ent.amountRequested === 0 && !!ent.commitmentId) {
          // delete those that now have value 0
          return DataStore.destroy('commitments', ent.commitmentId, {
            params: {
              disable_email_notif: disableEmailNotif,
              // additional params needed for Mixpanel
              user_id: user_id,
              opportunity_id: ent.commitmentId,
              entity_id: ent.entity_id
            }
          })
        } else {
          return {}
        }
      })
      // remove commitments that arent required anymore (exist in commitment state but dont exist in selectedEntities state)
      allSubmitPromises = allSubmitPromises.concat(
        commitments.map(comm => {
          const found = selectedEntities.findIndex(elem => elem.commitmentId === comm.id)
          if (found === -1) {
            return DataStore.destroy('commitments', comm.id, {
              params: {
                disable_email_notif: disableEmailNotif,
                // additional params needed for Mixpanel
                user_id: user_id,
                opportunity_id: comm.id,
                entity_id: comm.entity_id
              }
            })
          } else {
            return {}
          }
        })
      )
    } else {
      const submittedCommitments = selectedEntities.filter(
        ent => ent.amountRequested && ent.amountRequested > 0
      )

      if (submittedCommitments.length === 0) {
        setShowSaveCompleteMessage(true)
        setMessage('An error occurred submitting your request. Please try again.')
        return
      }

      const newCommitmentsData = submittedCommitments.map(ent => {
        const newCommitment = {
          disable_email_notif: disableEmailNotif,
          amount_requested: ent.amountRequested,
          user_id: user_id,
          opportunity_id: oppId,
          entity_id: ent.value,
          carry_share: ent.carryShare
        }

        return newCommitment
      })

      allSubmitPromises = [createCommitmentsInBatch(newCommitmentsData, user_id)]
    }

    Promise.all(allSubmitPromises)
      .then(data => {
        setSingleCommitment(selectedEntities, oppId)
        if (editedCommitment) {
          setMessage('Commitment updated.')
        } else {
          if (isAdmin()) {
            setMessage(`Commitment created for user: ${user.name}`)
          } else {
            setMessage(
              'Thank you, your requested commitment amount for this deal has been received. We will be in touch with next steps shortly.'
            )
          }
        }

        afterSuccess({ ...data[0][0], opportunity: opportunity, amount_requested: totalInvestment })
        setShowSaveCompleteMessage(true)
      })
      .catch(e => {
        setShowSaveCompleteMessage(true) // this just causes
        setMessage(e.message || 'An error occurred submitting your request. Please try again.')
      })
    // eslint-disable-next-line
  }, [editedCommitment, disableEmailNotif, oppId, user, selectedEntities])

  function setSingleCommitment(entities, oppId) {
    const amounReq = entities.reduce((a, c) => a + Number(c.amountRequested || 0), 0)

    const c = {
      amount_requested: amounReq,
      opportunity_id: oppId
    }

    if (editedCommitment !== null) {
      c.id = editedCommitment.id
      c.opportunity_id = editedCommitment.opportunity_id
    }

    setCommitment(c)
  }

  function removeCommitment(editedCommitment) {
    setSaving(true)
    if (!editedCommitment) {
      setShowSaveCompleteMessage(true)
      setMessage('Error removing commitment!')
      return
    }

    // sometimes there are more than one commitment on this page.
    authenticatedFetch(
      `opportunities/${oppId}/remove_commits?organization_id=` + user.organization.id
    )
      .then(() => {
        afterSuccess(editedCommitment.id)
        clearForm()
        setShowSaveCompleteMessage(true)
        setCommitment(null)
        setMessage('Commitment removed.')
      })
      .catch(e => {
        clearForm()
        setShowSaveCompleteMessage(true)
        setMessage(
          e.response?.data?.message ||
            'Sorry, an error occurred when trying to remove your request. Please try again, and contact MVP if you continue to have difficulties.'
        )
      })
  }

  /**
   * Clears all values in the form
   */
  function clearForm() {
    setOppId(null)
    setOpportunity(null)
  }

  const formatResult = item => {
    return (
      <>
        <span>{dealName(item)}</span>
      </>
    )
  }

  const handleOppSelect = item => {
    item.name = dealName(item)
    setOppId(item.id)
  }

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
      let query = `search_term=${debouncedSearchTerm}&no_commits_for_user=${user.id}&slim=true&limit=10&order_by=name&order=asc&statuses[]=active`
      if (isAdmin()) {
        query = query + `&statuses[]=finalized`
      }
      query =
        query +
        `&skip_platform=${
          user.platform === PLATFORMS.CHAMPION.toLowerCase()
            ? OPPORTUNITY_TYPES.VINTAGE
            : OPPORTUNITY_TYPES.COLLECTION
        }`

      authenticatedFetch(`opportunities?${query}`).then(opps => {
        setOpps(opps)
      })
    }
  }, [debouncedSearchTerm])

  return (
    <>
      {entities.length > 0 && (
        <Container>
          {opportunity && moment(opportunity?.closes).isBefore() && !isAdmin() && (
            <>
              <Header>
                <Info>
                  <H2>Deal has closed</H2>
                  <Text>You can no longer add or edit commitments for this deal.</Text>
                </Info>
              </Header>
              <Actions>
                <Button
                  label='Close'
                  action={() => {
                    clearForm()
                    close()
                  }}
                />
              </Actions>
            </>
          )}
          {showManageEntities && (
            <Modal
              minHeight='10vh'
              show={showManageEntities}
              close={() => setShowManageEntities(false)}
              noPadding
            >
              <ManageEntities user={user} close={() => setShowManageEntities(false)} />
            </Modal>
          )}
          {(isAdmin() ||
            !opportunity ||
            (opportunity && !moment(opportunity?.closes).isBefore())) && (
            <>
              <Header>
                <Info>
                  <H2>
                    {editedCommitment
                      ? `Update commitment: ${opportunity?.name}`
                      : 'Make a commitment'}
                  </H2>
                  {oppId && (
                    <Text color={showValidationWarning ? style.color.danger : style.color.text}>
                      {maximum ? (
                        <Spaced>
                          Investment requirement: {dollarShortener(minimum)} –{' '}
                          {dollarShortener(maximum)}
                        </Spaced>
                      ) : (
                        <>Investment Requirement: {dollarShortener(minimum)}+</>
                      )}
                    </Text>
                  )}
                </Info>
              </Header>
              {isAdmin() && (
                <Checkbox
                  label='Do not send client email notifications'
                  selected={disableEmailNotif}
                  toggle={() => setdisableEmailNotif(!disableEmailNotif)}
                />
              )}
              {!opportunityId && !editedCommitment ? (
                <ReactSearchAutocomplete
                  styling={{ zIndex: 1070 }}
                  items={opps}
                  onSearch={value => setSearchTerm(value)}
                  autoFocus
                  formatResult={formatResult}
                  onSelect={handleOppSelect}
                  onClear={clearForm}
                />
              ) : (
                <Explainer>
                  <SmallText>
                    Please enter the dollar amount to invest in the{' '}
                    {opportunity?.isDealshare() ? 'Co-Invest' : 'Vintage'}
                  </SmallText>
                </Explainer>
              )}
              <Form>
                <Column>
                  <EntityCommitmentFeeAndCapital
                    totalInvestment={totalInvestment}
                    feeAmount={feeAmount}
                    totalCapital={totalCapital}
                    initialCapital={initialCapital}
                    multipleEntities={selectedEntities.length > 1}
                    isDealshare={opportunity?.isDealshare()}
                  />
                </Column>

                <Column>
                  <EntitiesDropdown
                    value={selectedEntities}
                    options={entities
                      .map((e, id) => ({
                        label: e.name,
                        value: e.id,
                        default: id === true,
                        carryShare: 100
                      }))
                      .concat([{ label: '', value: entities.length }])}
                    // options={entities.map((e, id) => ({ label: e.name, value: e.id, default: id === true }))}
                    onChange={ents => setSelectedEntities(ents)}
                    placeholder='Investment Entities'
                    setShowManageEntities={setShowManageEntities}
                  />
                  {isAdmin() && (
                    <>
                      {showCarryShare ? (
                        <Col>
                          <Button
                            marginRight
                            label='Hide Carry Share'
                            action={() => setShowCarryShare(false)}
                          />
                        </Col>
                      ) : (
                        <Col>
                          <Button
                            className='cancel'
                            subtle
                            marginRight
                            label='Show Carry Share'
                            action={() => setShowCarryShare(true)}
                          />
                        </Col>
                      )}
                    </>
                  )}
                  {selectedEntities && selectedEntities.length > 0
                    ? selectedEntities.map((entity, id) => (
                        <Inputs key={id} style={{ alignItems: 'center' }}>
                          <Col>
                            <EntityCommitment
                              key={'selEnt' + id}
                              entity={entity}
                              orgType={orgType}
                              orgName={orgName}
                              inputStateChanger={value => updateAmounts(entity.value, value)}
                              initialValue={editedCommitment ? entity.amountRequested : null}
                            />
                          </Col>

                          {isAdmin() && showCarryShare && (
                            <Col>
                              <Input
                                minValue={0}
                                maxValue={100}
                                label='Carry Share'
                                type='number'
                                value={entity.carryShare}
                                onChange={value => updateCarryShare(entity.value, value)}
                              />
                            </Col>
                          )}
                        </Inputs>
                      ))
                    : null}
                </Column>
              </Form>
              <FeeText>
                <HiddenLink
                  href={
                    user.platform === 'champion'
                      ? 'https://mvp-vc.docsend.com/view/mz8msimiewhqis9c'
                      : 'https://mvp-vc.docsend.com/view/eashtrs52u89sive'
                  }
                  target='_blank'
                >
                  Click here
                </HiddenLink>{' '}
                for more information and alternative options on our management fee structure.
              </FeeText>
              <ModalActions>
                {editedCommitment ? (
                  <Button
                    className='cancel'
                    subtle
                    marginRight
                    label='Remove'
                    action={() => setShowRemoveConfirm(true)}
                  />
                ) : (
                  <div></div>
                )}
                <Button
                  className='cancel'
                  subtle
                  marginRight
                  label='Cancel'
                  action={handleCancel}
                />
                <Button
                  label='Submit'
                  action={() => handleSubmit()}
                  disabled={!isAmountValid || !oppId}
                />
              </ModalActions>
            </>
          )}
          <ConfirmDialog
            show={showRemoveConfirm}
            close={() => setShowRemoveConfirm(false)}
            message='Are you sure you want to remove this commitment?'
            action={() => removeCommitment(editedCommitment)}
          />
          <ConfirmDialog
            show={showConfirmSubmit}
            close={() => setShowConfirmSubmit(false)}
            message={'Important'}
            subMessage={`When you make a commitment, we consider it final unless the deal changes. You cannot commit and then later withdraw. Please confirm your investment of ${dollarFormat(
              totalInvestment
            )} into ${opportunity?.name} here`}
            action={() => commitmentSubmit(totalInvestment)}
            messageWidth={'800px'}
          />
          <SavingProgress
            saving={saving}
            success={showSaveCompleteMessage}
            close={handleCancel}
            message={message}
          />
        </Container>
      )}
    </>
  )
}
