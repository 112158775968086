import React, { useEffect, useState } from 'react'
import CurrencyInput from './core/fields/CurrencyInput'
import { PLATFORMS } from '../services/authentication'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
/**
 * @returns {JSX.Element}
 * @constructor
 */
export default function EntityCommitment({
  inputStateChanger,
  entity,
  initialValue = null,
  externalValue = null
}) {
  const [inputValue, setInputValue] = useState(null) // what user inputs

  const handleInputChange = value => {
    setInputValue(value)
    inputStateChanger(value)
  }

  useEffect(() => {
    if (externalValue) setInputValue(externalValue)
  }, [setInputValue, externalValue])

  return (
    <CurrencyInput
      label={entity.label}
      value={inputValue || initialValue || 0}
      onChange={value => {
        handleInputChange(value)
      }}
      allowDecimals={false}
      shrink={false}
    />
  )
}
