import React, { useState, useEffect } from 'react'
import { style } from '../themes/styles'
import Button from './core/Button'
import Input from './core/fields/Input'
import { H4, Label } from '../constants/StyleComponents'
import { formatValue } from 'react-currency-input-field'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
const StaticEntityInfo = style.StyledComponent.div`
  ${style.borderRadius};
  ${style.box};
  border: 1px solid;
  border-color: ${style.color.cell};
  display: grid;
  grid-template-columns: 80% 10% 10%;
  justify-content: space-between;
  margin-bottom: ${style.grid * 2}px;
  padding: 0px ${style.grid * 2}px;
  padding-top: 10px;
  padding-bottom: 2px;
  width: 94%;
  @media ${style.screen.sm} {
    grid-template-columns: 60% 20% 20%;
    width: 90%;
  }
`

const EditSaveButton = style.StyledComponent(Button)`
  border: none;
  background-color: white;
  color: ${props => (props.subtle ? style.color.cell : style.color.cta)};
  margin: 0;
  font-size: clamp(0.75rem, 0.6429rem + 0.5357vw, 1.125rem);
`
const EntityInput = style.StyledComponent(Input)`
  display: block;
  margin: ${style.grid}px;
  width: 95%;
`
const EntityNoInput = style.StyledComponent.div`
  width: 100%;
  display: block;
  margin: ${style.grid}px;
`
const EntityInputContainer = style.StyledComponent.div`
  align-items: center;
  border: 1px solid;
  border-color: ${style.color.cell};
  grid-template-columns: 70% 15% 15%;
  display: ${props => (props.disabled ? 'none' : 'grid')};
  margin-bottom: ${style.grid * 2}px;
`

const ManageEntityTitle = style.StyledComponent(H4)`
  font-size: clamp(0.75rem, 0.6429rem + 0.5357vw, 1.125rem);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
/**
 *
 * @param {boolean} hideDeleteButton - Used to review if is the only available entity. Ex:[entities.length === 1 => true]
 * @constructor
 */
export default function DisplayAndEditEntity({
  disableAdd,
  user,
  entity,
  id,
  removeEntity,
  saveNewEntity,
  editEntity,
  initiallyDisabled = true,
  hideDeleteButton = false
}) {
  const [disableInput, setDisableInput] = useState(initiallyDisabled)
  const [entityName, setEntityName] = useState(entity.name || '')

  useEffect(() => {
    if (user && entity) {
      setEntityName(entity.name)
    }
  }, [user, entity])

  const handleSave = async data => {
    data.preventDefault()
    if (initiallyDisabled && entity.name !== data.target[0].value) {
      editEntity(entity.id, data.target[0].value)
    } else if (!initiallyDisabled && data.target[0].value !== '') {
      saveNewEntity(user.organization_id, data.target[0].value)
    } else if (!initiallyDisabled && data.target[0].value === '') {
      removeEntity(id, true)
      return
    }
    setDisableInput(true)
  }

  const handleCancel = () => {
    setEntityName(`${entity.name}`)
    if (!initiallyDisabled) {
      removeEntity(id, true)
    } else {
      disableAdd(false)
      setDisableInput(true)
    }
  }

  /**
   * Depending on `disableInput`, either EntityInputContainer or
   * StaticEntityInfo will be displayed in the Manage Entities modal
   **/
  return (
    <>
      {!disableInput ? (
        <form onSubmit={handleSave}>
          <EntityInputContainer disabled={disableInput}>
            <EntityInput
              value={entityName}
              defaultValue={entity.name || ''}
              label='Entity Name'
              onChange={value => setEntityName(value)}
            />
            <EditSaveButton
              className='cancel'
              subtle
              label='Cancel'
              action={() => handleCancel()}
            />
            <EditSaveButton label='Save' inactive={entityName.length === 0} type='submit' />
          </EntityInputContainer>
        </form>
      ) : (
        <StaticEntityInfo disabled={disableInput}>
          <EntityNoInput>
            <ManageEntityTitle>{entityName}</ManageEntityTitle>
            <Label>{`${
              entity.total_funded
                ? formatValue({
                    value: entity.total_funded,
                    groupSeparator: ',',
                    decimalSeparator: '.',
                    prefix: '$',
                    decimalScale: '2'
                  })
                : '$0'
            } invested`}</Label>
          </EntityNoInput>
          <EditSaveButton
            label='Edit'
            action={() => {
              disableAdd(true)
              setDisableInput(false)
            }}
          />
          {!hideDeleteButton && <EditSaveButton label='Delete' action={() => removeEntity(id)} />}
        </StaticEntityInfo>
      )}
    </>
  )
}
