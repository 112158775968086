import React, { useState } from 'react'
import FeatherIcon from 'feather-icons-react'
import { style } from '../../themes/styles'

// STYLE ##########################################################

const Container = style.StyledComponent.div`
  background-color: #F8F8F8;
  border-radius: 16px;
  margin-bottom: 10px;
`

const ContainerContent = style.StyledComponent.div`
  padding: 10px;
`

const Header = style.StyledComponent.div`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto;
  padding: 10px;
  
  h2 {
    margin: 0;
  }
`

const HeaderTitle = style.StyledComponent.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const SubtitleContent = style.StyledComponent.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 10px;
  width: 60%;
`

const ToggleButton = style.StyledComponent.button`
  background-color: transparent;
  border: none;
  color: #888;
  cursor: pointer;
  margin-left: auto;
  outline: none;
  transition: color 0.3s;
`

// Component ##########################################################

/**
 * Collapsible container to hide / display content.
 *
 * @param {string} title - The title for the container
 * @constructor
 */
const CollapsibleContainer = ({ title, subTitleContent, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Container>
      <Header onClick={toggleOpen}>
        <HeaderTitle>
          <h2>{title}</h2>
          <ToggleButton
            className={`toggle-button ${isOpen ? 'open' : 'close'}`}
            onClick={toggleOpen}
          >
            <FeatherIcon icon={isOpen ? 'chevron-up' : 'chevron-down'} size={22} />
          </ToggleButton>
        </HeaderTitle>
        {subTitleContent && <SubtitleContent>{subTitleContent}</SubtitleContent>}
      </Header>
      {isOpen && <ContainerContent>{children}</ContainerContent>}
    </Container>
  )
}

export default CollapsibleContainer
