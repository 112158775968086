import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { linkedInRegex, isValidEmail } from '../services/utils'
import sv from '../themes/styles'
import Button from './core/Button'
import Input from './core/fields/Input'
import SavingProgress from './SavingProgress'
import IconLink from './core/IconLink'
import { H2, Text } from '../constants/StyleComponents'
import LogoLetter from './LogoLetter'
import {
  changePassword,
  isAdmin as currentUserIsAdmin,
  isSuperAdmin,
  reloadUserInfo,
  userInfo,
  validatePassword,
  validateEmail
} from '../services/authentication'
import { DataStore } from '../services/DataStore'
import Dropdown from './core/fields/Dropdown'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const Container = styled.div`
  position: relative;
  padding: ${sv.appPadding}px;
`

const Title = styled(H2)`
  padding-bottom: ${sv.appPadding}px;
  border-bottom: ${sv.borderStyle};
  margin-bottom: ${sv.appPadding}px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const Actions = styled.div`
  ${sv.flexRow};
  margin-top: ${sv.appPadding}px;
  justify-content: flex-end;
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export default function EditAccountInfo({ user, close, closeAll, userUpdated }) {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [carry, setCarry] = useState(0)
  const [linkedinUrl, setLinkedinUrl] = useState('')
  const [platform, setPlatform] = useState(user?.platform)
  // const [address, setAddress] = useState('')
  // const [phone, setPhone] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
  const [validNewPassword, setValidNewPassword] = useState(true)
  const [showNewPasswordField, setShowNewPasswordField] = useState(false)
  const [error, setError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [saving, setSaving] = useState(false)
  const [successSaving, setSuccessSaving] = useState(false)
  const [message, setMessage] = useState(null)
  const [containerOverflow, setContainerOverflow] = useState('auto')
  const [loggedInUser, setLoggedInUser] = useState(null)
  const [dissabledSave, setDissabledSave] = useState(false)
  const [fieldsDisabled, setFieldsDisabled] = useState(false)
  const [msg, setMsg] = useState('')

  const loggedInUserData = async () => {
    const data = await userInfo()
    setLoggedInUser(data)
  }

  const handleClose = () => {
    setShowNewPasswordField(false)
    setNewPassword('')
    setNewPasswordConfirmation('')
    setError(null)
    close()
  }

  useEffect(() => {
    setFirstName(user?.person?.fname)
    setLastName(user?.person?.lname)
    setEmail(user?.username)
    setLinkedinUrl(user?.linkedin_url)
    if (isSuperAdmin()) {
      setCarry(user?.global_carry)
    }
    // setAddress(user.address)
    // setPhone(user.phone)
  }, [user])

  useEffect(() => {
    loggedInUserData()
  }, [])

  const _currentUserIsAdmin = currentUserIsAdmin()
  const allowChangePlatform =
    _currentUserIsAdmin &&
    user &&
    !user.is_admin &&
    !user.created_by_invite &&
    user?.id !== loggedInUser?.id

  const handleSave = async () => {
    setError(null)
    setPasswordError(null)

    const promises = []
    const userProps = Object.assign({}, user)
    const validNewPassword = validatePassword(newPassword)
    // only platform change or other items
    if (allowChangePlatform && platform !== user.platform) {
      userProps.change_platform = platform
    } else {
      if (linkedinUrl && linkedinUrl !== '' && !linkedInRegex.test(linkedinUrl)) {
        setError('Please enter a valid LinkedIn URL (ie: https://www.linkedin.com/in/...)')
        return false
      }
      if (!isValidEmail(email)) {
        setError('Please enter a valid email address')
        return false
      }
      userProps.person.fname = firstName ? firstName.trim() : ''
      userProps.person.lname = lastName ? lastName.trim() : ''
      userProps.linkedin_url = linkedinUrl ? linkedinUrl.trim() : ''
      userProps.email = email.trim()
      userProps.username = email.trim() // api calls it username
      userProps.username = email.trim()
      if (isSuperAdmin()) {
        userProps.global_carry = carry
      }

      // Admin can update the password directly on the user
      if (_currentUserIsAdmin && newPassword.length > 0) {
        if (validNewPassword.length > 0) {
          setError(validNewPassword)
          return false
        } else {
          userProps.password = newPassword
        }
      }
    }
    const userUpdatePromise = DataStore.update('users', user.id, userProps).then(res => {
      if (userProps.change_platform) {
        setMessage(res.message)
      }
    })

    promises.push(userUpdatePromise)

    // only do the rest if the admin is not changing the user's platform
    if (!userProps.change_platform) {
      // Non-admin users have to enter the old password to update their passwords
      if (
        showNewPasswordField &&
        !_currentUserIsAdmin &&
        currentPassword.length > 0 &&
        newPassword.length > 0 &&
        validNewPassword.length === 0
      ) {
        try {
          const response = await changePassword(currentPassword, newPassword)
          setPasswordError([response.message])
          setShowNewPasswordField(false)
        } catch (e) {
          return setPasswordError([
            'The password could not be updated: ' + (e.message || 'Unknown error')
          ])
        }
      } else if (validNewPassword.length > 0 && newPassword.length > 0) {
        setPasswordError(validNewPassword)
        return false
      }
    }
    Promise.all(promises)
      .then(async () => {
        setSuccessSaving(true)
        setSaving(true)
        setPasswordError(null)
        document.getElementById('account_info').scrollTop = 0
        setContainerOverflow('hidden')

        const loggedInUserInfo = await userInfo()
        if (user.id === loggedInUserInfo.id) {
          reloadUserInfo()
        }
        // for updating the current edited object and list info
        // would be better to reload from returned info
        user.person.fname = firstName ? firstName.trim() : ''
        user.person.lname = lastName ? lastName.trim() : ''
        user.linkedin_url = linkedinUrl ? linkedinUrl.trim() : ''
        user.platform = platform
        user.email = email
        if (isSuperAdmin()) {
          user.global_carry = carry
          setCarry(user.global_carry)
        }
        setFirstName(user.person.fname)
        setLastName(user.person.lname)
        setEmail(user.username)
        setLinkedinUrl(user.linkedin_url)
        setPlatform(user.platform)
        setNewPassword('')
        userUpdated(user)
      })
      .catch(err => {
        setError(err.message || 'A problem was encountered updating the user')
        setSaving(false)
      })
  }

  const handleEmailValidation = () => {
    if (!email) {
      setError('Please enter an email address')
      setDissabledSave(true)
    } else if (email !== user.username) {
      if (!isValidEmail(email)) {
        setError('Please enter a valid email address')
        setDissabledSave(true)
      } else {
        validateEmail(email).then(r => {
          if (r) {
            setError(r)
            setDissabledSave(true)
          } else {
            setError('')
            setDissabledSave(false)
          }
        })
      }
    } else {
      setDissabledSave(false)
      setError(null)
    }
  }

  const handlePasswordToggle = () => {
    if (!fieldsDisabled) {
      showNewPasswordField && setNewPassword('')
      showNewPasswordField && setNewPasswordConfirmation('')
      setShowNewPasswordField(!showNewPasswordField)
    }
  }
  const handlePlatformChange = val => {
    setPlatform(val.value)
    if (val.value !== user.platform) {
      setMsg('Please complete the platform change before editing other fields.')
      setError('')
      setPasswordError('')
      setFieldsDisabled(true)
      setShowNewPasswordField(false)
      setNewPassword('')
      setNewPasswordConfirmation('')
      setFirstName(user.person?.fname)
      setLastName(user.person?.lname)
      setEmail(user.username)
      setLinkedinUrl(user.linkedin_url)
      setDissabledSave(false)
    } else {
      setMsg('')
      setFieldsDisabled(false)
    }
  }

  const closeAfterSuccess = () => {
    setContainerOverflow('auto')
    setSaving(false)
    // setSuccessSaving(false)
    if (_currentUserIsAdmin && message) {
      closeAll()
    } else {
      handleClose()
    }
  }
  const dropdownOptions = [
    { value: 'champion', label: 'Champion' },
    { value: 'mvp', label: 'MVP' }
  ]

  return (
    <Container style={{ overflow: containerOverflow }} id='account_info'>
      <Title>
        Edit account information{' '}
        {_currentUserIsAdmin && user?.platform && <LogoLetter platform={user.platform} />}
      </Title>

      {user && user.created_by_invite && user.account_owner && user.organization && (
        <Text>
          User on account belonging to {user.account_owner.name} ({user.organization.name})
        </Text>
      )}

      {msg && <Text>{msg}</Text>}
      {error && <Text color={sv.colors.warning}>{error}</Text>}

      {/* editing name/email address is disabled for now */}

      {allowChangePlatform && (
        <Dropdown
          label='Platform'
          value={platform}
          options={dropdownOptions}
          onChange={handlePlatformChange}
        />
      )}
      <Input
        label='first name'
        value={firstName || ''}
        onChange={value => setFirstName(value)}
        disabled={fieldsDisabled}
      />
      <Input
        label='last name'
        value={lastName || ''}
        onChange={value => setLastName(value)}
        disabled={fieldsDisabled}
      />
      <Input
        label='email address'
        value={email || ''}
        onBlur={value => handleEmailValidation(value)}
        onChange={value => setEmail(value)}
        disabled={fieldsDisabled}
      />
      <Input
        label='LinkedIn URL'
        value={linkedinUrl || ''}
        onChange={value => setLinkedinUrl(value)}
        disabled={fieldsDisabled}
      />
      {isSuperAdmin() && user?.id !== loggedInUser?.id && (
        <Input
          minValue={0}
          maxValue={100}
          label='Global carry (%)'
          type='number'
          inputId='global_carry'
          value={carry}
          onChange={value => setCarry(value)}
        />
      )}
      {/*
        <Input
        label="address"
        value={address || ""}
        onChange={(value) => setAddress(value)}
        />
        <Input
        label="phone number"
        value={phone || ""}
        onChange={(value) => setPhone(value)}
        />
      */}
      {showNewPasswordField && (
        <>
          {!_currentUserIsAdmin && (
            <Input
              label='current password'
              type='password'
              value={currentPassword}
              onChange={value => setCurrentPassword(value)}
            />
          )}
          <Input
            label='new password'
            type='password'
            value={newPassword}
            onChange={value => setNewPassword(value)}
          />
          <div onBlur={() => setValidNewPassword(newPassword === newPasswordConfirmation)}>
            <Input
              label='confirm new password'
              type='password'
              value={newPasswordConfirmation}
              onChange={value => setNewPasswordConfirmation(value)}
            />
            {passwordError && (
              <Text color={sv.colors.warning}>
                {passwordError.map((err, i) => (
                  <span key={i}>
                    {err}
                    <br />
                  </span>
                ))}
              </Text>
            )}
            {newPasswordConfirmation && !validNewPassword && (
              <Text color={sv.colors.warning}>
                <span>
                  Passwords do not match.
                  <br />
                </span>
              </Text>
            )}
          </div>
        </>
      )}
      <IconLink
        action={handlePasswordToggle}
        label={showNewPasswordField ? 'Cancel Password Change' : 'Change Password'}
        color={sv.colors.cta}
      />

      <Actions>
        <Button className='cancel' subtle marginRight label='Cancel' action={handleClose} />
        <Button
          label='Save Changes'
          disabled={dissabledSave || newPassword !== newPasswordConfirmation}
          action={() => handleSave()}
        />
      </Actions>

      <SavingProgress
        saving={saving}
        success={successSaving}
        close={closeAfterSuccess}
        message={message}
      />
    </Container>
  )
}
